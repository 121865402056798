npm <template>

    <div style="height: 100vh">
      <div style="padding-left: 15px;font-size: 18px">
      <p>行数 ：<input v-model="iptRows"  style="width: 80px" size="2" type="number"> 卡号 ：<input v-model="card4No" style="width: 200px"  size="4" type="number" > 单号：<input v-model="seqNo" style="width: 200px" maxlength="6" type="number"></p>
      <p>条形码 ：<input v-model="barcodeValue"> 日期：<input v-model="orderDate" style="width: 300px" size="2"  >
      <button @click ="gensFuncs()" style="margin-left:10px;width: 80px;background-color: #00afff;height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">生成</button>
      <button @click ="printElement()" style="margin-left:10px;width: 80px;background-color: #096c0b; height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">打印</button></p>
      </div>
      <div  style="background-color: #4AB7BD;height: auto;padding-top: 20px;">
      <div id="printContent" style="-webkit-font-smoothing:antialiased;font-family: 'Times New Roman', Times, serif;font-size: 14px;font-weight: lighter">
        <div   style="padding-bottom:70px;line-height:7.8px;font-size:14px;text-align: center;width: 300px;background-color: #FFFFFF;font-family: 'Times New Roman';font-size: 5px;margin: auto">
        <p style="padding-top: 60px;font-size: 14px;font-weight: bold" v-html="'FAMILY' + '&nbsp;'.repeat(2*2) + 'DOLLAR'"></p>
        <div style="text-align: left">
<!--          <p style="padding-left: 20px;font-size: 14px;" v-html="'STORE' + '&nbsp;'.repeat(2*2) + '#'+storeNo + '&nbsp;'.repeat(2*2) + ' '+address"></p>-->
<!--          <p style="padding-left: 40px;font-size: 14px;" v-html="city+',' + '&nbsp;'.repeat(2*2)+phone"></p>-->
          <p class="container" style="padding-left: 10px;font-size: 14px; text-align: center;" ><span style="display: inline-block;width: 40%; text-align: right; " v-html="'STORE' + '&nbsp;'.repeat(2*2) + '#'+storeNo"></span><span style="display: inline-block;width: 60%; text-align: center; " v-html="address"></span></p>
          <p class="container2" style="font-size: 14px; text-align: center;"><span style="display: inline-block;width: 55%; text-align: center; " v-html="city +','"></span><span style="display: inline-block;width: 45%; text-align: left; " v-html="phone"></span></p>
          <div v-for="(item,index) in genRows">
            <p style="padding-left: 10px;font-size: 14px;" v-html="'BREYERS' + '&nbsp;'.repeat(1*1) + 'ICE' + '&nbsp;'.repeat(1*1) + 'CREAM' + '&nbsp;'.repeat(1*1) + 'VANILLA 480Z'"></p>
            <p style="padding-left: 20px;font-size: 14px;" v-html="'077567254238' + '&nbsp;'.repeat(39*1) + itemPrice.toFixed(2)+' F'"></p>
          </div>
          <p style="padding-left: 10px; font-size: 14px;" v-html="'SUBTOTAL' + '&nbsp;'.repeat(23*2) + '$'+subTotalPrice"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'TAX'+showIptRows + '&nbsp;'.repeat(27*2) + '$'+taxPrice"></p>
          <p style="padding-left: 10px;font-size: 14px;"><span style="font-weight: bold" v-html="'T O T A L' + '&nbsp;'.repeat(49*1)"></span><span v-html="'$'+totalPrice"></span></p>
          <p style="padding-left: 10px;font-size: 14px;"><span style="font-weight: bold" v-html="'V I S A' + '&nbsp;'.repeat(54*1)"></span><span v-html="'$'+totalPrice"></span></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'US' + '&nbsp;'.repeat(2*2) + 'DEBIT ************'+card4No"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'CHIP'+ '&nbsp;'.repeat(2*2) +'CONTACTLESS'+ '&nbsp;'.repeat(2*2) +'Approved'"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'AUTH#'+ '&nbsp;'.repeat(1) +'BBU5LJ'+ '&nbsp;'.repeat(2*2) +'SEQUENCE'+ '&nbsp;'.repeat(2*2)+'NO: '+seqNo"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'Mode:'+ '&nbsp;'.repeat(2) +'Issuer'+ '&nbsp;'.repeat(2*2) +'ARQC - '+'C'+issuer"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'AID:'+ '&nbsp;'.repeat(2) +'A0000000' +aId5No+ '&nbsp;'.repeat(2*2) +'ARC:'"></p>
          <p style="font-size: 14px;text-align: center;margin-top: -3px"  > <svg ref="barcode"></svg></p>
          <p style="font-size: 14px;text-align: center;margin-top: -12px" v-html="barcodeValue.replace(/(.)(?=.)/g, '$1 ')"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'ITEMS '+iptRows"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="orderDate+' '+storeNo+' '+iptRowsFull+' '+code7No+' '+seq4No"></p>

          <p style="padding-left: 20px;font-size: 14px;" v-html="'Cash check Debit Credit &'+ '&nbsp;'.repeat(2*2) +'EBT'+ '&nbsp;'.repeat(2*2) +'Accepted.'"></p>
          <p style="font-size: 14px;text-align: center" v-html="'THANK YOU.'"></p>
        </div>
      </div>
      </div>
    </div>
    </div>
</template>

<style>

</style>
<script>
import {initApkHub, newestApkUrl, queryApkHub} from "@/api/index";
import QRCode from 'qrcode'
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';
import { Circle } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';
import { Image as VanImage } from 'vant';
import { Card } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { Notify } from 'vant';
import JsBarcode from 'jsbarcode'
Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);
import  apkhubLogo  from "../../assets/img/APKHub.png";
export default {
  data () {
    return {
      storeNo:'',
      phone:'',
      city:'',
      address:'',
      seq4No:'',
      issuer:'',
      iptRowsFull:'',
      pageRows:1,
      iptRows:3,
      showIptRows:'',
      itemPrice:5.50,
      tax10:0.02,
      taxPrice:0.0,
      totalPrice:0.0,
      subTotalPrice:0.0,
      genRows:3,
      card4No:9311,
      seqNo:304740,
      barcodeValue:'9900300230130764390',
      orderDate:'',
      aId5No:925659,
      code7No:1911123,
      storeData:[{"storeNo":"11648","address":"8166 Nc 39 Highway","city":"Zebulon, NC","phone":"919-823-4281"},
        {"storeNo":"00173","address":"601 W Gannon Ave","city":"Zebulon, NC","phone":"919-823-4435"},
        {"storeNo":"01698","address":"1054 Nc Hwy 86 N","city":"Yanceyville, NC","phone":"743-244-8936"},
        {"storeNo":"00118","address":"619 South State Street","city":"Yadkinville, NC","phone":"336-518-0616"},
        {"storeNo":"08066","address":"104 South Main St.","city":"Winton, NC","phone":"252-427-1109"},
        {"storeNo":"04677","address":"631 Worthington Rd","city":"Winterville, NC","phone":"252-275-8125"},
        {"storeNo":"11242","address":"4194 Ebert Rd.","city":"Winston Salem, NC ","phone":"336-283-5630"},
        {"storeNo":"11551","address":"2017 S Broad Street","city":"Winston Salem, NC ","phone":"743-207-2117"},
        {"storeNo":"00142","address":"1425 Waughtown St","city":"Winston Salem, NC ","phone":"336-283-5873"},
        {"storeNo":"03531","address":"7758 North Point Blvd","city":"Winston-Salem, NC","phone":"336-530-9703"},
        {"storeNo":"03857","address":"631 Peters Creek Pkwy","city":"Winston Salem, NC ","phone":"743-253-6437"},
        {"storeNo":"04921","address":"3523a Patterson Ave","city":"Winston-Salem, NC","phone":"336-901-2606"},
        {"storeNo":"06552","address":"1311 North Patterson Ave","city":"Winston-Salem, NC","phone":"743-253-5701"},
        {"storeNo":"06975","address":"3045 Waughtown St","city":"Winston-Salem, NC","phone":"910-319-8294"},
        {"storeNo":"07340","address":"3195 Peters Creek Pkwy","city":"Winston-Salem, NC","phone":"336-283-5381"},
        {"storeNo":"07840","address":"3501 South Main Street","city":"Winston-Salem, NC","phone":"743-207-2024"},
        {"storeNo":"10720","address":"3708 Highway 74 E","city":"Wingate, NC","phone":"980-480-1591"},
        {"storeNo":"00623","address":"224 Us 13 Bypass","city":"Windsor, NC","phone":"252-484-3221"},
        {"storeNo":"09365","address":"1307 South King St","city":"Windsor, NC","phone":"252-484-3231"},
        {"storeNo":"10049","address":"6501 Ward Blvd.","city":"Wilson, NC","phone":"252-293-6659"},
        {"storeNo":"10714","address":"205 Hines Street Sw","city":"Wilson, NC","phone":"252-371-1652"},
        {"storeNo":"00264","address":"4000 A Ward Blvd","city":"Wilson, NC","phone":"252-293-6906"},
        {"storeNo":"00359","address":"1301 Ward Blvd S","city":"Wilson, NC","phone":"252-293-6721"},
        {"storeNo":"06003","address":"4800 Ward Blvd.","city":"Wilson, NC","phone":"252-293-6509"},
        {"storeNo":"07790","address":"3912 N Nash Street","city":"Wilson, NC","phone":"252-289-1970"},
        {"storeNo":"10446","address":"2616 Castle Hayne Road","city":"Wilmington, NC","phone":"910-218-1314"},
        {"storeNo":"05548","address":"1013 Market St","city":"Wilmington, NC","phone":"910-218-2941"},
        {"storeNo":"13667","address":"3839 Fire Dept Rd","city":"Williamston, NC","phone":"252-508-9400"},
        {"storeNo":"00172","address":"300 West Blvd","city":"Williamston, NC","phone":"252-508-9487"},
        {"storeNo":"04237","address":"204 N Jk Powell Blvd","city":"Whiteville, NC","phone":"910-212-5761"},
        {"storeNo":"09031","address":"302 N White St.","city":"Whitakers, NC","phone":"252-591-1108"},
        {"storeNo":"01730","address":"2920 Wendell Boulevard","city":"Wendell, NC","phone":"984-788-5072"},
        {"storeNo":"07138","address":"210 E 2nd St","city":"Weldon, NC","phone":"252-538-3627"},
        {"storeNo":"12219","address":"115 Eagles Nest Rd","city":"Waynesville, NC","phone":"828-356-5005"},
        {"storeNo":"05876","address":"1201 N Broome St","city":"Waxhaw, NC","phone":"980-489-0012"},
        {"storeNo":"00057","address":"207 East 5th Street","city":"Washington, NC","phone":"252-362-6434"},
        {"storeNo":"12765","address":"2570 Nc 8 & 89 Hwy S","city":"Walnut Cove, NC","phone":"743-215-0811"},
        {"storeNo":"08009","address":"114 E Southerland St.","city":"Wallace, NC","phone":"910-463-1075"},
        {"storeNo":"12364","address":"3015 Old Hollow Rd","city":"Walkertown, NC","phone":"336-920-4036"},
        {"storeNo":"12942","address":"23100 Main Street","city":"Wagram, NC","phone":"910-701-5677"},
        {"storeNo":"00311","address":"909 East Caswell Street","city":"Wadesboro, NC","phone":"980-934-5016"},
        {"storeNo":"07951","address":"124 Main St.","city":"Vass, NC","phone":"910-556-5025"},
        {"storeNo":"07168","address":"8155 Main Street","city":"Vanceboro, NC","phone":"252-244-7255"},
        {"storeNo":"01315","address":"425 Albemarle Road","city":"Troy, NC","phone":"336-872-9053"},
        {"storeNo":"13798","address":"9044 Hillsville Rd","city":"Trinity, NC","phone":"336-663-3261"},
        {"storeNo":"06844","address":"8275 S Nc Highway 109","city":"Thomasville, NC","phone":"336-481-0057"},
        {"storeNo":"07413","address":"1101 Lexington Ave.","city":"Thomasville, NC","phone":"743-205-4649"},
        {"storeNo":"08594","address":"530 National Hwy.","city":"Thomasville, NC","phone":"743-205-3540"},
        {"storeNo":"02902","address":"513 West Main Ave","city":"Taylorsville, NC","phone":"828-352-2601"},
        {"storeNo":"13865","address":"14352 Nc 87 Hwy W","city":"Tar Heel, NC","phone":"910-801-0094"},
        {"storeNo":"00071","address":"1001 Pireway Rd","city":"Tabor City, NC","phone":"910-377-5662"},
        {"storeNo":"12609","address":"13065 Us Hwy 264","city":"Swan Quarter, NC","phone":"252-210-4230"},
        {"storeNo":"08163","address":"2230 Us Highway 70","city":"Swannanoa, NC","phone":"828-571-0664"},
        {"storeNo":"00109","address":"407 E Broad Street","city":"St. Pauls, NC","phone":"910-241-0403"},
        {"storeNo":"13206","address":"102 Highway 15 N","city":"Stovall, NC","phone":"919-514-1053"},
        {"storeNo":"05393","address":"5179 Clinton Rd","city":"Stedman, NC","phone":"910-710-5068"},
        {"storeNo":"00166","address":"1135 W Front St","city":"Statesville, NC","phone":"980-223-0433"},
        {"storeNo":"00288","address":"1384 Shelton Ave","city":"Statesville, NC","phone":"980-223-0179"},
        {"storeNo":"04546","address":"608 Turnersburg Hwy","city":"Statesville, NC","phone":"980-223-0268"},
        {"storeNo":"00451","address":"105 E Dallas Rd","city":"Stanley, NC","phone":"980-372-0026"},
        {"storeNo":"00360","address":"205 Skyland Plaza","city":"Spring Lake, NC","phone":"910-500-4963"},
        {"storeNo":"06389","address":"3274 Ray Rd","city":"Spring Lake, NC","phone":"910-912-6904"},
        {"storeNo":"01241","address":"381 S Main Street","city":"Sparta, NC","phone":"336-890-6883"},
        {"storeNo":"12514","address":"4037 Highway 123 N","city":"Snow Hill, NC","phone":"252-543-8531"},
        {"storeNo":"13664","address":"420 Kingold Blvd","city":"Snow Hill, NC","phone":"252-653-6402"},
        {"storeNo":"00734","address":"110 Greenwood Sq","city":"Snow Hill, NC","phone":"252-543-8248"},
        {"storeNo":"09328","address":"3510 Wilsons Mills Road","city":"Smithfield, NC","phone":"984-201-0231"},
        {"storeNo":"07249","address":"1113 E 11th St","city":"Siler City, NC","phone":"984-265-1400"},
        {"storeNo":"10903","address":"1285 Fallston Road","city":"Shelby, NC ","phone":"980-295-2291"},
        {"storeNo":"03331","address":"1151 E Marion St","city":"Shelby, NC ","phone":"980-295-2171"},
        {"storeNo":"05519","address":"1408 S. Lafayette Street","city":"Shelby, NC ","phone":"980-295-2146"},
        {"storeNo":"11333","address":"4364 S Nc Hwy 111","city":"Seven Springs, NC","phone":"252-843-1267"},
        {"storeNo":"11241","address":"107 N Pollock Street","city":"Selma, NC","phone":"984-223-1321"},
        {"storeNo":"12775","address":"803 Main St","city":"Scotland Neck, NC","phone":"252-696-1207"},
        {"storeNo":"11998","address":"1144 Greenwood Road","city":"Sanford, NC","phone":"984-977-9967"},
        {"storeNo":"04274","address":"902 N Horner Blvd","city":"Sanford, NC","phone":"984-977-9221"},
        {"storeNo":"04301","address":"1935 S Horner Blvd","city":"Sanford, NC","phone":"984-977-9395"},
        {"storeNo":"00007","address":"2512 Lee Ave","city":"Sanford, NC","phone":"984-977-9187"},
        {"storeNo":"00232","address":"410 E Innes St","city":"Salisbury, NC ","phone":"980-330-6171"},
        {"storeNo":"07928","address":"2102 Statesville Boulevard","city":"Salisbury, NC ","phone":"980-330-6168"},
        {"storeNo":"12244","address":"6280 Burlington Rd","city":"Roxboro, NC","phone":"336-439-6734"},
        {"storeNo":"00275","address":"700 N Madison Blvd.","city":"Roxboro, NC","phone":"336-915-7732"},
        {"storeNo":"11544","address":"2588 Nc 710 Highway S","city":"Rowland, NC","phone":"910-720-0429"},
        {"storeNo":"07793","address":"402 W. Main Street","city":"Rowland, NC","phone":"910-720-0547"},
        {"storeNo":"11208","address":"301 S Sycamore Street","city":"Rose Hill","phone":"910-447-6066"},
        {"storeNo":"11013","address":"7989 Us Highway 117 S","city":"Rocky Point, NC","phone":"910-604-6892"},
        {"storeNo":"10414","address":"1257 Goldrock Road","city":"Rocky Mount, NC","phone":"252-210-4583"},
        {"storeNo":"01611","address":"1459 E. Raleigh Blvd.","city":"Rocky Mount, NC","phone":"252-210-4159"},
        {"storeNo":"05770","address":"3627 N. Hathaway Blvd.","city":"Rocky Mount, NC","phone":"252-210-4416"},
        {"storeNo":"06615","address":"606 Cokey Rd","city":"Rocky Mount, NC","phone":"252-740-0079"},
        {"storeNo":"08215","address":"3016 Sunset Ave.","city":"Rocky Mount, NC","phone":"252-210-4314"},
        {"storeNo":"04993","address":"945 W Main St","city":"Rockwell, NC","phone":"980-332-8131"},
        {"storeNo":"10952","address":"311 Airport Road","city":"Rockingham, NC","phone":"910-434-5573"},
        {"storeNo":"00023","address":"1002 E Broad Ave","city":"Rockingham, NC","phone":"910-517-0003"},
        {"storeNo":"05317","address":"384 Rodney Orr Bypass","city":"Robbinsville, NC","phone":"828-346-0053"},
        {"storeNo":"11870","address":"5893 Highway 48","city":"Roanoke Rapids, NC","phone":"252-507-6028"},
        {"storeNo":"00301","address":"1041 E 10th St","city":"Roanoke Rapids, NC","phone":"252-219-0362"},
        {"storeNo":"07306","address":"30 Us Highway 158","city":"Roanoke Rapids, NC","phone":"252-219-0309"},
        {"storeNo":"07759","address":"3196 Old Stage Highway","city":"Riegelwood, NC","phone":"910-941-0568"},
        {"storeNo":"06757","address":"525 N Main St","city":"Rich Square, NC","phone":"252-512-0387"},
        {"storeNo":"13241","address":"108 Culp Rd","city":"Richfield, NC","phone":"336-530-9653"},
        {"storeNo":"00273","address":"1105 S Scales Street","city":"Reidsville, NC","phone":"336-616-9906"},
        {"storeNo":"08026","address":"1130 Freeway Dr.","city":"Reidsville, NC","phone":"336-616-9297"},
        {"storeNo":"00490","address":"216 S. Peachtree Street","city":"Red Springs, NC","phone":"910-227-3280"},
        {"storeNo":"01750","address":"6869 Jordan Rd","city":"Ramseur, NC","phone":"336-223-6689"},
        {"storeNo":"11800","address":"3605 Amber Lane","city":"Raleigh, NC","phone":"984-304-8002"},
        {"storeNo":"12032","address":"3110 Garner Road","city":"Raleigh, NC","phone":"984-304-7896"},
        {"storeNo":"13904","address":"4011 Capital Blvd","city":"Raleigh, NC","phone":"984-212-5369"},
        {"storeNo":"05640","address":"3210 S Wilmington St","city":"Raleigh, NC","phone":"984-304-7502"},
        {"storeNo":"05833","address":"3229 Avent Ferry Rd","city":"Raleigh, NC","phone":"984-319-2213"},
        {"storeNo":"07205","address":"4654 Capital Blvd","city":"Raleigh, NC","phone":"984-304-7449"},
        {"storeNo":"09329","address":"2001 Rock Quarry Road","city":"Raleigh, NC","phone":"984-304-7457"},
        {"storeNo":"10485","address":"156 N Parker Church Road","city":"Raeford, NC","phone":"910-248-3723"},
        {"storeNo":"00181","address":"215 S Main St","city":"Raeford, NC","phone":"910-683-3054"},
        {"storeNo":"06026","address":"4522 Fayetteville Rd","city":"Raeford, NC","phone":"910-683-3055"},
        {"storeNo":"06889","address":"6200 Highway 11 South","city":"Pink Hill, NC","phone":"252-516-1185"},
        {"storeNo":"12676","address":"5503 N Boyd Rd","city":"Pinetown, NC","phone":"252-362-6161"},
        {"storeNo":"07366","address":"314 E Hamlett St","city":"Pinetops, NC ","phone":"252-316-4293"},
        {"storeNo":"07269","address":"155 S. Walnut Street","city":"Pine Bluff, NC","phone":"910-696-1084"},
        {"storeNo":"00647","address":"628 South Key Street","city":"Pilot Mountain, NC","phone":"743-766-0071"},
        {"storeNo":"00730","address":"201 W. 3rd Street","city":"Pembroke, NC","phone":"910-668-6017"},
        {"storeNo":"10814","address":"1901 S Post Road","city":"Patterson Springs, NC","phone":"980-295-2152"},
        {"storeNo":"10597","address":"52 David Parnell Street","city":"Parkton, NC","phone":"910-446-8724"},
        {"storeNo":"00304","address":"315 Hillsboro St","city":"Oxford, NC","phone":"984-256-6132"},
        {"storeNo":"12073","address":"1633 Seaside Road Sw","city":"Ocean Isle Beach, NC","phone":"910-408-4163"},
        {"storeNo":"08587","address":"207 Nc 11 North Highway","city":"Oak City, NC","phone":"252-316-4295"},
        {"storeNo":"07441","address":"103 North Main St","city":"Norwood, NC","phone":"980-790-1031"},
        {"storeNo":"11147","address":"114 U.S. Highway 1 S","city":"Norlina, NC","phone":"252-701-1033"},
        {"storeNo":"06512","address":"306 Main St","city":"Newton Grove, NC","phone":"910-236-2272"},
        {"storeNo":"13419","address":"2481 Blaine Rd","city":"New London, NC","phone":"860-440-7875"},
        {"storeNo":"00702","address":"245 River St","city":"Newland, NC","phone":"828-528-1211"},
        {"storeNo":"00056","address":"2511 Neuse Blvd","city":"New Bern, NC","phone":"252-497-2224"},
        {"storeNo":"07066","address":"1405 Neuse Blvd","city":"New Bern, NC","phone":"252-497-3062"},
        {"storeNo":"00054","address":"1810 Sandhills Blvd N","city":"Aberdeen, NC","phone":"984-266-0026"},
        {"storeNo":"00192","address":"1216 Memorial Drive E","city":"Ahoskie, NC","phone":"252-818-7024"},
        {"storeNo":"00250","address":"210 Nc 740 Bypass","city":"Albemarle, NC","phone":"704-983-1411"},
        {"storeNo":"06155","address":"503c Us Hwy 52 N","city":"Albemarle, NC","phone":"980-410-0530"},
        {"storeNo":"01224","address":"13881 Nc Highway 55","city":"Alliance, NC","phone":"252-745-1458"},
        {"storeNo":"12819","address":"25 Wilson St","city":"Andrews, NC","phone":"828-690-0601"},
        {"storeNo":"00940","address":"441 N Raleigh Street","city":"Angier, NC","phone":"984-355-5969"},
        {"storeNo":"13305","address":"19 Waddell St","city":"Ansonville, NC","phone":"980-354-1424"},
        {"storeNo":"04067","address":"358 N Fayetteville St","city":"Asheboro, NC","phone":"704-444-0309"},
        {"storeNo":"03282","address":"1598 Patton Ave","city":"Asheville, NC","phone":"828-785-5563"},
        {"storeNo":"08294","address":"1340 Tunnel Rd.","city":"Asheville, NC","phone":"828-998-1481"},
        {"storeNo":"07632","address":"410 W Church St","city":"Atkinson, NC","phone":"910-661-2838"},
        {"storeNo":"11671","address":"114 S Commerce Street","city":"Aulander, NC","phone":"252-818-7040"},
        {"storeNo":"00450","address":"148 3rd Street","city":"Ayden, NC","phone":"252-501-3664"},
        {"storeNo":"10430","address":"501 Carteret Street","city":"Bath, NC","phone":"252-283-0949"},
        {"storeNo":"00536","address":"708 E Parrish Dr","city":"Benson, NC","phone":"984-279-0977"},
        {"storeNo":"12451","address":"7364 Main Street","city":"Bethel, NC","phone":"252-818-7787"},
        {"storeNo":"01502","address":"404 W. Main St.","city":"Beulaville, NC","phone":"910-375-1244"},
        {"storeNo":"12034","address":"5251 Church Loop N","city":"Black Creek, NC","phone":"937-346-8277"},
        {"storeNo":"07535","address":"1209 N Main St","city":"Bladenboro, NC","phone":"910-516-8442"},
        {"storeNo":"00220","address":"250 Highway 19 S","city":"Bryson City, NC","phone":"828-341-1214"},
        {"storeNo":"06023","address":"17 Nc Hwy 98 E","city":"Bunn, NC","phone":"984-320-1022"},
        {"storeNo":"04653","address":"200 W Wilmington St","city":"Burgaw, NC","phone":"405-778-2305"},
        {"storeNo":"10879","address":"1519 Rauhut Street","city":"Burlington, NC","phone":"743-208-6310"},
        {"storeNo":"12603","address":"11033 S Nc Hwy 62","city":"Burlington, NC","phone":"336-539-2811"},
        {"storeNo":"04791","address":"2206 W Webb Ave","city":"Burlington, NC","phone":"743-205-4921"},
        {"storeNo":"00006","address":"516 N Church St","city":"Burlington, NC","phone":"336-290-0124"},
        {"storeNo":"07082","address":"908 E Webb Ave","city":"Burlington, NC","phone":"743-205-4795"},
        {"storeNo":"09437","address":"2204 Maple Avenue","city":"Burlington, NC","phone":"743-205-4381"},
        {"storeNo":"08297","address":"10215 E.Beach Drive, Sw","city":"Calabash, NC","phone":"984-243-0084"},
        {"storeNo":"13454","address":"115 Hwy 158","city":"Camden, NC","phone":"252-254-1481"},
        {"storeNo":"03342","address":"145 Mattie Haddock Dr","city":"Cameron, NC","phone":"910-556-5037"},
        {"storeNo":"12423","address":"298 Dogwood Rd","city":"Candler, NC","phone":"828-365-6912"},
        {"storeNo":"07053","address":"320 N Main St","city":"Candor, NC","phone":"910-466-6470"},
        {"storeNo":"12226","address":"595 Champion Dr.","city":"Canton, NC","phone":"828-276-1812"},
        {"storeNo":"00762","address":"1005 Monroe St","city":"Carthage, NC","phone":"910-722-9343"},
        {"storeNo":"07486","address":"8885 Nc Hwy 58 N","city":"Castalia, NC","phone":"252-220-2507"},
        {"storeNo":"00827","address":"127 E Strawberry Blvd","city":"Chadbourn, NC","phone":"910-516-1784"},
        {"storeNo":"10078","address":"2926 Milton Rd","city":"Charlotte, NC","phone":"980-938-2406"},
        {"storeNo":"10175","address":"240 Bradford Drive","city":"Charlotte, NC","phone":"980-221-2198"},
        {"storeNo":"10701","address":"3115 Mount Holly Huntersville","city":"Charlotte, NC","phone":"980-595-3785"},
        {"storeNo":"11330","address":"6716 Nations Ford Road","city":"Charlotte, NC","phone":"980-785-1371"},
        {"storeNo":"11376","address":"542 Valleydale Road","city":"Charlotte, NC","phone":"980-495-0150"},
        {"storeNo":"11575","address":"3618 Beatties Ford Rd","city":"Charlotte, NC","phone":"980-378-1674"},
        {"storeNo":"11612","address":"1025 W Sugar Creek Road","city":"Charlotte, NC","phone":"980-224-2009"},
        {"storeNo":"12450","address":"5401 South Boulevard","city":"Charlotte, NC","phone":"980-308-0306"},
        {"storeNo":"01931","address":"2516 Little Rock Rd","city":"Charlotte, NC","phone":"980-595-3722"},
        {"storeNo":"01933","address":"4005 Sunset Rd","city":"Charlotte, NC","phone":"980-218-0311"},
        {"storeNo":"00002","address":"3400 Wilkinson Blvd","city":"Charlotte, NC","phone":"980-202-4022"},
        {"storeNo":"02093","address":"6212 The Plaza","city":"Charlotte, NC","phone":"980-406-5186"},
        {"storeNo":"02817","address":"4455 Central Ave","city":"Charlotte, NC","phone":"980-819-0887"},
        {"storeNo":"02972","address":"2400 Freedom Dr","city":"Charlotte, NC","phone":"980-288-6158"},
        {"storeNo":"00030","address":"2022 N Graham St","city":"Charlotte, NC","phone":"216-273-2224"},
        {"storeNo":"03737","address":"7006 E Wt Harris Blvd","city":"Charlotte, NC","phone":"980-249-4602"},
        {"storeNo":"03843","address":"5925 South Blvd","city":"Charlotte, NC","phone":"980-357-1561"},
        {"storeNo":"04761","address":"6327 Albemarle Rd","city":"Charlotte, NC","phone":"980-771-0857"},
        {"storeNo":"05203","address":"917 Wendover Rd N","city":"Charlotte, NC","phone":"980-228-9892"},
        {"storeNo":"05424","address":"5430 N Tryon St","city":"Charlotte, NC","phone":"980-999-4013"},
        {"storeNo":"05446","address":"1560a West Blvd","city":"Charlotte, NC","phone":"980-221-2105"},
        {"storeNo":"05851","address":"2716 W Sugar Creek Rd","city":"Charlotte, NC","phone":"980-406-5132"},
        {"storeNo":"00064","address":"4500 N Tryon St","city":"Charlotte, NC","phone":"980-221-1882"},
        {"storeNo":"06656","address":"4611 W Tyvola Rd","city":"Charlotte, NC","phone":"980-221-1348"},
        {"storeNo":"06680","address":"4130 Rozelles Ferry Rd","city":"Charlotte, NC","phone":"980-221-0559"},
        {"storeNo":"07179","address":"6701 N Tryon St","city":"Charlotte, NC","phone":"980-785-1538"},
        {"storeNo":"07345","address":"4301 Statesville Rd","city":"Charlotte, NC","phone":"980-296-0655"},
        {"storeNo":"07523","address":"7308 E Independence Blvd","city":"Charlotte, NC","phone":"980-938-2391"},
        {"storeNo":"07747","address":"7921 Old Statesville Road","city":"Charlotte, NC","phone":"980-378-1910"},
        {"storeNo":"08259","address":"7524 S. Tryon Street","city":"Charlotte, NC","phone":"980-867-4690"},
        {"storeNo":"08388","address":"3106 Eastway Dr.","city":"Charlotte, NC","phone":"980-819-1500"},
        {"storeNo":"08486","address":"2100 Beatties Ford Rd.","city":"Charlotte, NC","phone":"615-671-6202"},
        {"storeNo":"08840","address":"9115 Samlen Lane Unit B01","city":"Charlotte, NC","phone":"980-495-0172"},
        {"storeNo":"08876","address":"3529 S. Tryon St","city":"Charlotte, NC","phone":"980-495-0146"},
        {"storeNo":"08846","address":"1545 South Main Street","city":"China Grove, NC","phone":"980-746-0013"},
        {"storeNo":"11311","address":"2748 Nc Hwy 41","city":"Chinquapin, NC","phone":"910-463-1082"},
        {"storeNo":"13897","address":"3035 Centennial Bvd","city":"Claremont, NC","phone":"984-316-0377"},
        {"storeNo":"10953","address":"10528 S College Street","city":"Clarkton, NC","phone":"910-801-0138"},
        {"storeNo":"00684","address":"10137 Us Highway 70 W","city":"Clayton, NC","phone":"984-355-1145"},
        {"storeNo":"00261","address":"415 Northeast Blvd.","city":"Clinton, NC","phone":"910-260-4266"},
        {"storeNo":"08222","address":"210 South Main St.","city":"Colerain, NC","phone":"252-490-0047"},
        {"storeNo":"10404","address":"300 West Main Street","city":"Conway, NC","phone":"252-595-1372"},
        {"storeNo":"06691","address":"2167 Wilton Ave","city":"Creedmoor, NC","phone":"984-257-7535"},
        {"storeNo":"13242","address":"808 Montague Road","city":"Currie, NC","phone":"910-604-6902"},
        {"storeNo":"11025","address":"514 E Trade Street","city":"Dallas, NC","phone":"980-238-4741"},
        {"storeNo":"10922","address":"1945 Tulls Mill Road","city":"Deep Run, NC","phone":"252-516-1165"},
        {"storeNo":"00663","address":"17895 Glenn St","city":"Denton, NC","phone":"336-490-6003"},
        {"storeNo":"12119","address":"224 East Kornegay Street","city":"Dover, NC","phone":"252-208-9279"},
        {"storeNo":"11305","address":"3865 S Us 117 Alt. Highway","city":"Dudley, NC","phone":"984-207-6668"},
        {"storeNo":"06049","address":"1285 E Cumberland St","city":"Dunn, NC","phone":"910-292-1405"},
        {"storeNo":"10161","address":"2904 Guess Road","city":"Durham, NC","phone":"984-244-2771"},
        {"storeNo":"11975","address":"900 Bacon Street","city":"Durham, NC","phone":"984-316-0356"},
        {"storeNo":"12031","address":"318 Old Oxford Rd.","city":"Durham, NC","phone":"984-244-2761"},
        {"storeNo":"07548","address":"5178 Wake Forest Rd","city":"Durham, NC","phone":"984-219-3550"},
        {"storeNo":"07807","address":"3823 Guess Rd","city":"Durham, NC","phone":"984-316-0182"},
        {"storeNo":"08609","address":"1518 S. Miami Blvd.","city":"Durham, NC","phone":"984-316-0661"},
        {"storeNo":"13235","address":"114 E Nc 67 Bypass Hwy","city":"East Bend, NC","phone":"980-588-8402"},
        {"storeNo":"12214","address":"2556 Spartanburg Hwy","city":"East Flat Rock, NC","phone":"828-435-3755"},
        {"storeNo":"00067","address":"924 Washington St","city":"Eden, NC","phone":"743-400-7060"},
        {"storeNo":"11465","address":"1823 Virginia Road","city":"Edenton, NC","phone":"252-415-0010"},
        {"storeNo":"00100","address":"703 W Ehringhaus Street","city":"Elizabeth City, NC","phone":"252-254-1044"},
        {"storeNo":"12832","address":"1048 Us Hwy 158 W","city":"Elizabeth City, NC","phone":"252-254-1951"},
        {"storeNo":"00309","address":"304 S Poplar Street","city":"Elizabethtown, NC","phone":"910-801-0137"},
        {"storeNo":"07709","address":"428 Main St","city":"Ellenboro, NC","phone":"828-401-1097"},
        {"storeNo":"10811","address":"2229 N Main Street","city":"Ellerbe, NC","phone":"980-495-0155"},
        {"storeNo":"08588","address":"2113 N Nc Highway 87","city":"Elon, NC","phone":"743-205-3779"},
        {"storeNo":"00515","address":"121 S.Mcdaniel St","city":"Enfield, NC","phone":"252-592-5005"},
        {"storeNo":"01071","address":"401 S Walnut St","city":"Fairmont, NC","phone":"910-415-2021"},
        {"storeNo":"12359","address":"11 Ward Store Road","city":"Fairmont, NC","phone":"910-415-4036"},
        {"storeNo":"00204","address":"5469 Us 258","city":"Farmville, NC","phone":"252-228-1219"},
        {"storeNo":"10360","address":"1907 Murchison Rd","city":"Fayetteville, NC","phone":"910-705-3482"},
        {"storeNo":"11880","address":"101 S Reilly Road","city":"Fayetteville, NC","phone":"910-705-3216"},
        {"storeNo":"00163","address":"420 Person Street","city":"Fayetteville, NC","phone":"910-759-3895"},
        {"storeNo":"00292","address":"4112 Raeford Rd","city":"Fayetteville, NC","phone":"910-475-1078"},
        {"storeNo":"03101","address":"3436 Bragg St","city":"Fayetteville, NC","phone":"910-705-3841"},
        {"storeNo":"03590","address":"9535 Cliffdale Rd","city":"Fayetteville, NC","phone":"910-705-3553"},
        {"storeNo":"04394","address":"929 Mcarthur Rd","city":"Fayetteville, NC","phone":"910-475-8036"},
        {"storeNo":"04524","address":"5701 Yadkin Rd","city":"Fayetteville, NC","phone":"910-705-3781"},
        {"storeNo":"04894","address":"3043 Legion Rd","city":"Fayetteville, NC","phone":"479-487-2058"},
        {"storeNo":"05254","address":"6511 Fisher Rd","city":"Fayetteville, NC","phone":"910-705-3822"},
        {"storeNo":"06544","address":"6021 Raeford Rd","city":"Fayetteville, NC","phone":"910-705-4143"},
        {"storeNo":"06674","address":"5108 Nc Hwy 87 S","city":"Fayetteville, NC","phone":"910-705-3808"},
        {"storeNo":"00068","address":"2893 Owen Drive","city":"Fayetteville, NC","phone":"910-912-6841"},
        {"storeNo":"07412","address":"2800 Ramsey St","city":"Fayetteville, NC","phone":"910-705-3694"},
        {"storeNo":"00745","address":"1322 Clinton Rd","city":"Fayetteville, NC","phone":"910-705-3802"},
        {"storeNo":"07562","address":"4700 Cumberland Rd","city":"Fayetteville, NC","phone":"910-912-6851"},
        {"storeNo":"08091","address":"150 Andrews Rd.","city":"Fayetteville, NC","phone":"910-912-6942"},
        {"storeNo":"00840","address":"3412 Murchison Road","city":"Fayetteville, NC","phone":"910-705-3856"},
        {"storeNo":"08933","address":"3734 Cumberland Rd","city":"Fayetteville, NC","phone":"910-500-6310"},
        {"storeNo":"00003","address":"822 West Main Street","city":"Forest City, NC","phone":"828-202-1735"},
        {"storeNo":"01035","address":"3382 Us 1 Highway","city":"Franklinton, NC","phone":"984-271-0024"},
        {"storeNo":"04864","address":"176 Ingold Avenue","city":"Garland, NC","phone":"910-516-1011"},
        {"storeNo":"00445","address":"505 Plaza Cir","city":"Garner, NC","phone":"984-304-8650"},
        {"storeNo":"11971","address":"219 Lawrenceville Road","city":"Gaston, NC","phone":"980-285-7605"},
        {"storeNo":"00143","address":"2559 W Franklin Blvd","city":"Gastonia, NC","phone":"980-289-7542"},
        {"storeNo":"00015","address":"1322 E Franklin Blvd","city":"Gastonia, NC","phone":"980-238-4928"},
        {"storeNo":"06207","address":"1415 N Chester St","city":"Gastonia, NC","phone":"980-238-4630"},
        {"storeNo":"07703","address":"2001 Davis Park Road","city":"Gastonia, NC","phone":"980-285-3872"},
        {"storeNo":"08451","address":"2206 Union Rd.","city":"Gastonia, NC","phone":"980-289-0208"},
        {"storeNo":"12458","address":"5091 Main Street","city":"Gibson, NC","phone":"910-418-4156"},
        {"storeNo":"11265","address":"550 Nc Highway 581 S","city":"Goldsboro, NC","phone":"984-264-0252"},
        {"storeNo":"00213","address":"2529 E Ash St","city":"Goldsboro, NC","phone":"984-207-6702"},
        {"storeNo":"05928","address":"573 E. New Hope Rd","city":"Goldsboro, NC","phone":"984-264-0194"},
        {"storeNo":"06563","address":"423 S George St","city":"Goldsboro, NC","phone":"984-264-0208"},
        {"storeNo":"00821","address":"102 Five Points Rd","city":"Goldsboro, NC","phone":"984-207-6921"},
        {"storeNo":"08445","address":"1403 Wayne Memorial Drive","city":"Goldsboro, NC","phone":"919-396-5831"},
        {"storeNo":"08217","address":"116 E Harden St.","city":"Graham, NC","phone":"336-290-0131"},
        {"storeNo":"05036","address":"117 Forbes Loop Rd","city":"Grandy, NC","phone":"252-421-3183"},
        {"storeNo":"00555","address":"31 Pinewood Rd","city":"Granite Falls, NC","phone":"828-523-0378"},
        {"storeNo":"10862","address":"240 N Salisbury Avenue","city":"Granite Quarry, NC","phone":"980-332-8087"},
        {"storeNo":"10428","address":"2101 Randleman Road","city":"Greensboro, NC","phone":"336-271-1538"},
        {"storeNo":"11048","address":"2927 E Market Street","city":"Greensboro, NC","phone":"743-230-3491"},
        {"storeNo":"11088","address":"601 Martin Luther King Jr. Dri","city":"Greensboro, NC","phone":"336-265-6528"},
        {"storeNo":"11377","address":"5501 Randleman Road","city":"Greensboro, NC","phone":"743-230-2540"},
        {"storeNo":"11410","address":"2115 W. Vandalia Road","city":"Greensboro, NC","phone":"336-291-4927"},
        {"storeNo":"11608","address":"4900 Summit Avenue","city":"Greensboro, NC","phone":"336-743-0142"},
        {"storeNo":"12341","address":"106 College Rd.","city":"Greensboro, NC","phone":"743-230-2471"},
        {"storeNo":"13941","address":"944 Summit Ave","city":"Greensboro, NC","phone":"336-890-8926"},
        {"storeNo":"01657","address":"122 W Meadowview Rd","city":"Greensboro, NC","phone":"743-230-2801"},
        {"storeNo":"03192","address":"3000 Randleman Rd","city":"Greensboro, NC","phone":"743-230-2636"},
        {"storeNo":"03937","address":"2316 East Market St","city":"Greensboro, NC","phone":"336-291-4065"},
        {"storeNo":"00041","address":"4609 W. Gate City Blvd","city":"Greensboro, NC","phone":"743-230-2991"},
        {"storeNo":"00466","address":"2515 Phillips Ave","city":"Greensboro, NC","phone":"743-230-2997"},
        {"storeNo":"00050","address":"1922 Coliseum Blvd","city":"Greensboro, NC","phone":"743-230-2463"},
        {"storeNo":"05237","address":"1318 Lees Chapel Rd","city":"Greensboro, NC","phone":"743-230-5493"},
        {"storeNo":"10462","address":"1700 Greenville Blvd. Sw","city":"Greenville, NC","phone":"252-275-8231"},
        {"storeNo":"10644","address":"3019 Nc 33 W","city":"Greenville, NC","phone":"252-275-8121"},
        {"storeNo":"10769","address":"1601 Evans Street","city":"Greenville, NC","phone":"252-275-8251"},
        {"storeNo":"12134","address":"2109 Pactolus Highway","city":"Greenville, NC","phone":"252-275-8104"},
        {"storeNo":"00825","address":"250a Easy St","city":"Greenville, NC","phone":"252-275-8115"},
        {"storeNo":"00098","address":"2530 S Memorial Dr","city":"Greenville, NC","phone":"252-275-8113"},
        {"storeNo":"06930","address":"810 W Hamlet Ave","city":"Hamlet, NC","phone":"910-716-1214"},
        {"storeNo":"07279","address":"14566 Us Highway 17","city":"Hampstead, NC","phone":"910-319-9436"},
        {"storeNo":"13838","address":"3056 Us 21","city":"Hamptonville, NC","phone":"743-230-3680"},
        {"storeNo":"06705","address":"3338 Harmony Hwy","city":"Harmony, NC","phone":"980-351-0294"},
        {"storeNo":"08341","address":"570 Tomahawk Hwy.","city":"Harrells, NC","phone":"252-490-0051"},
        {"storeNo":"10549","address":"320 W Main Street","city":"Havelock, NC","phone":"252-652-8209"},
        {"storeNo":"13278","address":"104 Hwy 64 E","city":"Hayesville, NC","phone":"828-415-3078"},
        {"storeNo":"06052","address":"7907 Idlewild Road","city":"Hemby Bridge, NC","phone":"980-364-0016"},
        {"storeNo":"00244","address":"112 Raleigh Rd","city":"Henderson, NC","phone":"252-226-9261"},
        {"storeNo":"01041","address":"208 Ocean Hwy S","city":"Hertford, NC ","phone":"252-374-0064"},
        {"storeNo":"12975","address":"1678 Ocean Hwy N","city":"Hertford, NC","phone":"252-374-0073"},
        {"storeNo":"00122","address":"1209 16th St Ne","city":"Hickory, NC","phone":"828-569-6660"},
        {"storeNo":"09333","address":"5025 Nc Hwy 90 E","city":"Hiddenite, NC","phone":"828-352-2644"},
        {"storeNo":"00042","address":"119 Greensboro Rd","city":"High Point, NC","phone":"336-802-1663"},
        {"storeNo":"05780","address":"840 S Main Street","city":"High Point, NC","phone":"743-273-0734"},
        {"storeNo":"07974","address":"944 N. Main Street","city":"High Point, NC","phone":"743-205-4643"},
        {"storeNo":"08592","address":"417 Brentwood St","city":"High Point, NC","phone":"743-205-3596"},
        {"storeNo":"00097","address":"2831 S. Main St.","city":"High Point, NC","phone":"743-205-1872"},
        {"storeNo":"12521","address":"60 Evans Road","city":"Hollister, NC","phone":"252-776-9986"},
        {"storeNo":"11442","address":"110 E Main Street","city":"Hookerton, NC","phone":"252-653-6987"},
        {"storeNo":"00260","address":"3477 N Main St","city":"Hope Mills, NC","phone":"910-705-3671"},
        {"storeNo":"06309","address":"485 Main Street","city":"Hudson, NC","phone":"828-572-3436"},
        {"storeNo":"10815","address":"4709 E Highway 27","city":"Iron Station, NC","phone":"980-281-1712"},
        {"storeNo":"06871","address":"306 W Jefferson St","city":"Jackson, NC","phone":"252-574-6674"},
        {"storeNo":"11669","address":"2100 Burgaw Highway","city":"Jacksonville, NC","phone":"910-378-9930"},
        {"storeNo":"00342","address":"1419 N Marine Blvd","city":"Jacksonville, NC","phone":"910-378-9966"},
        {"storeNo":"08764","address":"4634 Gum Branch Rd","city":"Jacksonville, NC","phone":"910-378-2432"},
        {"storeNo":"00185","address":"1909 N Cannon Blvd","city":"Kannapolis, NC","phone":"980-340-9091"},
        {"storeNo":"06600","address":"318 Oak Ave Mall Dr","city":"Kannapolis, NC","phone":"980-340-9022"},
        {"storeNo":"00095","address":"610 S Cannon Blvd","city":"Kannapolis, NC","phone":"980-340-9201"},
        {"storeNo":"00027","address":"311 N. Main Street","city":"Kenansville, NC","phone":"910-275-4028"},
        {"storeNo":"00269","address":"110 E King St","city":"Kings Mountain, NC","phone":"980-291-0246"},
        {"storeNo":"00055","address":"702 Plaza Blvd","city":"Kinston, NC","phone":"252-208-9211"},
        {"storeNo":"07367","address":"2046 Highway 258 N","city":"Kinston, NC","phone":"252-208-9256"},
        {"storeNo":"00783","address":"701 Plaza Blvd.","city":"Kinston, NC","phone":"252-468-0211"},
        {"storeNo":"10583","address":"2520 Hodge Road","city":"Knightdale, NC","phone":"984-733-5987"},
        {"storeNo":"11959","address":"2941 S. Smithfield Rd.","city":"Knightdale, NC","phone":"984-529-1036"},
        {"storeNo":"07858","address":"220 Island Ford Road","city":"Maiden, NC","phone":"828-705-1448"},
        {"storeNo":"11936","address":"7071 U.S. Highway 421","city":"Mamers, NC","phone":"414-285-6162"},
        {"storeNo":"00294","address":"522 West Henderson Street","city":"Marion, NC","phone":"828-559-9003"},
        {"storeNo":"00171","address":"5707 Us Hwy 25 -70 Ste S","city":"Marshall, NC","phone":"828-649-5609"},
        {"storeNo":"02712","address":"7210 E Marshville Blvd","city":"Marshville, NC","phone":"980-480-1103"},
        {"storeNo":"00722","address":"108 S Patterson Street","city":"Maxton, NC","phone":"910-240-2206"},
        {"storeNo":"01007","address":"175 Freedom Way Ste 10","city":"Midway Park, NC","phone":"910-378-9914"},
        {"storeNo":"00512","address":"196 Wilkesboro St","city":"Mocksville, NC","phone":"743-259-0042"},
        {"storeNo":"00034","address":"106 E Sunset Dr","city":"Monroe, NC","phone":"980-239-7328"},
        {"storeNo":"03544","address":"807 E Roosevelt Blvd","city":"Monroe, NC","phone":"980-239-7463"},
        {"storeNo":"04241","address":"2114f W Roosevelt Blvd","city":"Monroe, NC ","phone":"980-239-7526"},
        {"storeNo":"00059","address":"461 E. Plaza Dr","city":"Mooresville, NC","phone":"980-447-8125"},
        {"storeNo":"08533","address":"8080 Us Highway 52 South","city":"Morven, NC ","phone":"980-354-1501"},
        {"storeNo":"00018","address":"1116 W Pine St","city":"Mount Airy, NC","phone":" 743-212-0267"},
        {"storeNo":"01798","address":"7630 Nc Hwy 73","city":"Mount Pleasant, NC","phone":"980-202-4159"},
        {"storeNo":"00575","address":"849 W Main St","city":"Murfreesboro, NC","phone":"252-319-6274"},
        {"storeNo":"00771","address":"223 E. Washington Street","city":"La Grange, NC","phone":"252-582-0352"},
        {"storeNo":"07679","address":"9161 Morgan Street","city":"Laurel Hill, NC","phone":"910-361-2858"},
        {"storeNo":"00141","address":"1221 S Main St","city":"Laurinburg, NC","phone":"910-384-0461"},
        {"storeNo":"02890","address":"1112 Aberdeen Rd","city":"Laurinburg, NC","phone":"910-384-0642"},
        {"storeNo":"10962","address":"1411 Lanvale Road Ne","city":"Leland, NC","phone":"910-218-0955"},
        {"storeNo":"00400","address":"112-C Village Rd Ste 7","city":"Leland, NC","phone":"910-408-5383"},
        {"storeNo":"00009","address":"230 Morganton Blvd Sw","city":"Lenoir, NC ","phone":"828-572-6269"},
        {"storeNo":"10907","address":"6265 Old Us Hwy 52","city":"Lexington, NC","phone":"336-530-9691"},
        {"storeNo":"00020","address":"927 South Main Street","city":"Lexington, NC","phone":"743-230-2467"},
        {"storeNo":"00075","address":"805 E Main St","city":"Lincolnton, NC","phone":"980-281-1421"},
        {"storeNo":"08167","address":"2677 E. Main Street","city":"Lincolnton, NC","phone":"980-281-1413"},
        {"storeNo":"10525","address":"100 Lizard Creek Road","city":"Littleton, NC","phone":"252-629-1416"},
        {"storeNo":"07723","address":"236 Highway 158e","city":"Littleton, NC","phone":"252-629-1054"},
        {"storeNo":"00108","address":"1719 N Roberts Ave","city":"Lumberton, NC","phone":"910-225-4471"},
        {"storeNo":"10949","address":"1305 East 5th Street","city":"Lumberton, NC","phone":"910-225-4294"},
        {"storeNo":"13919","address":"5472 Us Highway 301 S","city":"Lucama, NC","phone":"252-307-3421"},
        {"storeNo":"00113","address":"121 S Bickett Blvd.","city":"Louisburg, NC","phone":"984-320-1381"},
        {"storeNo":"05999","address":"1015 Main Street W","city":"Locust, NC","phone":"980-354-1433"}
      ]

    }
  },
  components: {
  },
  watch: {
    // 监听 newOrderId 变量的变化
    newOrderId(newValue) {
      if (newValue) {
       // console.log('newOrderId 变化，启动定时任务');
        this.startTimer();  // 启动定时任务
      } else {
        this.stopTimer();   // 如果 newOrderId 为空，停止定时任务
      }
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  mounted() {
    this.gensFuncs();
  },
  methods: {
      printElement() {
        const printWindow = window.open('', '_blank',);
        const element = document.getElementById("printContent");
        const printContent = element.innerHTML;
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();

    },
    gensFuncs(){
      this.orderDate = this.getRandomDateTime();
      var index = Math.floor(Math.random() * this.storeData.length);
      this.storeNo =  this.storeData[index].storeNo;
      this.address =  this.storeData[index].address;
      if(this.address.length>25){
        this.address = this.address.substring(0, 25);
      }
      this.phone =  this.storeData[index].phone;
      this.city =  this.storeData[index].city;
      this.iptRows = Math.floor(Math.random() * (10 - 5 + 1)) + 5;
      this.iptRowsFull = this.iptRows < 10 ? '0' + this.iptRows : this.iptRows.toString();
      //单号
      this.seq4No = Math.floor(Math.random() * (6000 - 3000 + 1) + 3000);
      let num2 = Math.floor(Math.random() * (99 - 11 + 1) + 11);
      this.seqNo = this.seq4No+""+num2;
      //条形码
      let barcodeFrist ="99";
      let barcodeSecond = Math.random().toString().slice(2, 7);
      this.barcodeValue = barcodeFrist+''+this.storeNo+""+num2+""+this.seq4No+""+this.iptRowsFull+""+barcodeSecond;
      //信用卡后四位
      /*var myArray =[1972,1191,1614,1785,1798,1516,1077,1772,1892,1379,1764,1621,1342,1397,1589,1568,1074,1722,1111,1651,1986,1212,1494,1859,1633,1601,1496,1277,1945,1552,1030,1153,1244,1708,1549,1344,1038,1086,1336,1814,1635,1484,1820,1178,1490,1808,1511,1163,1717,1324,1398,1261,1940,1833,1522,1681,1269,1775,1310,1490,1891,1759,1288,1372,1168,1572,1409,1863,1879,1167,1467,1385,1083,1058,1918,1313,1117,1292,1351,1423,1910,1283,1773,1578,1122,1024,1297,1810,1941,1412,1081,1499,1117,1345,1593,1939,1971,1267,1552,1258];
      let myIndex = Math.floor(Math.random() * myArray.length);
      this.card4No = myArray[myIndex];*/

      this.code7No = Math.random().toString().slice(2, 9);
      //打印机编号后5位
      this.aId5No = Math.floor(Math.random() * (926150 - 925160 + 1)) + 925160;
      //日期
      this.orderDate = this.getRandomDateTime();
      //行数随机
      let num13 = this.generateRandomString(13);
      this.issuer = this.iptRows+""+num13;
      this.genRows= parseInt(this.iptRows);
      this.subTotalPrice =  (this.genRows*this.itemPrice).toFixed(2);
      this.totalPrice =  ((this.genRows*this.itemPrice)+(this.genRows*this.itemPrice)*this.tax10).toFixed(2);
      this.taxPrice = ((this.genRows*this.itemPrice)*this.tax10).toFixed(2);
      this.showIptRows = this.iptRows < 10 ? this.iptRows.toString()+'\xa0'+'\xa0' : this.iptRows.toString();
      this.generateBarcode();
    },

    generateRandomString(num) {
      let digitsCount = 0;
      let result = '';

      while (result.length < num) {
        const randomChar = Math.random() >= 0.5 ? this.getRandomChar() : this.getRandomDigit();
        result += randomChar;
        if (randomChar >= '0' && randomChar <= '9') {
          digitsCount++;
        }
      }
      // 确保至少有4位数字
      while (digitsCount < 4) {
        const randomIndex = Math.floor(Math.random() * result.length);
        const charAtIndex = result.charAt(randomIndex);
        if (charAtIndex >= '0' && charAtIndex <= '9') {
          result = result.slice(0, randomIndex) + this.getRandomDigit() + result.slice(randomIndex);
          digitsCount++;
        }
      }
      return result;
    },
    getRandomChar() {
      return String.fromCharCode(65 + Math.floor(Math.random() * 26)); // A-Z
    },
    getRandomDigit() {
      return String.fromCharCode(48 + Math.floor(Math.random() * 10)); // 0-9
    },

    getRandomDateTime() {
      const startYear = 2016;
      const endYear = 2024;
      const startTime = new Date(startYear, 4, 21); // 2016年的4月21日
      const diff = new Date(endYear, 7, 13) - startTime; // 2024年的7月1日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      const year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }
      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      const tempHours =  Math.floor(Math.random() * (19 - 12 + 1)) + 12;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');
      const seconds = String(randomDate.getSeconds()).padStart(2, '0');
      return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },

    generateBarcode() {
      // 使用 JsBarcode 库生成条形码
      JsBarcode(this.$refs.barcode, this.barcodeValue, {
        format: "CODE128", // 条形码格式，可选 CODE128、EAN 等
        lineColor: "#000",
        width: 1.7,
        height: 40,
        displayValue: false // 是否显示条形码值
      });
    },
    // 判断设备是否为移动端
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 使用正则检查 userAgent 中是否包含手机/平板的关键字
      return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile|webos.*mobile/i.test(userAgent);
    },
    isMobileBrowerSize() {
      return window.innerWidth <= 768;  // 视窗宽度小于等于 768px 视为移动端
    },

  },
  beforeDestroy() {

  },
  created() {

  }
}
</script>

<style >
@media print {
  body {
    font-family: "Times New Roman", Times, serif;
  }
}
</style>