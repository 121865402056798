npm <template>

    <div style="height: 100vh">
      <div style="padding-left: 15px;font-size: 18px">
      <p>行数 ：<input v-model="iptRows"  style="width: 80px" size="2" type="number"> 卡号 ：<input v-model="card4No" style="width: 200px"  size="4" type="number" > 单号：<input v-model="seqNo" style="width: 200px" maxlength="6" type="number"></p>
      <p>条形码 ：<input v-model="barcodeValue"> 日期：<input v-model="orderDate" style="width: 300px" size="2"  >
      <button @click ="gensFuncs()" style="margin-left:10px;width: 80px;background-color: #00afff;height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">生成</button>
      <button @click ="printElement()" style="margin-left:10px;width: 80px;background-color: #096c0b; height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">打印</button></p>
      </div>
      <div  style="background-color: #4AB7BD;height: auto;padding-top: 20px;">
      <div id="printContent" style="-webkit-font-smoothing:antialiased;font-family: 'Times New Roman', Times, serif;font-size: 14px;font-weight: lighter">
        <div   style="padding-bottom:70px;line-height:7.8px;font-size:14px;text-align: center;width: 300px;background-color: #FFFFFF;font-family: 'Times New Roman';font-size: 5px;margin: auto">
        <p style="padding-top: 60px;font-size: 14px;font-weight: bold" v-html="'FAMILY' + '&nbsp;'.repeat(2*2) + 'DOLLAR'"></p>
        <div style="text-align: left">
<!--          <p style="padding-left: 20px;font-size: 14px;" v-html="'STORE' + '&nbsp;'.repeat(2*2) + '#'+storeNo + '&nbsp;'.repeat(2*2) + ' '+address"></p>-->
<!--          <p style="padding-left: 40px;font-size: 14px;" v-html="city+',' + '&nbsp;'.repeat(2*2)+phone"></p>-->
          <p class="container" style="padding-left: 10px;font-size: 14px; text-align: center;" ><span style="display: inline-block;width: 40%; text-align: right; " v-html="'STORE' + '&nbsp;'.repeat(2*2) + '#'+storeNo"></span><span style="display: inline-block;width: 60%; text-align: center; " v-html="address"></span></p>
          <p class="container2" style="font-size: 14px; text-align: center;"><span style="display: inline-block;width: 55%; text-align: center; " v-html="city +','"></span><span style="display: inline-block;width: 45%; text-align: left; " v-html="phone"></span></p>
          <div v-for="(item,index) in genRows">
            <p style="padding-left: 10px;font-size: 14px;" v-html="'BREYERS' + '&nbsp;'.repeat(1*1) + 'ICE' + '&nbsp;'.repeat(1*1) + 'CREAM' + '&nbsp;'.repeat(1*1) + 'VANILLA 480Z'"></p>
            <p style="padding-left: 20px;font-size: 14px;" v-html="'077567254238' + '&nbsp;'.repeat(39*1) + itemPrice.toFixed(2)+' F'"></p>
          </div>
          <p style="padding-left: 10px; font-size: 14px;" v-html="'SUBTOTAL' + '&nbsp;'.repeat(23*2) + '$'+subTotalPrice"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'TAX'+showIptRows + '&nbsp;'.repeat(27*2) + '$'+taxPrice"></p>
          <p style="padding-left: 10px;font-size: 14px;"><span style="font-weight: bold" v-html="'T O T A L' + '&nbsp;'.repeat(49*1)"></span><span v-html="'$'+totalPrice"></span></p>
          <p style="padding-left: 10px;font-size: 14px;"><span style="font-weight: bold" v-html="'V I S A' + '&nbsp;'.repeat(54*1)"></span><span v-html="'$'+totalPrice"></span></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'US' + '&nbsp;'.repeat(2*2) + 'DEBIT ************'+card4No"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'CHIP'+ '&nbsp;'.repeat(2*2) +'CONTACTLESS'+ '&nbsp;'.repeat(2*2) +'Approved'"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'AUTH#'+ '&nbsp;'.repeat(1) +'BBU5LJ'+ '&nbsp;'.repeat(2*2) +'SEQUENCE'+ '&nbsp;'.repeat(2*2)+'NO: '+seqNo"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'Mode:'+ '&nbsp;'.repeat(2) +'Issuer'+ '&nbsp;'.repeat(2*2) +'ARQC - '+'C'+issuer"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'AID:'+ '&nbsp;'.repeat(2) +'A0000000' +aId5No+ '&nbsp;'.repeat(2*2) +'ARC:'"></p>
          <p style="font-size: 14px;text-align: center;margin-top: -3px"  > <svg ref="barcode"></svg></p>
          <p style="font-size: 14px;text-align: center;margin-top: -12px" v-html="barcodeValue.replace(/(.)(?=.)/g, '$1 ')"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="'ITEMS '+iptRows"></p>
          <p style="padding-left: 10px;font-size: 14px;" v-html="orderDate+' '+storeNo+' '+iptRowsFull+' '+code7No+' '+seq4No"></p>

          <p style="padding-left: 20px;font-size: 14px;" v-html="'Cash check Debit Credit &'+ '&nbsp;'.repeat(2*2) +'EBT'+ '&nbsp;'.repeat(2*2) +'Accepted.'"></p>
          <p style="font-size: 14px;text-align: center" v-html="'THANK YOU.'"></p>
        </div>
      </div>
      </div>
    </div>
    </div>
</template>

<style>

</style>
<script>
import {initApkHub, newestApkUrl, queryApkHub} from "@/api/index";
import QRCode from 'qrcode'
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';
import { Circle } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';
import { Image as VanImage } from 'vant';
import { Card } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { Notify } from 'vant';
import JsBarcode from 'jsbarcode'
Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);
import  apkhubLogo  from "../../assets/img/APKHub.png";
export default {
  data () {
    return {
      storeNo:'',
      phone:'',
      city:'',
      address:'',
      seq4No:'',
      issuer:'',
      iptRowsFull:'',
      pageRows:1,
      iptRows:3,
      showIptRows:'',
      itemPrice:5.50,
      tax10:0.02,
      taxPrice:0.0,
      totalPrice:0.0,
      subTotalPrice:0.0,
      genRows:3,
      card4No:9311,
      seqNo:304740,
      barcodeValue:'9900300230130764390',
      orderDate:'',
      aId5No:925659,
      code7No:1911123,
      storeData:[{"city":"DOWNEY CA","phone":"562-622-4891","address":"12270 PARAMOUNT BLVD","postalCode":"90242","storeNo":"05603","manager":"FREEMAN MARK"},
        {"city":"PICO RIVERA CA","phone":"562-801-2413","address":"8500 WASHINGTON BLVD","postalCode":"90660","storeNo":"02886","manager":"EDDY M CALZADO"},
        {"city":"DOWNEY CA","phone":"562-803-3507","address":"9001 APOLLO WAY","postalCode":"90242","storeNo":"04132","manager":"NORMAN NOBLE"},
        {"city":"HAWTHORNE CA","phone":"424-336-2212","address":"14441 INGLEWOOD AVE","postalCode":"90250","storeNo":"05604","manager":"AMANDA CARPENTER"},
        {"city":"PARAMOUNT CA","phone":"562-531-8240","address":"14501 LAKEWOOD BLVD","postalCode":"90723","storeNo":"02110","manager":"NATHANIEL GOLSON"}
      ]
    }
  },
  components: {
  },
  watch: {
    // 监听 newOrderId 变量的变化
    newOrderId(newValue) {
      if (newValue) {
       // console.log('newOrderId 变化，启动定时任务');
        this.startTimer();  // 启动定时任务
      } else {
        this.stopTimer();   // 如果 newOrderId 为空，停止定时任务
      }
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  mounted() {
    this.gensFuncs();
  },
  methods: {
    gensFuncs(){
     /* var storeList = JSON.parse(this.storeData);
      storeList.forEach(function(store) {
        if(store.telephone){
          console.log('Name: ' + store.telephone);
        }

      });*/
      for(let i = 1; i <= this.storeData.length; i++) {

          /*var tempStore = String(this.storeData[i].storeNo).padStart(5, '0');
          var json = '{"city":"' + this.storeData[i].city+ '","phone":"' + this.storeData[i].phone + '","address":"' + this.storeData[i].address + '","postalCode":"' + this.storeData[i].postalCode + '","storeNo":"' + tempStore + '","manager":"' + this.mamagerData[i].manager + '"}';
          console.log(json+",","\n")*/

      }

    }
  },
  beforeDestroy() {

  },
  created() {

  }
}
</script>

<style >
@media print {
  body {
    font-family: "Times New Roman", Times, serif;
  }
}
</style>