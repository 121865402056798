npm <template>

  <div style="height: 100vh">
    <div style="padding-left: 15px;font-size: 18px">
      <p>张数 ：<input v-model="pageRows"  style="width: 80px" @input="pageRowsInput" type="number"> 卡号 ：<input v-model="card4No" style="width: 200px"  @input="handleInput" type="number" >
        <button @click ="printElement()" style="margin-left:10px;width: 180px;background-color: #096c0b; height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">Lodop打印</button></p>
    </div>
    <div  style="background-color: #4AB7BD;height: auto;padding-top: 20px;">
      <div id="printContent" style="-webkit-font-smoothing:antialiased;font-family: 'Times New Roman', Times, serif;font-weight: lighter">
        <div   style="padding-bottom:70px;line-height:6px;text-align: center;width: 300px; font-size: 12px; background-color: #FFFFFF; margin: auto">
          <p style="padding-top: 10px;"><img src='/image/target-logo.png' width="250px"/></p>
          <div style="text-align: left; margin-top: 15px">
            <p class="container" style="padding-left: 10px; text-align: center;" ><span v-html="name+' - '+phone"></span></p>
            <p style="padding-left: 10px;text-align: center;"><span style="display: inline-block; text-align: center; " v-html="address"></span></p>
            <p style="padding-left: 10px; text-align: center;"><span style="display: inline-block; text-align: center; " v-html="city+ '&nbsp;'.repeat(2*1)+zip"></span></p>
            <p style="text-align: center" v-html="orderTempDate+ '&nbsp;'.repeat(10) +orderDate"></p>
            <p style="text-align: center;margin-top: 11px"  > <svg ref="barcode"></svg></p>
            <p style="padding-left: 15px; font-weight: bold;  text-align: left;"><span v-html="'GROCERY'"></span></p>
            <div v-if="showProduceData" v-for="(item, index) in showProduceData" :key="index">
              <p class="container2" style="padding-left: 15px;"><span style="display: inline-block;width: 25%; text-align: left; " v-html="item.productCode"/><span style="display: inline-block;width: 40%; text-align: left; " v-html="item.name"/><span style="display: inline-block;width: 15%; text-align: left;" v-html="'BF'"/><span style="display: inline-block;width: 20%; text-align: left;  " v-html="'$'+ item.price"/></p>
            </div>
            <p style="padding-left: 15px; font-weight: bold;  text-align: left;"><span v-html="'TOYS' + '&nbsp;'.repeat(2)+'-' + '&nbsp;'.repeat(2)+'SPORTING' + '&nbsp;'.repeat(4)+'GOODS'"></span></p>
            <div v-if="showIceProduceData" v-for="(item, index) in showIceProduceData" :key="index">
              <p class="container2" style="padding-left: 15px;"><span style="display: inline-block;width: 25%; text-align: left; " v-html="item.productCode"/><span style="display: inline-block;width: 40%; text-align: left; " v-html="item.name"/><span style="display: inline-block;width: 15%; text-align: left;" v-html="'TF'"/><span style="display: inline-block;width: 20%; text-align: left;" v-html="'$'+ item.price"/></p>
            </div>
            <p style="padding-left: 125px; margin-top: 20px;" v-html="'SUBTOTAL' + '&nbsp;'.repeat(20) +'$'+subTotalPrice"></p>
            <p style="padding-left: 40px;"><span  v-html="'B' + '&nbsp;'.repeat(2)+'=' + '&nbsp;'.repeat(3)+'TN' + '&nbsp;'.repeat(2)+'TAX' + '&nbsp;'.repeat(3)+'6.5000' + '&nbsp;'.repeat(3)+'on' + '&nbsp;'.repeat(3)+'$'+tempPrice"></span><span style="margin-left: 30px" v-html="'$'+taxPrice"></span></p>
            <p style="padding-left: 40px;"><span  v-html="'T' + '&nbsp;'.repeat(2)+'=' + '&nbsp;'.repeat(3)+'TN' + '&nbsp;'.repeat(2)+'TAX' + '&nbsp;'.repeat(3)+'7.5000' + '&nbsp;'.repeat(3)+'on' + '&nbsp;'.repeat(3)+'$'+tempIcePrice"></span><span style="margin-left: 25px" v-html="'$'+taxIcePrice"></span></p>
            <p style="padding-left: 150px; " v-html="'TOTAL' + '&nbsp;'.repeat(20) +'$'+totalPrice"></p>
            <p style="padding-left: 90px;" v-html="'*' + '&nbsp;'.repeat(1)+card4No+ '&nbsp;'.repeat(2)+'VISA'+ '&nbsp;'.repeat(2)+'CHARGE'+ '&nbsp;'.repeat(12)+'$'+totalPrice"></p>
            <p style="padding-left: 160px;" v-html="'AID:'+ '&nbsp;'.repeat(2)+'A00000000'+aId5No"></p>
            <p style="padding-left: 150px;" v-html="'AUTH  CODE:'+ '&nbsp;'.repeat(10)+code6No"></p>

            <p style="padding-top: 1px;"></p>
            <p style="padding-left: 15px;  word-spacing: 3px; letter-spacing: 2px; text-align: center;"><span v-html="'Your Target Circle earnings are in!'"></span></p>
            <p style="padding-left: 15px;  word-spacing: 3px; letter-spacing: 2px; text-align: center;"><span v-html="'Open the Target App or visit'"></span></p>
            <p style="padding-left: 15px;  word-spacing: 3px; letter-spacing: 2px; text-align: center;"><span v-html="'Target.com/Circle to see you benefits.'"></span></p>
            <p style="padding-left: 15px; text-align: center;word-spacing: 5px;letter-spacing: 2px; margin-top:20px;"><span v-html="'-----------------------------------------'"></span></p>
            <p style="padding-left: 15px; text-align: center;letter-spacing: 1px;"><span v-html="'TOTAL SAVINGS THIS TRIP'"></span></p>
            <p style="padding-left: 15px; text-align: center;word-spacing: 5px;letter-spacing: 2px;"><span v-html="'-----------------------------------------'"></span></p>
            <p style="padding-left: 15px; margin-top:20px;text-align: center;letter-spacing: 1px;"><span v-html="'RECEIPT'+ '&nbsp;'.repeat(1)+'ID:'+'&nbsp;'.repeat(2)+receipt"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>
<script>
import {initApkHub, newestApkUrl, queryApkHub} from "@/api/index";
import QRCode from 'qrcode'
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';
import { Circle } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';
import { Image as VanImage } from 'vant';
import { Card } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { Notify } from 'vant';
import JsBarcode from 'jsbarcode'
Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
  data () {
    return {
      storeId:'',
      phone:'',
      city:'',
      address:'',
      name:'',
      tempAddress:'',
      seq4No:'',
      issuer:'',
      iptRowsFull:'',
      pageRows:1,
      iptRows:3,
      showIptRows:'',
      itemPrice:5.50,
      tax10:0.065,
      tax20:0.075,
      tempPrice:0.00,
      tempIcePrice:0.00,
      taxPrice:0.0,
      taxIcePrice:0.0,
      totalPrice:0.0,
      cashAmount:100.00,
      changeAmount:0.00,
      subTotalPrice:0.00,
      genRows:3,
      card4No:9311,
      seqNo:304740,
      receipt:'9900300230130764390',
      barcodeValue:'9900300230130764390',
      orderDate:'',
      orderTempDate:'',
      aId5No:925650,
      code6No:191113,
      codeFrist:'2050',
      codeSecend:'7177',
      codeThird:'0022',
      showProduceData:[{"name":"TRU FRU","price":"7.99","productCode":"201211751"}],
      showIceProduceData:[{"name":"FISHER-PRICE","price":"27.99","productCode":"204103330"}],
      iceRows:0,
      storeData:[
        {"storeID":"3380","phone":"332-266-7061","address":"10 Union Square East","city":"New York, NY","name":"Manhattan Union Square","postalCode":"10003-3314"},
        {"storeID":"3277","phone":"646-968-4739","address":"112 W 34th St","city":"New York, NY","name":"Manhattan Herald Square","postalCode":"10120-0101"},
        {"storeID":"3321","phone":"646-822-4567","address":"512 2nd Ave","city":"New York, NY","name":"Kips Bay","postalCode":"10016-8604"},
        {"storeID":"3411","phone":"646-822-5200","address":"600 Broadway","city":"New York City, NY","name":"Manhattan Soho","postalCode":"10012-3206"},
        {"storeID":"3385","phone":"212-884-2217","address":"237 W 42nd St","city":"New York, NY","name":"Times Square 42nd Street","postalCode":"10036-7201"},
        {"storeID":"3249","phone":"917-994-3522","address":"500 E 14th St","city":"New York, NY","name":"Manhattan East Village","postalCode":"10009-3336"},
        {"storeID":"3268","phone":"212-389-1418","address":"615 10th Ave","city":"New York, NY","name":"Manhattan Hells Kitchen","postalCode":"10036-3035"},
        {"storeID":"3284","phone":"917-994-3500","address":"400 Grand St","city":"New York, NY","name":"Lower East Side Grand and Clinton","postalCode":"10002-3949"},
        {"storeID":"3229","phone":"917-438-2214","address":"255 Greenwich St","city":"New York, NY","name":"Tribeca","postalCode":"10007-2377"},
        {"storeID":"1886","phone":"201-499-0017","address":"100 14th St","city":"Jersey City, NJ","name":"Jersey City","postalCode":"07310-1202"},
        {"storeID":"3334","phone":"646-429-3934","address":"1863 Broadway","city":"New York, NY","name":"Upper West Side 61st and Broadway","postalCode":"10023-7503"},
        {"storeID":"3312","phone":"332-204-1718","address":"1201 3rd Ave","city":"New York, NY","name":"Upper East Side 70th and 3rd","postalCode":"10021-5401"},
        {"storeID":"3433","phone":"718-663-2125","address":"25-01 Jackson Ave","city":"Long Island City, NY","name":"LIC Court Square","postalCode":"11101-5095"},
        {"storeID":"3387","phone":"646-758-8596","address":"150 E 86th St","city":"New York, NY","name":"Upper East Side 86th and Lex","postalCode":"10028-2118"},
        {"storeID":"2850","phone":"929-397-2864","address":"445 Albee Square West","city":"BROOKLYN, NY","name":"Brooklyn Fulton St","postalCode":"11201-3016"},
        {"storeID":"3394","phone":"646-758-8424","address":"795 Columbus Ave","city":"New York City, NY","name":"Upper West Side 98th and Columbus","postalCode":"10025-5920"},
        {"storeID":"1849","phone":"718-290-1109","address":"139 Flatbush Ave","city":"Brooklyn, NY","name":"Atlantic Terminal","postalCode":"11217-1403"},
        {"storeID":"1865","phone":"201-520-1701","address":"7101 Tonnelle Ave","city":"North Bergen, NJ","name":"North Bergen Commons","postalCode":"07047-4507"},
        {"storeID":"1263","phone":"201-402-0252","address":"543 River Rd","city":"Edgewater, NJ","name":"Edgewater","postalCode":"07020-1146"},
        {"storeID":"2259","phone":"202-777-3773","address":"3100 14th St NW","city":"Washington, DC","name":"Columbia Heights","postalCode":"20010-2444"},
        {"storeID":"3328","phone":"202-892-3822","address":"7828 Georgia Ave NW","city":"Washington, DC","name":"Georgia and Eastern","postalCode":"20012-1305"},
        {"storeID":"3308","phone":"202-892-3794","address":"3505 Connecticut Ave NW","city":"Washington, DC","name":"Cleveland Park","postalCode":"20008-2400"},
        {"storeID":"3351","phone":"202-292-5573","address":"4500 Wisconsin Ave NW","city":"Washington, DC","name":"DC Tenleytown","postalCode":"20016-4628"},
        {"storeID":"3320","phone":"202-914-2396","address":"1515 New York Ave NE","city":"Washington, DC","name":"DC New York Ave NE","postalCode":"20002-1744"},
        {"storeID":"1890","phone":"301-955-0005","address":"3500 East West Hwy Ste 1200","city":"Hyattsville, MD","name":"Prince Georges Plaza","postalCode":"20782-5003"},
        {"storeID":"3242","phone":"240-781-3725","address":"6831 Wisconsin Ave","city":"Bethesda, MD","name":"Bethesda","postalCode":"20815-6122"},
        {"storeID":"3210","phone":"571-777-2438","address":"1500 Wilson Blvd","city":"Rosslyn, VA","name":"Rosslyn","postalCode":"22209-2458"},
        {"storeID":"1415","phone":"301-946-8006","address":"11160 Veirs Mill Rd","city":"Silver Spring, MD","name":"Wheaton","postalCode":"20902-2538"},
        {"storeID":"3430","phone":"571-403-5242","address":"900 Army Navy Dr, Ste a2","city":"Arlington, VA","name":"Pentagon City Army Navy Dr","postalCode":"22202-4935"},
        {"storeID":"1295","phone":"301-837-0054","address":"6100 Greenbelt Rd","city":"Greenbelt, MD","name":"Greenbelt","postalCode":"20770-4063"},
        {"storeID":"3257","phone":"703-682-9584","address":"740 N Glebe Rd","city":"Arlington, VA","name":"Ballston","postalCode":"22203-2257"},
        {"storeID":"1138","phone":"301-586-0724","address":"12000 Cherry Hill Rd","city":"Silver Spring, MD","name":"Silver Spring","postalCode":"20904-1985"},
        {"storeID":"1076","phone":"703-706-3840","address":"3101 Richmond Hwy","city":"Alexandria, VA","name":"Alexandria","postalCode":"22305-3042"},
        {"storeID":"1431","phone":"703-237-8118","address":"6100 Arlington Blvd","city":"Falls Church, VA","name":"Falls Church","postalCode":"22044-2901"},
        {"storeID":"1854","phone":"301-945-0018","address":"5700 Bou Ave","city":"Rockville, MD","name":"Rockville","postalCode":"20852-1663"},
        {"storeID":"1893","phone":"703-253-0021","address":"5115 Leesburg Pike","city":"Falls Church, VA","name":"Skyline","postalCode":"22041-3207"},
        {"storeID":"1897","phone":"301-778-1651","address":"3101 Donnell Dr","city":"District Heights, MD","name":"Forestville","postalCode":"20747-3204"},
        {"storeID":"3339","phone":"301-281-2718","address":"6163 Oxon Hill Rd","city":"Oxon Hill, MD","name":"Oxon Hill","postalCode":"20745-3108"},
        {"storeID":"2007","phone":"301-955-0107","address":"10401 Martin Luther King Jr Hwy","city":"Bowie, MD","name":"Lanham","postalCode":"20720-4201"},
        {"storeID":"2493","phone":"678-975-3060","address":"916 Loganville Hwy","city":"Bethlehem, GA","name":"Winder","postalCode":"30620-2145"},
        {"storeID":"1206","phone":"678-482-2367","address":"3205 Woodward Crossing Blvd","city":"Buford, GA","name":"Buford","postalCode":"30519-4938"},
        {"storeID":"747","phone":"770-338-4460","address":"875 Lawrenceville Suwanee Rd","city":"Lawrenceville, GA","name":"Lawrenceville","postalCode":"30043-8479"},
        {"storeID":"2387","phone":"770-967-1205","address":"5865 Spout Springs Rd","city":"Flowery Branch, GA","name":"Flowery Branch","postalCode":"30542-3448"},
        {"storeID":"917","phone":"770-736-1113","address":"1905 Scenic Hwy N","city":"Snellville, GA","name":"Snellville","postalCode":"30078-5633"},
        {"storeID":"796","phone":"770-532-6670","address":"514 Shallowford Rd NW","city":"Gainesville, GA","name":"Gainesville","postalCode":"30504-4154"},
        {"storeID":"2056","phone":"678-513-8013","address":"2625 Peachtree Pkwy","city":"Suwanee, GA","name":"Suwanee Peachtree Pkwy","postalCode":"30024-1048"},
        {"storeID":"749","phone":"770-476-5548","address":"5950 State Bridge Rd","city":"Johns Creek, GA","name":"Johns Creek State St and 141","postalCode":"30097-6438"},
        {"storeID":"1394","phone":"678-455-9618","address":"1525 Market Place Blvd","city":"Cumming, GA","name":"Cumming","postalCode":"30041-7935"},
        {"storeID":"982","phone":"770-849-0885","address":"3200 Holcomb Bridge Rd","city":"Peachtree Corners, GA","name":"Peachtree Corners","postalCode":"30092-3361"},
        {"storeID":"1453","phone":"706-208-9379","address":"3065 Atlanta Hwy","city":"Athens, GA","name":"Athens","postalCode":"30606-3334"},
        {"storeID":"2431","phone":"770-225-1780","address":"13055 Highway 9 N","city":"Milton, GA","name":"Milton","postalCode":"30004-5137"},
        {"storeID":"970","phone":"770-664-5395","address":"6000 N Point Pkwy","city":"Alpharetta, GA","name":"Alpharetta","postalCode":"30022-3006"},
        {"storeID":"1390","phone":"770-270-5375","address":"4241 Lavista Rd","city":"Tucker, GA","name":"Northlake","postalCode":"30084-5310"},
        {"storeID":"3362","phone":"706-715-3851","address":"127 E Broad St","city":"Athens, GA","name":"Athens Broad Street","postalCode":"30601-2852"},
        {"storeID":"2174","phone":"770-785-6470","address":"2195 Hwy 20 SE","city":"Conyers, GA","name":"Conyers","postalCode":"30013-2028"},
        {"storeID":"2036","phone":"678-259-0888","address":"100 Perimeter Center Pl","city":"Atlanta, GA","name":"Atlanta Perimeter","postalCode":"30346-1204"},
        {"storeID":"1486","phone":"404-267-0060","address":"2400 N Druid Hills Rd NE","city":"Atlanta, GA","name":"North Druid Hills","postalCode":"30329-3211"},
        {"storeID":"2333","phone":"678-704-8120","address":"5570 Roswell Rd","city":"Sandy Springs, GA","name":"Sandy Springs Prado","postalCode":"30342-1102"},
        {"storeID":"1197","phone":"404-237-9494","address":"3535 Peachtree Rd NE","city":"Atlanta, GA","name":"Buckhead","postalCode":"30326-3287"},
        {"storeID":"1071","phone":"502-326-9185","address":"4101 Towne Center Dr","city":"Louisville, KY","name":"Springhurst","postalCode":"40241-4146"},
        {"storeID":"2728","phone":"502-992-1237","address":"12975 Shelbyville Rd","city":"Louisville, KY","name":"Middletown","postalCode":"40243-2425"},
        {"storeID":"780","phone":"502-493-8717","address":"4640 Taylorsville Rd","city":"Louisville, KY","name":"Jeffersontown","postalCode":"40220-3530"},
        {"storeID":"2473","phone":"502-992-1039","address":"4174 Westport Rd","city":"Louisville, KY","name":"St Matthews","postalCode":"40207-2735"},
        {"storeID":"2068","phone":"812-284-4524","address":"1125 Veterans Pkwy","city":"Clarksville, IN","name":"Waterford Park","postalCode":"47129-2664"},
        {"storeID":"1479","phone":"502-456-6768","address":"3600 Mall Rd","city":"Louisville, KY","name":"Bashford Manor","postalCode":"40218-5403"},
        {"storeID":"1513","phone":"502-964-1053","address":"7311 Jefferson Blvd","city":"Louisville, KY","name":"Louisville Jefferson Pavilion","postalCode":"40219-6178"},
        {"storeID":"139","phone":"812-945-5046","address":"2209 State St","city":"New Albany, IN","name":"New Albany","postalCode":"47150-4924"},
        {"storeID":"1200","phone":"859-371-7771","address":"1100 Hansel Ave","city":"Florence, KY","name":"Florence","postalCode":"41042-4869"},
        {"storeID":"3313","phone":"859-286-3352","address":"500 S Upper St, STE 110","city":"Lexington, KY","name":"Lexington Campus","postalCode":"40508-2920"},
        {"storeID":"366","phone":"859-273-9403","address":"131 West Reynolds Rd","city":"Lexington, KY","name":"Lexington","postalCode":"40503-3599"},
        {"storeID":"2488","phone":"513-719-1076","address":"6150 Glenway Ave","city":"Cincinnati, OH","name":"Western Hills","postalCode":"45211-6319"},
        {"storeID":"2483","phone":"859-814-0140","address":"160 Pavilion Pkwy","city":"Newport, KY","name":"Newport","postalCode":"41071-2884"},
        {"storeID":"1094","phone":"859-263-7448","address":"1940 Pavillon Way","city":"Lexington, KY","name":"Lexington SE","postalCode":"40509-2210"},
        {"storeID":"3254","phone":"513-698-8161","address":"235 Calhoun St","city":"Cincinnati, OH","name":"UC Calhoun St","postalCode":"45219-1528"},
        {"storeID":"1545","phone":"513-719-0037","address":"9040 Colerain Ave","city":"Cincinnati, OH","name":"Colerain","postalCode":"45251-2402"},
        {"storeID":"1447","phone":"513-631-5690","address":"3245 Geier Dr","city":"Cincinnati, OH","name":"Cincinnati Central","postalCode":"45209-5009"},
        {"storeID":"1092","phone":"513-474-6006","address":"8680 Beechmont Ave","city":"Cincinnati, OH","name":"Beechmont Area","postalCode":"45255-4710"},
        {"storeID":"1911","phone":"812-376-0450","address":"1865 N National Rd","city":"Columbus, IN","name":"Columbus","postalCode":"47201-5194"},
        {"storeID":"2557","phone":"513-898-2021","address":"9099 Plainfield Rd","city":"Blue Ash, OH","name":"Blue Ash","postalCode":"45236-1245"},
        {"storeID":"1170","phone":"515-663-9500","address":"320 S Duff Ave","city":"Ames, IA","name":"Ames","postalCode":"50010-6644"},
        {"storeID":"1767","phone":"515-964-9000","address":"2135 SE Delaware Ave","city":"Ankeny, IA","name":"Ankeny","postalCode":"50021-4592"},
        {"storeID":"1791","phone":"515-270-6556","address":"11148 Plum Dr","city":"Urbandale, IA","name":"Urbandale","postalCode":"50322-6328"},
        {"storeID":"1939","phone":"515-967-9343","address":"3414 8th St SW","city":"Altoona, IA","name":"Altoona","postalCode":"50009-1024"},
        {"storeID":"2041","phone":"515-331-6115","address":"5901 Douglas Ave","city":"Des Moines, IA","name":"Des Moines North","postalCode":"50322-3303"},
        {"storeID":"69","phone":"515-225-3784","address":"1800 Valley West Dr","city":"West Des Moines, IA","name":"Valley West","postalCode":"50266-1104"},
        {"storeID":"2909","phone":"515-858-4985","address":"900 SE Laurel St","city":"Waukee, IA","name":"Waukee","postalCode":"50263-9100"},
        {"storeID":"1901","phone":"515-223-0262","address":"5405 Mills Civic Pkwy","city":"West Des Moines, IA","name":"West Des Moines SW","postalCode":"50266-5303"},
        {"storeID":"803","phone":"515-287-3438","address":"1111 E Army Post Rd Ste 2204","city":"Des Moines, IA","name":"South Des Moines","postalCode":"50315-5962"},
        {"storeID":"878","phone":"515-573-7950","address":"2910 1st Ave S","city":"Fort Dodge, IA","name":"Fort Dodge","postalCode":"50501-2972"},
        {"storeID":"2526","phone":"319-553-1120","address":"214 Viking Plaza Dr","city":"Cedar Falls, IA","name":"Cedar Falls","postalCode":"50613-6936"},
        {"storeID":"1792","phone":"319-226-6500","address":"1501 E San Marnan Dr","city":"Waterloo, IA","name":"Waterloo","postalCode":"50702-4300"},
        {"storeID":"804","phone":"641-423-8335","address":"3450 4th St SW","city":"Mason City, IA","name":"Mason City","postalCode":"50401-1572"},
        {"storeID":"1771","phone":"319-396-4444","address":"3400 Edgewood Rd SW","city":"Cedar Rapids, IA","name":"Cedar Rapids South","postalCode":"52404-7214"},
        {"storeID":"1166","phone":"815-356-9265","address":"5580 Northwest Hwy","city":"Crystal Lake, IL","name":"Crystal Lake","postalCode":"60014-8016"},
        {"storeID":"1036","phone":"847-726-1174","address":"555 S Rand Rd","city":"Lake Zurich, IL","name":"Lake Zurich","postalCode":"60047-2356"},
        {"storeID":"1801","phone":"847-458-5340","address":"750 S Randall Rd","city":"Algonquin, IL","name":"Algonquin","postalCode":"60102-5915"},
        {"storeID":"2122","phone":"847-645-1170","address":"2800 N Sutton Rd","city":"HoffMan Estates, IL","name":"Hoffman Estates","postalCode":"60192-3717"},
        {"storeID":"753","phone":"847-202-5120","address":"679 E Dundee Rd","city":"Palatine, IL","name":"Palatine","postalCode":"60074-2817"},
        {"storeID":"1912","phone":"847-367-2650","address":"3100 W IL Route 60","city":"Mundelein, IL","name":"Mundelein","postalCode":"60060-4267"},
        {"storeID":"1950","phone":"630-372-3330","address":"1001 Sutton Rd","city":"Streamwood, IL","name":"Streamwood","postalCode":"60107-2332"},
        {"storeID":"835","phone":"847-798-0192","address":"2621 W Schaumburg Rd","city":"Schaumburg, IL","name":"West Schaumburg","postalCode":"60194-3894"},
        {"storeID":"834","phone":"847-695-1992","address":"300 S Randall Rd","city":"Elgin, IL","name":"Elgin","postalCode":"60123-5525"},
        {"storeID":"880","phone":"847-413-1080","address":"1235 E Higgins Rd","city":"Schaumburg, IL","name":"Woodfield","postalCode":"60173-4939"},
        {"storeID":"833","phone":"847-680-0390","address":"313 E Townline Rd","city":"Vernon Hills, IL","name":"Vernon Hills","postalCode":"60061-1555"},
        {"storeID":"1385","phone":"847-215-5051","address":"1400 E Lake Cook Rd","city":"Wheeling, IL","name":"Wheeling","postalCode":"60090-2239"},
        {"storeID":"1176","phone":"847-222-0925","address":"1700 E Rand Rd","city":"Arlington Heights, IL","name":"Arlington Heights","postalCode":"60004-4379"},
        {"storeID":"1896","phone":"847-697-1017","address":"530 Randall Rd","city":"South Elgin, IL","name":"South Elgin","postalCode":"60177-3315"},
        {"storeID":"836","phone":"630-582-0043","address":"175 W Army Trail Rd","city":"Glendale Heights, IL","name":"Glendale Heights","postalCode":"60139-1971"},
        {"storeID":"1323","phone":"630-232-3980","address":"3885 E Main St","city":"St Charles, IL","name":"St Charles","postalCode":"60174-2400"},
        {"storeID":"865","phone":"847-244-4990","address":"6601 Grand Ave","city":"Gurnee, IL","name":"Gurnee","postalCode":"60031-4591"},
        {"storeID":"2860","phone":"847-810-8085","address":"975 Rockland Rd","city":"Lake Bluff, IL","name":"Lake Bluff","postalCode":"60044-1778"},
        {"storeID":"893","phone":"630-594-5510","address":"401 W Irving Park Rd","city":"Wood Dale, IL","name":"Wood Dale","postalCode":"60191-1338"},
        {"storeID":"1168","phone":"847-266-8022","address":"2099 Skokie Valley Rd","city":"Highland Park, IL","name":"Highland Park","postalCode":"60035-1728"},
        {"storeID":"1449","phone":"504-888-2184","address":"4500 Veterans Memorial Blvd","city":"Metairie, LA","name":"Metairie","postalCode":"70006-5330"},
        {"storeID":"2534","phone":"504-617-4859","address":"1401 W Esplanade Ave","city":"Kenner, LA","name":"Kenner","postalCode":"70065-2845"},
        {"storeID":"1451","phone":"504-227-2014","address":"1731 Manhattan Blvd","city":"Harvey, LA","name":"Harvey","postalCode":"70058-3409"},
        {"storeID":"3441","phone":"985-231-3387","address":"3450 U.S. Hwy 190 Ste 2","city":"Mandeville, LA","name":"Mandeville","postalCode":"70471-3196"},
        {"storeID":"1876","phone":"985-871-7093","address":"69320 Highway 21","city":"Covington, LA","name":"Covington","postalCode":"70433-7220"},
        {"storeID":"2531","phone":"985-277-3203","address":"2030 Hammond Square Dr","city":"Hammond, LA","name":"Hammond","postalCode":"70403-6156"},
        {"storeID":"1450","phone":"985-223-2575","address":"1727 Martin Luther King Jr Blvd","city":"Houma, LA","name":"Houma","postalCode":"70360-2409"},
        {"storeID":"2089","phone":"225-272-4275","address":"2001 Millerville Rd","city":"Baton Rouge, LA","name":"Baton Rouge East","postalCode":"70816-1408"},
        {"storeID":"1369","phone":"225-293-0984","address":"6885 Siegen Ln","city":"Baton Rouge, LA","name":"Baton Rouge Siegen","postalCode":"70809-4528"},
        {"storeID":"2485","phone":"228-233-3301","address":"3867 Promenade Pkwy","city":"D'iberville, MS","name":"D'Iberville","postalCode":"39540-5363"},
        {"storeID":"1471","phone":"303-214-0114","address":"14200 E Ellsworth Ave","city":"Aurora, CO","name":"Aurora","postalCode":"80012-1402"},
        {"storeID":"1413","phone":"303-755-8530","address":"1400 S Havana St","city":"Aurora, CO","name":"Aurora West","postalCode":"80012-4014"},
        {"storeID":"2052","phone":"303-209-8720","address":"7930 Northfield Blvd","city":"Denver, CO","name":"Denver Northfield Blvd","postalCode":"80238-3527"},
        {"storeID":"3403","phone":"303-218-5430","address":"93 N Quebec St","city":"Denver, CO","name":"Denver Lowry","postalCode":"80230"},
        {"storeID":"256","phone":"303-699-1020","address":"16910 E Quincy Ave","city":"Aurora, CO","name":"Aurora SE","postalCode":"80015-2745"},
        {"storeID":"1806","phone":"303-209-0182","address":"4301 E Virginia Ave","city":"Glendale, CO","name":"Glendale","postalCode":"80246-1510"},
        {"storeID":"2820","phone":"303-481-2821","address":"7777 E Hampden Ave","city":"Denver, CO","name":"Denver SE","postalCode":"80231-4806"},
        {"storeID":"3386","phone":"303-218-5745","address":"2490 S. Colorado Blvd","city":"Denver, CO","name":"Denver University Hills Plaza","postalCode":"80222-5907"},
        {"storeID":"2458","phone":"720-214-6000","address":"15700 E Briarwood Cir","city":"Aurora, CO","name":"Aurora South","postalCode":"80016-1558"},
        {"storeID":"3279","phone":"303-222-7520","address":"1600 California St, Ste 14","city":"Denver, CO","name":"Denver Downtown","postalCode":"80202-3709"},
        {"storeID":"147","phone":"303-790-2583","address":"6767 S Clinton St","city":"Greenwood Village, CO","name":"Arapahoe","postalCode":"80112-3617"},
        {"storeID":"1976","phone":"303-209-2827","address":"7400 S Gartrell Rd","city":"Aurora, CO","name":"Aurora Saddle Rock","postalCode":"80016-4236"},
        {"storeID":"2261","phone":"303-974-6661","address":"3650 River Point Pkwy","city":"Sheridan, CO","name":"Sheridan","postalCode":"80110-3312"},
        {"storeID":"2225","phone":"720-274-3665","address":"1985 Sheridan Blvd","city":"Edgewater, CO","name":"Edgewater","postalCode":"80214-1325"},
        {"storeID":"2183","phone":"303-219-9055","address":"2171 Prairie Center Pkwy","city":"Brighton, CO","name":"Brighton","postalCode":"80601-7000"},
        {"storeID":"2023","phone":"303-209-1851","address":"10001 Commons St","city":"Lone Tree, CO","name":"Lone Tree","postalCode":"80124-5547"},
        {"storeID":"1372","phone":"303-280-0400","address":"1001 E 120th Ave","city":"Thornton, CO","name":"Thornton","postalCode":"80233-5711"},
        {"storeID":"271","phone":"303-797-1911","address":"1950 E County Line Rd","city":"Highlands Ranch, CO","name":"Douglas County","postalCode":"80126-2437"},
        {"storeID":"2717","phone":"303-209-7749","address":"460 S Vance St","city":"Lakewood, CO","name":"Lakewood","postalCode":"80226-3305"},
        {"storeID":"2219","phone":"303-209-0166","address":"11150 S Twenty Mile Rd","city":"Parker, CO","name":"Parker","postalCode":"80134-4952"},
        {"storeID":"3217","phone":"213-275-3149","address":"3131 S Hoover St STE 1910","city":"Los Angeles, CA","name":"Los Angeles USC Village","postalCode":"90089-1910"},
        {"storeID":"3406","phone":"213-460-7085","address":"5731 Bickett St","city":"Huntington Park, CA","name":"LA Huntington Park","postalCode":"90255-2624"},
        {"storeID":"2776","phone":"213-330-4543","address":"735 S Figueroa St","city":"Los Angeles, CA","name":"LA Central","postalCode":"90017-2571"},
        {"storeID":"3216","phone":"213-296-3712","address":"620 S Virgil Ave Ste 100","city":"Los Angeles, CA","name":"Los Angeles Koreatown","postalCode":"90005-4080"},
        {"storeID":"3393","phone":"213-513-3089","address":"330 N Westlake Ave, Unit 500","city":"Los Angeles, CA","name":"LA Bonnie Brae","postalCode":"90026-7734"},
        {"storeID":"3288","phone":"213-758-5147","address":"609 N Dillon St","city":"Los Angeles, CA","name":"LA Silver Lake","postalCode":"90026-3604"},
        {"storeID":"3294","phone":"323-801-0965","address":"1302 S. La Brea Ave, Ste A","city":"Los Angeles, CA","name":"LA Mid City","postalCode":"90019-2909"},
        {"storeID":"1329","phone":"310-677-2436","address":"3471 W Century Blvd","city":"Inglewood, CA","name":"Inglewood","postalCode":"90301-1218"},
        {"storeID":"3343","phone":"424-439-5117","address":"220 N. La Brea Ave","city":"Inglewood, CA","name":"Inglewood Florence and La Brea","postalCode":"90301"},
        {"storeID":"189","phone":"323-725-1121","address":"5600 Whittier Blvd","city":"Commerce, CA","name":"Commerce","postalCode":"90022-4106"},
        {"storeID":"3359","phone":"323-801-2401","address":"415 S LaBrea Ave","city":"Los Angeles, CA","name":"LA LaBrea and 4th St","postalCode":"90036-3523"},
        {"storeID":"190","phone":"562-806-2911","address":"5700 Firestone Blvd","city":"South Gate, CA","name":"South Gate","postalCode":"90280-3704"},
        {"storeID":"1306","phone":"310-895-1131","address":"3535 S La Cienega Blvd","city":"Los Angeles, CA","name":"Los Angeles","postalCode":"90016-4407"},
        {"storeID":"2280","phone":"323-492-1000","address":"2700 W 120th St","city":"Hawthorne, CA","name":"Hawthorne","postalCode":"90250-3388"},
        {"storeID":"2479","phone":"213-588-7000","address":"5500 W Sunset Blvd","city":"Los Angeles, CA","name":"LA Sunset","postalCode":"90028-8522"},
        {"storeID":"3347","phone":"424-361-3817","address":"1833A LaCienega Blvd","city":"Los Angeles, CA","name":"LA 18th and La Cienega","postalCode":"90035-4664"},
        {"storeID":"1884","phone":"323-603-0004","address":"7100 Santa Monica Blvd, STE 201","city":"West Hollywood, CA","name":"West Hollywood","postalCode":"90046-5896"},
        {"storeID":"198","phone":"310-839-5200","address":"10820 Jefferson Blvd","city":"Culver City, CA","name":"Culver City Jefferson","postalCode":"90230-4935"},
        {"storeID":"2632","phone":"310-754-4614","address":"6000 Sepulveda Blvd","city":"Culver City, CA","name":"Culver City Westfield Mall","postalCode":"90230-6423"},
        {"storeID":"3346","phone":"213-588-7106","address":"7021 Hollywood Blvd, #210","city":"Los Angeles, CA","name":"Hollywood Galaxy","postalCode":"90028-6034"},
        {"storeID":"3293","phone":"818-949-3422","address":"965 Town Center Dr.","city":"La Canada Flintridge, CA","name":"La Canada Flintridge","postalCode":"91011-1638"},
        {"storeID":"1408","phone":"323-258-1355","address":"2626 Colorado Blvd","city":"Los Angeles, CA","name":"Los Angeles Eagle Rock","postalCode":"90041-1070"},
        {"storeID":"2307","phone":"818-334-1400","address":"2195 Glendale Galleria","city":"Glendale, CA","name":"Glendale","postalCode":"91210-2101"},
        {"storeID":"883","phone":"626-584-1606","address":"777 E Colorado Blvd","city":"Pasadena, CA","name":"Pasadena","postalCode":"91101-2104"},
        {"storeID":"3262","phone":"323-474-3070","address":"4211 Eagle Rock Blvd","city":"Los Angeles, CA","name":"LA Glassell Park","postalCode":"90065-4404"},
        {"storeID":"1362","phone":"818-238-0132","address":"1800 W Empire Ave","city":"Burbank, CA","name":"Burbank","postalCode":"91504-3403"},
        {"storeID":"1332","phone":"626-584-2931","address":"3121 E Colorado Blvd","city":"Pasadena, CA","name":"Pasadena East","postalCode":"91107-3814"},
        {"storeID":"3251","phone":"747-241-5032","address":"1033 N Hollywood Way","city":"Burbank, CA","name":"LA Burbank West","postalCode":"91505-2540"},
        {"storeID":"184","phone":"626-308-3290","address":"2120 W Main St","city":"Alhambra, CA","name":"Alhambra","postalCode":"91801-1856"},
        {"storeID":"294","phone":"818-761-3083","address":"11051 Victory Blvd","city":"North Hollywood, CA","name":"North Hollywood","postalCode":"91606-3719"},
        {"storeID":"2479","phone":"213-588-7000","address":"5500 W Sunset Blvd","city":"Los Angeles, CA","name":"LA Sunset","postalCode":"90028-8522"},
        {"storeID":"3288","phone":"213-758-5147","address":"609 N Dillon St","city":"Los Angeles, CA","name":"LA Silver Lake","postalCode":"90026-3604"},
        {"storeID":"3393","phone":"213-513-3089","address":"330 N Westlake Ave, Unit 500","city":"Los Angeles, CA","name":"LA Bonnie Brae","postalCode":"90026-7734"},
        {"storeID":"3427","phone":"747-205-5632","address":"12121 Victory Blvd","city":"North Hollywood, CA","name":"NoHo Valley Plaza","postalCode":"91606-3204"},
        {"storeID":"3346","phone":"213-588-7106","address":"7021 Hollywood Blvd, #210","city":"Los Angeles, CA","name":"Hollywood Galaxy","postalCode":"90028-6034"},
        {"storeID":"3216","phone":"213-296-3712","address":"620 S Virgil Ave Ste 100","city":"Los Angeles, CA","name":"Los Angeles Koreatown","postalCode":"90005-4080"},
        {"storeID":"1884","phone":"323-603-0004","address":"7100 Santa Monica Blvd, STE 201","city":"West Hollywood, CA","name":"West Hollywood","postalCode":"90046-5896"},
        {"storeID":"2776","phone":"213-330-4543","address":"735 S Figueroa St","city":"Los Angeles, CA","name":"LA Central","postalCode":"90017-2571"},
        {"storeID":"1411","phone":"626-280-8024","address":"3600 Rosemead Blvd","city":"Rosemead, CA","name":"Rosemead","postalCode":"91770-2066"},
        {"storeID":"183","phone":"818-896-8214","address":"9725 Laurel Canyon Blvd","city":"Pacoima, CA","name":"Pacoima","postalCode":"91331-4100"},
        {"storeID":"1029","phone":"760-633-1406","address":"1010 N El Camino Real","city":"Encinitas, CA","name":"Encinitas","postalCode":"92024-1320"},
        {"storeID":"2165","phone":"760-208-6111","address":"3150 Business Park Dr","city":"Vista, CA","name":"Vista South Business Park Drive","postalCode":"92081-8520"},
        {"storeID":"303","phone":"760-967-8335","address":"2255 S El Camino Real","city":"Oceanside, CA","name":"Oceanside","postalCode":"92054-6318"},
        {"storeID":"1040","phone":"760-941-0220","address":"1751 University Dr","city":"Vista, CA","name":"Vista North University Drive","postalCode":"92083-7775"},
        {"storeID":"2871","phone":"760-666-6778","address":"443 College Blvd","city":"Oceanside, CA","name":"Oceanside East","postalCode":"92057-5435"},
        {"storeID":"274","phone":"760-489-6119","address":"1280 Auto Park Way","city":"Escondido, CA","name":"Escondido","postalCode":"92029-2231"},
        {"storeID":"2855","phone":"858-227-3918","address":"17170 Camino Del Sur","city":"San Diego, CA","name":"San Diego Del Sur","postalCode":"92127-4166"},
        {"storeID":"2802","phone":"760-705-9019","address":"280 E Via Rancho Pkwy","city":"Escondido, CA","name":"Escondido South","postalCode":"92025-8005"},
        {"storeID":"3369","phone":"858-410-0390","address":"9500 Gilman Dr","city":"La Jolla, CA","name":"UC San Diego Price Center","postalCode":"92093-5004"},
        {"storeID":"305","phone":"858-530-1901","address":"8251 Mira Mesa Blvd","city":"San Diego, CA","name":"Mira Mesa","postalCode":"92126-2603"},
        {"storeID":"296","phone":"858-679-8202","address":"14823 Pomerado Rd","city":"Poway, CA","name":"Poway","postalCode":"92064-2803"},
        {"storeID":"2465","phone":"858-309-6564","address":"5680 Balboa Ave","city":"San Diego, CA","name":"Balboa","postalCode":"92111-2702"},
        {"storeID":"205","phone":"858-576-1570","address":"8001 Othello Ave","city":"San Diego, CA","name":"Kearny Mesa","postalCode":"92111-3713"},
        {"storeID":"1410","phone":"619-542-0025","address":"1288 Camino Del Rio N","city":"San Diego, CA","name":"San Diego Mission Valley","postalCode":"92108-1511"},
        {"storeID":"201","phone":"619-223-2491","address":"3245 Sports Arena Blvd","city":"San Diego, CA","name":"Sports Arena","postalCode":"92110-4529"},
        {"storeID":"3303","phone":"619-881-1380","address":"4864 Newport Ave","city":"San Diego, CA","name":"Ocean Beach","postalCode":"92107-3111"},
        {"storeID":"1485","phone":"619-449-9790","address":"9846 Mission Gorge Rd","city":"Santee, CA","name":"Santee","postalCode":"92071-3834"},
        {"storeID":"3302","phone":"619-881-1535","address":"3029 University Ave","city":"San Diego, CA","name":"San Diego North Park","postalCode":"92104-3036"},
        {"storeID":"997","phone":"619-461-1960","address":"5500 Grossmont Ctr Dr","city":"La Mesa, CA","name":"Grossmont","postalCode":"91942-3015"},
        {"storeID":"3205","phone":"619-684-7086","address":"3030 Grape St","city":"San Diego, CA","name":"San Diego South Park","postalCode":"92102-1134"},
        {"storeID":"1027","phone":"805-384-0040","address":"209 W Ventura Blvd","city":"Camarillo, CA","name":"Camarillo","postalCode":"93010-8359"},
        {"storeID":"1100","phone":"805-480-9114","address":"2705 Teller Rd","city":"Thousand Oaks, CA","name":"Thousand Oaks","postalCode":"91320-1190"},
        {"storeID":"2760","phone":"805-288-3642","address":"2850 N OXNARD BLVD","city":"Oxnard, CA","name":"Oxnard West","postalCode":"93036-5443"},
        {"storeID":"1547","phone":"805-530-0028","address":"800 New Los Angeles Ave","city":"Moorpark, CA","name":"Moorpark","postalCode":"93021-3585"},
        {"storeID":"298","phone":"805-644-9871","address":"4200 E Main St","city":"Ventura, CA","name":"Ventura Main Street","postalCode":"93003-5230"},
        {"storeID":"2398","phone":"805-535-2752","address":"245 S Mills Rd","city":"Ventura, CA","name":"Ventura Mall","postalCode":"93003-3435"},
        {"storeID":"2462","phone":"805-416-5790","address":"51 Tierra Rejada Rd","city":"Simi Valley, CA","name":"Simi Valley Tierra Rejada Road","postalCode":"93065-2902"},
        {"storeID":"2810","phone":"818-661-2631","address":"30740 Russell Ranch Rd","city":"Westlake Village, CA","name":"Westlake Village","postalCode":"91362-4063"},
        {"storeID":"246","phone":"805-583-8933","address":"2907 Cochran St","city":"Simi Valley, CA","name":"Simi Valley Cochran Street","postalCode":"93065-2773"},
        {"storeID":"228","phone":"818-999-2390","address":"6635 Fallbrook Ave","city":"West Hills, CA","name":"West Hills","postalCode":"91307-3520"},
        {"storeID":"2143","phone":"818-746-9922","address":"6700 Topanga Canyon Blvd","city":"Canoga Park, CA","name":"Los Angeles Topanga","postalCode":"91303-2624"},
        {"storeID":"288","phone":"818-713-2707","address":"20801 Ventura Blvd","city":"Woodland Hills, CA","name":"Woodland Hills","postalCode":"91364-2319"},
        {"storeID":"299","phone":"818-772-0494","address":"8840 Corbin Ave","city":"Northridge, CA","name":"Northridge","postalCode":"91324-3309"},
        {"storeID":"3345","phone":"818-742-9264","address":"17401 Ventura Blvd, Unit A1","city":"Encino, CA","name":"Encino Ventura Blvd","postalCode":"91316-3860"},
        {"storeID":"2020","phone":"818-924-9001","address":"8999 Balboa Blvd","city":"Northridge, CA","name":"Sherwood Forest","postalCode":"91325-2608"},
        {"storeID":"257","phone":"661-254-8001","address":"24425 Magic Mountain Pkwy","city":"Valencia, CA","name":"Valencia","postalCode":"91355-3403"},
        {"storeID":"2329","phone":"818-360-2999","address":"11133 Balboa Blvd","city":"Granada Hills, CA","name":"Granada Hills","postalCode":"91344-4204"},
        {"storeID":"3218","phone":"818-741-1855","address":"10340 N Sepulveda Blvd","city":"Mission Hills, CA","name":"Mission Hills CA","postalCode":"91345-2422"},
        {"storeID":"1307","phone":"818-779-0163","address":"5711 Sepulveda Blvd","city":"Van Nuys, CA","name":"Sherman Oaks","postalCode":"91411-2918"},
        {"storeID":"1309","phone":"818-922-1001","address":"14920 Raymer St","city":"Van Nuys, CA","name":"Van Nuys Raymer St","postalCode":"91405-1146"},
        {"storeID":"1122","phone":"650-341-0734","address":"2220 Bridgepointe Pkwy","city":"San Mateo, CA","name":"San Mateo Fashion Island","postalCode":"94404-1569"},
        {"storeID":"1054","phone":"650-827-0171","address":"1150 El Camino Real","city":"San Bruno, CA","name":"Tanforan","postalCode":"94066-2420"},
        {"storeID":"320","phone":"650-992-8433","address":"5001 Junipero Serra Blvd","city":"Colma, CA","name":"Colma","postalCode":"94014-3217"},
        {"storeID":"1407","phone":"650-755-2393","address":"133 Serramonte Ctr","city":"Daly City, CA","name":"Daly City Serramonte","postalCode":"94015-2349"},
        {"storeID":"321","phone":"650-363-8940","address":"2485 El Camino Real","city":"Redwood City, CA","name":"Redwood City","postalCode":"94063-2849"},
        {"storeID":"3353","phone":"415-466-0506","address":"100 Westlake Ctr","city":"Daly City, CA","name":"Daly City Westlake","postalCode":"94015-1407"},
        {"storeID":"3264","phone":"415-680-2914","address":"285 Winston Dr","city":"San Francisco, CA","name":"San Francisco Stonestown","postalCode":"94132-1921"},
        {"storeID":"2185","phone":"510-731-0001","address":"19661 Hesperian Blvd","city":"Hayward, CA","name":"Hayward North","postalCode":"94541-4200"},
        {"storeID":"1428","phone":"510-276-2699","address":"15555 E 14th St","city":"San Leandro, CA","name":"San Leandro Bayfair","postalCode":"94578-1978"},
        {"storeID":"2766","phone":"415-343-6272","address":"789 Mission St","city":"San Francisco, CA","name":"San Francisco Central","postalCode":"94103-3132"},
        {"storeID":"2829","phone":"510-214-0931","address":"2700 Fifth Street","city":"Alameda, CA","name":"Alameda","postalCode":"94501-6574"},
        {"storeID":"2768","phone":"415-796-5280","address":"2675 Geary Blvd","city":"San Francisco, CA","name":"San Francisco West","postalCode":"94118-3400"},
        {"storeID":"1472","phone":"510-471-9166","address":"2499 Whipple Rd","city":"Hayward, CA","name":"Hayward","postalCode":"94544-7807"},
        {"storeID":"2767","phone":"510-285-0559","address":"1555 40th Street","city":"Emeryville, CA","name":"Oakland-Emeryville","postalCode":"94608-3515"},
        {"storeID":"322","phone":"650-965-7764","address":"555 Showers Dr","city":"Mountain View, CA","name":"Mountain View","postalCode":"94040-1432"},
        {"storeID":"1422","phone":"510-795-6305","address":"39201 Fremont Blvd","city":"Fremont, CA","name":"Fremont Hub","postalCode":"94538-1301"},
        {"storeID":"3267","phone":"510-542-5346","address":"1414 University Ave","city":"Berkeley, CA","name":"Berkeley Univ Ave","postalCode":"94702-1548"},
        {"storeID":"3202","phone":"510-982-3726","address":"2187 Shattuck Ave","city":"Berkeley, CA","name":"Berkeley Central","postalCode":"94704-1308"},
        {"storeID":"1926","phone":"510-982-0512","address":"1057 Eastshore Hwy","city":"Albany, CA","name":"Albany","postalCode":"94710-1011"},
        {"storeID":"2615","phone":"510-771-1616","address":"43950 Pacific Commons Blvd","city":"Fremont, CA","name":"Fremont South","postalCode":"94538-3803"},
        {"storeID":"1427","phone":"408-871-7984","address":"1600 Saratoga Ave","city":"San Jose, CA","name":"San Jose Westgate","postalCode":"95129-5101"},
        {"storeID":"324","phone":"408-267-7900","address":"1811 Hillsdale Ave","city":"San Jose, CA","name":"San Jose West","postalCode":"95124-3027"},
        {"storeID":"2830","phone":"669-342-3320","address":"2004 El Camino Real","city":"Santa Clara, CA","name":"Santa Clara","postalCode":"95050-4051"},
        {"storeID":"2088","phone":"408-346-2022","address":"533 Coleman Ave","city":"San Jose, CA","name":"San Jose College Park","postalCode":"95110-2047"},
        {"storeID":"323","phone":"408-725-2651","address":"20745 Stevens Creek Blvd","city":"Cupertino, CA","name":"Cupertino De Anza Blvd","postalCode":"95014-2123"},
        {"storeID":"2281","phone":"408-660-1703","address":"2161 Monterey Hwy","city":"San Jose, CA","name":"San Jose Central","postalCode":"95125-1057"},
        {"storeID":"1927","phone":"408-513-3002","address":"879 Blossom Hill Rd","city":"San Jose, CA","name":"San Jose Oakridge","postalCode":"95123-1213"},
        {"storeID":"2584","phone":"408-702-1012","address":"298 W McKinley Ave","city":"Sunnyvale, CA","name":"Sunnyvale","postalCode":"94086-6193"},
        {"storeID":"1984","phone":"408-273-0054","address":"1750 Story Rd","city":"San Jose, CA","name":"San Jose Story Road","postalCode":"95122-1921"},
        {"storeID":"2238","phone":"408-238-7800","address":"3155 Silver Creek Rd","city":"San Jose, CA","name":"San Jose East","postalCode":"95121-1730"},
        {"storeID":"1426","phone":"408-254-8931","address":"450 N Capitol Ave","city":"San Jose, CA","name":"San Jose Capitol","postalCode":"95133-1938"},
        {"storeID":"2581","phone":"408-834-1527","address":"95 Holger Way","city":"San Jose, CA","name":"San Jose North","postalCode":"95134-1377"},
        {"storeID":"2605","phone":"408-600-3721","address":"5630 Cottle Rd","city":"San Jose, CA","name":"San Jose South","postalCode":"95123-3696"},
        {"storeID":"626","phone":"408-946-6791","address":"2155 Morrill Ave","city":"San Jose, CA","name":"San Jose Landess","postalCode":"95132-1130"},
        {"storeID":"322","phone":"650-965-7764","address":"555 Showers Dr","city":"Mountain View, CA","name":"Mountain View","postalCode":"94040-1432"},
        {"storeID":"2615","phone":"510-771-1616","address":"43950 Pacific Commons Blvd","city":"Fremont, CA","name":"Fremont South","postalCode":"94538-3803"},
        {"storeID":"1422","phone":"510-795-6305","address":"39201 Fremont Blvd","city":"Fremont, CA","name":"Fremont Hub","postalCode":"94538-1301"},
        {"storeID":"3410","phone":"831-291-4754","address":"270 Mount Herman Rd","city":"Scotts Valley, CA","name":"Scotts Valley","postalCode":"95066-4024"},
        {"storeID":"321","phone":"650-363-8940","address":"2485 El Camino Real","city":"Redwood City, CA","name":"Redwood City","postalCode":"94063-2849"},
        {"storeID":"2252","phone":"408-310-4050","address":"1061 Cochrane Rd","city":"Morgan Hill, CA","name":"Morgan Hill","postalCode":"95037-9305"},
        {"storeID":"1406","phone":"503-674-9109","address":"21500 NE Halsey St","city":"Fairview, OR","name":"Fairview","postalCode":"97024-8616"},
        {"storeID":"346","phone":"503-659-1057","address":"9000 SE Sunnyside Rd","city":"Clackamas, OR","name":"Clackamas","postalCode":"97015-9758"},
        {"storeID":"1419","phone":"503-252-5850","address":"9800 SE Washington St","city":"Portland, OR","name":"Portland East Washington Street","postalCode":"97216-2420"},
        {"storeID":"1444","phone":"360-449-6420","address":"16200 SE Mill Plain Blvd","city":"Vancouver, WA","name":"Vancouver Mill Plain Blvd","postalCode":"98684-9638"},
        {"storeID":"2523","phone":"971-230-1930","address":"9401 NE Cascades Pkwy","city":"Portland, OR","name":"Portland Cascade Station","postalCode":"97220-6824"},
        {"storeID":"343","phone":"360-253-4324","address":"7809C NE Vancouver Plaza Dr","city":"Vancouver, WA","name":"Vancouver Plaza Dr","postalCode":"98662-6624"},
        {"storeID":"2779","phone":"503-205-9109","address":"1555 N Tomahawk Island Dr","city":"Portland, OR","name":"Portland Hayden Island Dr","postalCode":"97217-8296"},
        {"storeID":"1847","phone":"503-682-7781","address":"25925 SW Heather Pl","city":"Wilsonville, OR","name":"Wilsonville","postalCode":"97070-5785"},
        {"storeID":"345","phone":"503-624-0273","address":"9009 SW Hall Blvd","city":"Tigard, OR","name":"Washington Square","postalCode":"97223-4432"},
        {"storeID":"1883","phone":"360-713-0004","address":"8801 NE Hazel Dell Ave","city":"Vancouver, WA","name":"Vancouver Hazel Dell Ave","postalCode":"98665-8145"},
        {"storeID":"344","phone":"503-626-8345","address":"10775 SW Beaverton Hillsdale Hwy","city":"Beaverton, OR","name":"Beaverton","postalCode":"97005-3001"},
        {"storeID":"3413","phone":"503-403-3619","address":"12675 NW Cornell Rd","city":"Portland, OR","name":"Cedar Mill","postalCode":"97229-5886"},
        {"storeID":"1868","phone":"503-610-6000","address":"21365 SW Baler Way","city":"Sherwood, OR","name":"Sherwood","postalCode":"97140-8989"},
        {"storeID":"910","phone":"503-531-3656","address":"11095 NE Evergreen Pkwy","city":"Hillsboro, OR","name":"Tanasbourne","postalCode":"97006-7510"},
        {"storeID":"362","phone":"503-693-1016","address":"2295 SE Tualatin Valley Hwy","city":"Hillsboro, OR","name":"Hillsboro","postalCode":"97123-7915"},
        {"storeID":"2110","phone":"503-856-0601","address":"6450 Keizer Station Blvd NE","city":"Keizer, OR","name":"Keizer","postalCode":"97303-1695"},
        {"storeID":"608","phone":"503-588-7116","address":"3790 Center St NE","city":"Salem, OR","name":"Salem","postalCode":"97301-2905"},
        {"storeID":"628","phone":"360-425-3926","address":"205 Three Rivers Dr","city":"Kelso, WA","name":"Kelso","postalCode":"98626-3127"},
        {"storeID":"609","phone":"541-926-5727","address":"2255 14th Ave SE","city":"Albany, OR","name":"Albany","postalCode":"97322-8513"},
        {"storeID":"766","phone":"541-330-1434","address":"63435 N Highway 97","city":"Bend, OR","name":"Bend","postalCode":"97703-7802"},
        {"storeID":"1118","phone":"425-482-6410","address":"13950 NE 178th Pl","city":"Woodinville, WA","name":"Woodinville","postalCode":"98072-3523"},
        {"storeID":"338","phone":"425-670-1435","address":"18305 Alderwood Mall Pkwy","city":"Lynnwood, WA","name":"Lynnwood","postalCode":"98037-3961"},
        {"storeID":"1284","phone":"206-494-0897","address":"302 NE Northgate Way","city":"Seattle, WA","name":"Northgate","postalCode":"98125-6047"},
        {"storeID":"995","phone":"425-556-9533","address":"17700 NE 76th St","city":"Redmond, WA","name":"Redmond","postalCode":"98052-3300"},
        {"storeID":"3275","phone":"425-362-1530","address":"272 116th Ave NE","city":"Bellevue, WA","name":"Bellevue 4th St and 116th Ave","postalCode":"98004-5213"},
        {"storeID":"337","phone":"425-353-3167","address":"405 SE Everett Mall Way","city":"Everett, WA","name":"Everett","postalCode":"98208-3243"},
        {"storeID":"2786","phone":"206-494-3250","address":"1401 2nd Ave","city":"Seattle, WA","name":"Seattle Pike Plaza","postalCode":"98101-2007"},
        {"storeID":"339","phone":"425-562-0830","address":"4053 Factoria Square Mall SE","city":"Bellevue, WA","name":"Factoria","postalCode":"98006-1286"},
        {"storeID":"996","phone":"425-392-3357","address":"755 NW Gilman Blvd","city":"Issaquah, WA","name":"Issaquah","postalCode":"98027-5357"},
        {"storeID":"1331","phone":"425-397-8144","address":"9601 Market Pl","city":"Lake Stevens, WA","name":"Lake Stevens","postalCode":"98258-7949"},
        {"storeID":"2290","phone":"425-207-0067","address":"1215 N Landing Way","city":"Renton, WA","name":"Renton","postalCode":"98057-5521"},
        {"storeID":"637","phone":"206-932-1153","address":"2800 SW Barton St","city":"Seattle, WA","name":"West Seattle","postalCode":"98126-3975"},
        {"storeID":"627","phone":"206-575-0682","address":"301 Strander Blvd","city":"Tukwila, WA","name":"South Center","postalCode":"98188-2971"},
        {"storeID":"606","phone":"360-692-3966","address":"3201 NW Randall Way","city":"Silverdale, WA","name":"Silverdale","postalCode":"98383-7952"},
        {"storeID":"681","phone":"253-850-9710","address":"26301 104th Ave SE","city":"Kent, WA","name":"Kent","postalCode":"98030-7649"},
        {"storeID":"2192","phone":"360-386-4020","address":"16818 Twin Lakes Ave","city":"Marysville, WA","name":"Marysville","postalCode":"98271-4724"},
        {"storeID":"1947","phone":"253-733-7520","address":"2201 S Commons","city":"Federal Way, WA","name":"Federal Way","postalCode":"98003-6023"},
        {"storeID":"1205","phone":"253-858-9777","address":"11400 51st Ave NW","city":"Gig Harbor, WA","name":"Gig Harbor","postalCode":"98332-7891"},
        {"storeID":"341","phone":"253-627-2112","address":"3320 S 23rd St","city":"Tacoma, WA","name":"North Tacoma","postalCode":"98405-1603"},
        {"storeID":"1957","phone":"253-862-5660","address":"9400 192nd Ave E","city":"Bonney Lake, WA","name":"Bonney Lake","postalCode":"98391-8564"},
        {"storeID":"2857","phone":"509-822-3274","address":"4915 S Regal St","city":"Spokane, WA","name":"Spokane South","postalCode":"99223-7633"},
        {"storeID":"636","phone":"509-466-3006","address":"9770 N Newport Hwy","city":"Spokane, WA","name":"North Spokane","postalCode":"99218-1249"},
        {"storeID":"915","phone":"509-924-8757","address":"13724 E Sprague Ave","city":"Spokane, WA","name":"Spokane Valley","postalCode":"99216-0812"},
        {"storeID":"679","phone":"208-772-7523","address":"315 W Canfield Ave","city":"Coeur d'Alene, ID","name":"Coeur d'Alene","postalCode":"83815-7750"},
        {"storeID":"3407","phone":"208-997-3293","address":"2132 W Pullman Rd","city":"Moscow, ID","name":"Moscow West Pullman Road","postalCode":"83843-4011"}
      ],
      productData:[
        {"name":"TRU FRU","price":"7.99","productCode":"201211751"},
        {"name":"M&M'S","price":"4.99","productCode":"400060557"},
        {"name":"CHEEZ IT","price":"4.99","productCode":"210012424"},
        {"name":"CHEEZ-IT","price":"4.99","productCode":"210012418"},
        {"name":"RITZ","price":"5.79","productCode":"400007905"},
        {"name":"OREO","price":"5.49","productCode":"400001473"},
        {"name":"RITZ","price":"5.49","productCode":"400008042"},
        {"name":"SKITTLES","price":"6.99","productCode":"200030128"},
        {"name":"CHEEZ-IT","price":"4.99","productCode":"410010685"},
        {"name":"PREMIUM","price":"4.79","productCode":"400000057"},
        {"name":"WELCHS","price":"9.99","productCode":"485684068"},
        {"name":"OREO","price":"6.79","productCode":"400006023"},
        {"name":"RUFFLES","price":"6.49","productCode":"840051773"},
        {"name":"RITZ","price":"5.79","productCode":"400003111"},
        {"name":"LAYS POTATO","price":"5.99","productCode":"840031041"},
        {"name":"TOWN HOUSE","price":"3.99","productCode":"210010055"},
        {"name":"BODYARMOR","price":"7.99","productCode":"817600280"},
        {"name":"GATORADE","price":"11.93","productCode":"200012251"},
        {"name":"CELSIUS","price":"23.99","productCode":"939200093"},
        {"name":"FOLGERS","price":"18.99","productCode":"550030418"},
        {"name":"SIGNATURE","price":"4.50","productCode":"113029485"},
        {"name":"RED BULL","price":"3.12","productCode":"126981899"},
        {"name":"PEPSI SODA","price":"12.43","productCode":"200080994"},
        {"name":"CRAISINS","price":"2.99","productCode":"120000159"},
        {"name":"BLUE DIAMOND","price":"5.49","productCode":"157002970"},
        {"name":"BLUE DIAMOND","price":"5.49","productCode":"157002970"},
        {"name":"O ORGANICS","price":"8.99","productCode":"989315826"},
        {"name":"RITZ","price":"5.79","productCode":"400003111"},
        {"name":"VICENZI","price":"3.99","productCode":"175068386"},
        {"name":"SIGNATURE","price":"3.99","productCode":"113052239"},
        {"name":"SIGNATURE","price":"3.99","productCode":"113052239"},
        {"name":"SIGNATURE","price":"6.99","productCode":"113012896"},
        {"name":"LOTUS COOKIES","price":"3.00","productCode":"178891629"},
        {"name":"LOTUS COOKIES","price":"3.00","productCode":"178891629"},
        {"name":"SUN-MAID","price":"4.49","productCode":"114315160"},
        {"name":"SUN-MAID","price":"4.49","productCode":"114315160"},
        {"name":"NUTELLA ","price":"5.99","productCode":"980083003"},
        {"name":"TOOTSIE","price":"13.99","productCode":"172001752"},
        {"name":"TOOTSIE","price":"13.99","productCode":"172001752"},
        {"name":"SIM MILL","price":"4.99","productCode":"606900519"},
        {"name":"DATES PITTED","price":"11.99","productCode":"102232119"},
        {"name":"PLANTERS","price":"5.99","productCode":"900001665"},
        {"name":"OREO","price":"4.99","productCode":"400006011"},
        {"name":"GUACAMOLE","price":"5.99","productCode":"204159906"},
        {"name":"MARIANI","price":"2.50","productCode":"102234011"},
        {"name":"CHLULA HOT","price":"4.99","productCode":"204159906"},
        {"name":"365WFM HL MLK","price":"3.99","productCode":"202234011"}],
      productIceData:[
        {"name":"FISHER-PRICE","price":"27.99","productCode":"204103330"},
        {"name":"FISHER-PRICE","price":"38.99","productCode":"204103343"},
        {"name":"FISHER-PRICE","price":"45.49","productCode":"204105320"},
        {"name":"FISHER-PRICE","price":"56.99","productCode":"204203636"},
        {"name":"FISHER-PRICE","price":"69.99","productCode":"204103330"},
        {"name":"FISHER-PRICE","price":"77.99","productCode":"204603835"},
        {"name":"FISHER-PRICE","price":"86.99","productCode":"204803396"},
        {"name":"FISHER-PRICE","price":"88.49","productCode":"204903758"},
        {"name":"FISHER-PRICE","price":"89.99","productCode":"204183365"},
        {"name":"FISHER-PRICE","price":"88.99","productCode":"204103370"},
        {"name":"FISHER-PRICE","price":"97.99","productCode":"204603885"},
        {"name":"FISHER-PRICE","price":"98.99","productCode":"2048033596"},
        {"name":"FISHER-PRICE","price":"105.49","productCode":"204903763"},
        {"name":"FISHER-PRICE","price":"106.99","productCode":"204183335"},
        {"name":"FISHER-PRICE","price":"119.99","productCode":"204173339"}]
    }
  },
  components: {
  },
  watch: {
    // 监听 newOrderId 变量的变化
    newOrderId(newValue) {
      if (newValue) {
        // console.log('newOrderId 变化，启动定时任务');
        this.startTimer();  // 启动定时任务
      } else {
        this.stopTimer();   // 如果 newOrderId 为空，停止定时任务
      }
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  mounted() {
    this.gensFuncs();
  },
  methods: {
    pageRowsInput(event) {
      this.pageRows = event.target.value.slice(0, 3); // 限制为10位
    },
    handleInput(event) {
      this.card4No = event.target.value.slice(0, 4); // 限制为10位
    },
    roundToNextHundred(amount) {
      return Math.ceil(amount / 100) * 100;
    },
    printElement() {
      console.log("初始化")
      if (typeof getLodop === 'undefined') {
        console.error('LODOP未加载，请确保已正确引入 LodopFuncs.js');
        return;
      }

      for (let p = 1; p <= this.pageRows; p++) {
        this.gensFuncs();
        let LODOP = getLodop();
        if(LODOP) {
          LODOP.PRINT_INIT('');
          LODOP.SET_PRINTER_INDEX(-1);
          LODOP.SET_PRINT_STYLE("FontName", "Arial");
          LODOP.SET_PRINT_STYLE("FontSize", 8);
          LODOP.ADD_PRINT_IMAGE(20, 10, 250, 60, "<img src='/image/target-logo.png'/>");
          LODOP.SET_PRINT_STYLEA(0, "Stretch", 1);
          LODOP.ADD_PRINT_TEXT(95, 10, 240, 80, this.name + " - " + this.phone);
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);
          LODOP.ADD_PRINT_TEXT(110, 10, 240, 80, this.address);
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);
          LODOP.ADD_PRINT_TEXT(125, 10, 240, 80, this.city + " " + this.zip);
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);
          LODOP.ADD_PRINT_TEXT(140, 10, 240, 80, this.orderTempDate+"   "+this.orderDate);
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);
          LODOP.ADD_PRINT_BARCODE(170,45,248,16, 'EAN128C', this.barcodeValue)
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
          LODOP.ADD_PRINT_TEXT(200,10,240,80,'GROCERY')
          LODOP.SET_PRINT_STYLEA(7, "Bold", 1);
          var iCurLine = 200;
          var j=7;
          if (this.showProduceData.length > 0)
            for (var i = 0; i < this.showProduceData.length; i++) {
              iCurLine = iCurLine + 15;
              j=j+4;
              LODOP.ADD_PRINT_TEXT(iCurLine, 10, 240, 80, this.showProduceData[i].productCode);
              LODOP.ADD_PRINT_TEXT(iCurLine, 80, 240, 80,this.showProduceData[i].name);
              LODOP.ADD_PRINT_TEXT(iCurLine, 190, 240, 80, "BF");
              LODOP.ADD_PRINT_TEXT(iCurLine, 220, 240, 80, "$" + this.showProduceData[i].price);
            }
          LODOP.ADD_PRINT_TEXT(iCurLine+ 15,10,240,80, 'TOYS - SPORTING   GOODS')
          LODOP.SET_PRINT_STYLEA(j+1, "Bold", 1);
          if (this.showIceProduceData.length > 0)
            for (var i = 0; i < this.showIceProduceData.length; i++) {
              LODOP.ADD_PRINT_TEXT(iCurLine + 30, 10, 240, 80, this.showIceProduceData[i].productCode);
              LODOP.ADD_PRINT_TEXT(iCurLine + 30, 80, 240, 80,this.showIceProduceData[i].name);
              LODOP.ADD_PRINT_TEXT(iCurLine + 30, 190, 240, 80, "TF");
              LODOP.ADD_PRINT_TEXT(iCurLine + 30, 220, 240, 80, "$" + this.showIceProduceData[i].price);
            }

          LODOP.ADD_PRINT_TEXT(iCurLine + 55, 150, 300, 80, "SUBTOTAL");
          LODOP.ADD_PRINT_TEXT(iCurLine + 55, 220, 300, 80, "$" + this.subTotalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine + 70, 30, 300, 80, "B  =   TN  TAX   6.5000   on  "+"$" +this.tempPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine + 70, 220, 300, 80, "$" +this.taxPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine + 85, 30, 300, 80, "T  =   TN  TAX   7.5000   on  "+"$" +this.tempIcePrice);
          LODOP.ADD_PRINT_TEXT(iCurLine + 85, 220, 300, 80, "$" +this.taxIcePrice);
          LODOP.ADD_PRINT_TEXT(iCurLine + 100, 170, 300, 80, "TOTAL");
          LODOP.ADD_PRINT_TEXT(iCurLine + 100, 220, 300, 80, "$" + this.totalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine + 115, 80, 300, 80, "* "+this.card4No+"  VISA  CHARGE");
          LODOP.ADD_PRINT_TEXT(iCurLine + 115, 220, 300, 80, "$" + this.totalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine + 130, 145, 250, 80, "AID:  A00000000"+this.aId5No);
          LODOP.ADD_PRINT_TEXT(iCurLine + 145, 135, 250, 80, "AUTH  CODE:     "+this.code6No);


          LODOP.ADD_PRINT_TEXT(iCurLine + 170, 10, 250, 80, "Your Target Circle earnings are in!");
          LODOP.SET_PRINT_STYLEA(0,"LetterSpacing",1);
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);
          LODOP.ADD_PRINT_TEXT(iCurLine + 185, 10, 250, 80, "Open the Target App or visit");
          LODOP.SET_PRINT_STYLEA(0,"LetterSpacing",1);
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);
          LODOP.ADD_PRINT_TEXT(iCurLine + 200, 10, 250, 80, "Target.com/Circle to see you benefits.");
          LODOP.SET_PRINT_STYLEA(0,"LetterSpacing",1);
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);

          LODOP.ADD_PRINT_TEXT(iCurLine + 230, 10, 250, 80, "-----------------------------------------");
          LODOP.SET_PRINT_STYLEA(0,"LetterSpacing",1);
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);
          LODOP.ADD_PRINT_TEXT(iCurLine + 245, 10, 250, 80, "TOTAL SAVINGS THIS TRIP");
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);
          LODOP.ADD_PRINT_TEXT(iCurLine + 260, 10, 250, 80, "-----------------------------------------");
          LODOP.SET_PRINT_STYLEA(0,"LetterSpacing",1);
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);
          LODOP.ADD_PRINT_TEXT(iCurLine + 285, 10, 250, 80, "RECEIPT ID:  "+this.receipt)
          LODOP.SET_PRINT_STYLEA(0, "alignment", 2);
          LODOP.PRINT();
          //LODOP.PREVIEW();

          setTimeout(function () {
          }, 1000);
        } else {
          console.log("LODOP 无法获取")
        }
      }
    },

    gensFuncs() {
      this.card4No = Math.floor(Math.random() * (9999 - 1001 + 1) + 1001);
      this.showProduceData = [];
      this.showIceProduceData= [];
      var index = Math.floor(Math.random() * this.storeData.length);
      this.address =  this.storeData[index].address;
      this.tempAddress =  this.address;
      this.phone =  this.storeData[index].phone;
      this.city =  this.storeData[index].city;
      this.zip =  this.storeData[index].postalCode;
      this.storeId =  this.storeData[index].storeID;
      this.name = this.storeData[index].name;

      this.iptRows = Math.floor(Math.random() * (3 - 2 + 1)) + 2;
      this.iceRows = 1;
      this.iptRowsFull = this.iptRows < 10 ? '0' + this.iptRows : this.iptRows.toString();
      this.code6No = 0+""+Math.floor(Math.random() * (45001 - 30001 + 1) + 30001);

      var difference = 0;
      difference =  this.iptRows - this.iceRows;
      var pLength =  this.productData.length
      var pIceLeng = this.productIceData.length
      console.log("difference:"+difference)
      var price = 0.00;
      for(let i = 1; i <= difference; i++) {
        var pIndex = Math.floor(Math.random() * pLength);
        this.showProduceData.push(this.productData[pIndex])
        price = price + parseFloat(this.productData[pIndex].price);
      }
      var icePrice = 0.00;
      var pIcaIndex = Math.floor(Math.random() * pIceLeng);
      this.showIceProduceData.push(this.productIceData[pIcaIndex])
      icePrice = parseFloat(this.productIceData[pIcaIndex].price);

      this.tempPrice = parseFloat(price).toFixed(2);
      var tempTaxPrice= this.tempPrice * this.tax10;
      this.taxPrice = parseFloat(tempTaxPrice).toFixed(2);
      this.tempIcePrice = parseFloat(icePrice).toFixed(2);
      var tempTaxIcePrice = this.tempIcePrice*this.tax20;
      this.taxIcePrice = parseFloat(tempTaxIcePrice ).toFixed(2);

      this.orderTempDate = this.getRandomTempYear();
      this.orderDate = this.getRandomDateTime();
      this.aId5No = Math.floor(Math.random() * (45001 - 30001 + 1) + 30001);
      //条形码
      let barcodeFrist = Math.floor(Math.random() * (4000 - 1001 + 1) + 1001);
      let barcodeSecond = Math.floor(Math.random() * (4000 - 1001 + 1) + 1001);
      let barcodeThired = Math.floor(Math.random() * (4000 - 1001 + 1) + 1001);

      this.barcodeValue =  barcodeFrist+''+barcodeSecond+""+barcodeThired+""+this.storeId;
      this.receipt = this.iptRows+"-"+ barcodeFrist+'-'+barcodeSecond+"-"+barcodeThired+"-"+this.storeId+"-"+Math.floor(Math.random() * (9 - 2 + 1) + 2);

      //行数随机
      this.genRows= parseInt(this.iptRows);
      this.subTotalPrice =  price + icePrice
      this.subTotalPrice = parseFloat(this.subTotalPrice).toFixed(2);
      var tempTotalTaxPrice  = price + icePrice + tempTaxPrice + tempTaxIcePrice;
      this.totalPrice = parseFloat(tempTotalTaxPrice).toFixed(2);
      this.showIptRows = this.iptRows < 10 ? this.iptRows.toString()+'\xa0'+'\xa0' : this.iptRows.toString();
      this.generateBarcode();
    },
    generateRandomString(num) {
      let digitsCount = 0;
      let result = '';

      while (result.length < num) {
        const randomChar = Math.random() >= 0.5 ? this.getRandomChar() : this.getRandomDigit();
        result += randomChar;
        if (randomChar >= '0' && randomChar <= '9') {
          digitsCount++;
        }
      }
      // 确保至少有4位数字
      while (digitsCount < 2) {
        const randomIndex = Math.floor(Math.random() * result.length);
        const charAtIndex = result.charAt(randomIndex);
        if (charAtIndex >= '0' && charAtIndex <= '9') {
          result = result.slice(0, randomIndex) + this.getRandomDigit() + result.slice(randomIndex);
          digitsCount++;
        }
      }
      return result;
    },
    getRandomChar() {
      return String.fromCharCode(65 + Math.floor(Math.random() * 26)); // A-Z
    },
    getRandomDigit() {
      return String.fromCharCode(48 + Math.floor(Math.random() * 10)); // 0-9
    },

    getRandomDateTime() {
      const startYear = 2017;
      const endYear = 2019;
      const startTime = new Date(startYear, 4, 12); // 2018年的6月30日
      const diff = new Date(endYear, 3, 11) - startTime; // 2024年的7月14日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      var year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (12 - 2 + 1)) + 2;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }

      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      var  tempHours =  Math.floor(Math.random() * (19 - 9 + 1)) + 9;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');
      const seconds = String(randomDate.getSeconds()).padStart(2, '0');
      var tempYear = year.toString();

      var str;
      if(tempHours>12) {
        tempHours -= 12;
        str = " PM";
      }else{
        str = " AM";
      }
      tempHours = tempHours < 10 ? "0" + tempHours:tempHours;
      return `${tempHours}:${minutes} ${str}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },

    getRandomTempYear() {
      const startYear = 2017;
      const endYear = 2019;
      const startTime = new Date(startYear, 4, 12); // 2018年的6月30日
      const diff = new Date(endYear, 3, 11) - startTime; // 2024年的7月14日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      var year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }

      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      var  tempHours =  Math.floor(Math.random() * (19 - 12 + 1)) + 12;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');
      const seconds = String(randomDate.getSeconds()).padStart(2, '0');
      var tempYear = year.toString();
      return `${month}/${day}/${tempYear}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },

    generateBarcode() {
      // 使用 JsBarcode 库生成条形码
      JsBarcode(this.$refs.barcode, this.barcodeValue, {
        format: "CODE128", // 条形码格式，可选 CODE128、EAN 等
        lineColor: "#000",
        width: 1.4,
        height: 18,
        displayValue: false // 是否显示条形码值
      });
    },
    // 判断设备是否为移动端
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 使用正则检查 userAgent 中是否包含手机/平板的关键字
      return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile|webos.*mobile/i.test(userAgent);
    },
    isMobileBrowerSize() {
      return window.innerWidth <= 768;  // 视窗宽度小于等于 768px 视为移动端
    },

  },
  beforeDestroy() {

  },
  created() {

  }
}
</script>

<style scoped>
@media print {
  body {
    font-family: "Times New Roman", Times, serif;
  }
}
</style>