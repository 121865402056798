npm <template>

  <div style="height: 100vh">
    <div style="padding-left: 15px;font-size: 18px">
      <p>张数 ：<input v-model="pageRows"  style="width: 80px" @input="pageRowsInput" type="number"> 卡号 ：<input v-model="card4No" style="width: 200px"  @input="handleInput" type="number" >
        <button @click ="printElement()" style="margin-left:10px;width: 180px;background-color: #096c0b; height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">Lodop打印</button></p>
    </div>
    <div  style="background-color: #4AB7BD;height: auto;padding-top: 20px;">
      <div id="printContent" style="-webkit-font-smoothing:antialiased;font-family: 'Times New Roman', Times, serif;font-weight: lighter">
        <div   style="padding-bottom:70px;line-height:7px;text-align: center;width: 300px; font-size: 12px; background-color: #FFFFFF; margin: auto">
          <p style="padding-top: 10px;"><img src='/image/walgreens_logo.png' width="250px"/></p>
          <div style="text-align: left; margin-top: -40px">
            <!--          <p style="padding-left: 20px;" v-html="'STORE' + '&nbsp;'.repeat(2*2) + '#'+storeNo + '&nbsp;'.repeat(2*2) + ' '+address"></p>-->
            <p class="container" style="padding-left: 10px; text-align: center;" ><span style="display: inline-block; text-align: right; " v-html="'#'+storeNo+ '&nbsp;'.repeat(5*1)+address"></span></p>
            <p style="padding-left: 10px;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="city +'&nbsp;'.repeat(2*2) +zip"></span></p>
            <p style="padding-left: 10px;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="phone"></span></p>
            <p style="padding-top: 1px;"></p>
            <p style="padding-left: 20px;" v-html="codeFrist+ '&nbsp;'.repeat(10) +codeSecend+ '&nbsp;'.repeat(10)+codeThird+ '&nbsp;'.repeat(10)  +orderDate"></p>
            <p style="padding-left: 20px;" v-html="'ORDER ID:'+ '&nbsp;'.repeat(3*1) +seqNo"></p>
            <p style="padding-left: 20px;" v-html="'Date Placed:'+ '&nbsp;'.repeat(2*1) +orderTempDate"></p>
            <p style="padding-left: 20px;" v-html="'Date Paid:'+ '&nbsp;'.repeat(5*1) +orderTempDate"></p>
            <p style="padding-top: 1px;"></p>
            <p style="padding-left: 20px;" v-html="'*******************************************'"></p>
            <div style="font-size:11px;" v-if="showProduceData" v-for="(item, index) in showProduceData" :key="index">
              <p style="padding-left: 20px;" v-html="item.name"></p>
              <p style="padding-left: 50px;" v-html="item.productCode + '&nbsp;'.repeat(30*1) + item.price+' B'"></p>
            </div>
            <p style="padding-left: 20px;" v-html="'*******************************************'"></p>
            <p style="padding-top: 1px;"></p>
            <p style="padding-left: 50px; " v-html="'SUBTOTAL' + '&nbsp;'.repeat(15*2) +subTotalPrice"></p>
            <p style="padding-left: 50px;" v-html="'SALES TAX'+ '&nbsp;'.repeat(15*2) +taxPrice"></p>
            <p style="padding-left: 50px;"><span  v-html="'TOTAL' + '&nbsp;'.repeat(38*1)"></span><span v-html="+totalPrice"></span></p>
            <p style="padding-left: 50px;"><span  v-html="'VISA ACCT' + '&nbsp;'.repeat(4*1)+card4No+ '&nbsp;'.repeat(18*1)+totalPrice"/></p>
            <p style="padding-left: 50px;" v-html="'CHANGE'+ '&nbsp;'.repeat(34*1)+'0.00'"></p>
            <p style="padding-top: 1px;"></p>
            <p style="padding-left: 20px;" v-html="'THANK YOU FOR SHOPPING AT WALGREENS'"></p>
            <p style="padding-top: 1px;"></p>
            <p style="text-align: center; " v-html="'RFN#:  '+barcodeValue.replace(/(.)(?=.)/g, '$1 ')"></p>
            <p style="text-align: center;margin-top: -10px"  > <svg ref="barcode"></svg></p>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>
<script>
import {initApkHub, newestApkUrl, queryApkHub} from "@/api/index";
import QRCode from 'qrcode'
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';
import { Circle } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';
import { Image as VanImage } from 'vant';
import { Card } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { Notify } from 'vant';
import JsBarcode from 'jsbarcode'
Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
  data () {
    return {
      storeNo:'',
      phone:'',
      city:'',
      address:'',
      tempAddress:'',
      seq4No:'',
      issuer:'',
      iptRowsFull:'',
      pageRows:1,
      iptRows:3,
      showIptRows:'',
      itemPrice:5.50,
      tax10:0.02,
      taxPrice:0.0,
      totalPrice:0.0,
      subTotalPrice:0.0,
      genRows:3,
      card4No:9311,
      seqNo:304740,
      barcodeValue:'9900300230130764390',
      orderDate:'',
      orderTempDate:'',
      aId5No:925650,
      code7No:1911123,
      codeFrist:'258',
      codeSecend:'4658',
      codeThird:'0022',
      showProduceData:[{"name":"BREYERS ICE CREAM VANILLA 48 Oz","price":"5.50","productCode":"0077567254238"}],
      iceRows:0,
      zip:92565,
      storeData:[{
        "storeNo": "12913",
        "address": "1500 E GAGE AVE",
        "city": "LOS ANGELES, CA",
        "phone": "323-581-0964 ",
        "zip": "90001"
      }, {
        "storeNo": "11785",
        "address": "6100 PACIFIC BLVD",
        "city": "HUNTINGTON PARK, CA",
        "phone": "323-826-9775 ",
        "zip": "90255"
      }, {
        "storeNo": "10262",
        "address": "9830 LONG BEACH BLVD",
        "city": "SOUTH GATE, CA",
        "phone": "323-357-3925 ",
        "zip": "90280"
      }, {
        "storeNo": "7482",
        "address": "1800 W SLAUSON AVE",
        "city": "LOS ANGELES, CA",
        "phone": "323-292-1941 ",
        "zip": "90047"
      }, {
        "storeNo": "12460",
        "address": "617 W 7TH ST",
        "city": "LOS ANGELES, CA",
        "phone": "213-694-2880 ",
        "zip": "90017"
      }, {
        "storeNo": "6249",
        "address": "4351 E IMPERIAL HWY",
        "city": "LYNWOOD, CA",
        "phone": "310-609-2406 ",
        "zip": "90262"
      }, {
        "storeNo": "12529",
        "address": "305 N BREED ST",
        "city": "LOS ANGELES, CA",
        "phone": "323-264-0347 ",
        "zip": "90033"
      }, {
        "storeNo": "6974",
        "address": "3331 W CENTURY BLVD",
        "city": "INGLEWOOD, CA",
        "phone": "310-671-1523 ",
        "zip": "90303"
      }, {
        "storeNo": "7016",
        "address": "3724 CRENSHAW BLVD",
        "city": "LOS ANGELES, CA",
        "phone": "323-292-7261 ",
        "zip": "90016"
      }, {
        "storeNo": "6413",
        "address": "230 N LA BREA AVE",
        "city": "INGLEWOOD, CA",
        "phone": "310-671-2471 ",
        "zip": "90301"
      }, {
        "storeNo": "5879",
        "address": "3201 W 6TH ST",
        "city": "LOS ANGELES, CA",
        "phone": "213-251-0179 ",
        "zip": "90020"
      }, {
        "storeNo": "9136",
        "address": "1625 W SUNSET BLVD",
        "city": "LOS ANGELES, CA",
        "phone": "213-482-9286 ",
        "zip": "90026"
      }, {
        "storeNo": "9842",
        "address": "8030 IMPERIAL HWY",
        "city": "DOWNEY, CA",
        "phone": "562-861-6186 ",
        "zip": "90242"
      }, {
        "storeNo": "11440",
        "address": "2331 S ATLANTIC BLVD",
        "city": "MONTEREY PARK, CA",
        "phone": "323-526-9102 ",
        "zip": "91754"
      }, {
        "storeNo": "7175",
        "address": "1344 W REDONDO BEACH BLVD",
        "city": "GARDENA, CA",
        "phone": "310-353-5726 ",
        "zip": "90247"
      }, {
        "storeNo": "10069",
        "address": "11983 HAWTHORNE BLVD",
        "city": "HAWTHORNE, CA",
        "phone": "310-349-0130 ",
        "zip": "90250"
      }, {
        "storeNo": "9137",
        "address": "14250 PRAIRIE AVE",
        "city": "HAWTHORNE, CA",
        "phone": "310-978-9167 ",
        "zip": "90250"
      }, {
        "storeNo": "2155",
        "address": "1501 W WHITTIER BLVD",
        "city": "MONTEBELLO, CA",
        "phone": "323-727-9117 ",
        "zip": "90640"
      }, {
        "storeNo": "10112",
        "address": "9018 FIRESTONE BLVD",
        "city": "DOWNEY, CA",
        "phone": "562-861-5089 ",
        "zip": "90241"
      }, {
        "storeNo": "6516",
        "address": "5467 WILSHIRE BLVD",
        "city": "LOS ANGELES, CA",
        "phone": "323-525-0646 ",
        "zip": "90036"
      }, {
        "storeNo": "6680",
        "address": "9031 ROSECRANS AVE",
        "city": "BELLFLOWER, CA",
        "phone": "562-531-1557 ",
        "zip": "90706"
      }, {
        "storeNo": "7263",
        "address": "8900 WASHINGTON BLVD",
        "city": "PICO RIVERA, CA",
        "phone": "562-222-1590 ",
        "zip": "90660"
      }, {
        "storeNo": "7036",
        "address": "5451 W SUNSET BLVD",
        "city": "LOS ANGELES, CA",
        "phone": "323-860-7970 ",
        "zip": "90027"
      }, {
        "storeNo": "12057",
        "address": "1050 N HIGHLAND AVE",
        "city": "LOS ANGELES, CA",
        "phone": "323-463-1692 ",
        "zip": "90038"
      }, {
        "storeNo": "6445",
        "address": "8770 W PICO BLVD",
        "city": "LOS ANGELES, CA",
        "phone": "310-275-2117 ",
        "zip": "90035"
      }, {
        "storeNo": "11243",
        "address": "11930 STUDEBAKER RD",
        "city": "NORWALK, CA",
        "phone": "562-864-8138 ",
        "zip": "90650"
      }, {
        "storeNo": "9882",
        "address": "331 N PACIFIC COAST HWY",
        "city": "EL SEGUNDO, CA",
        "phone": "310-640-9651 ",
        "zip": "90245"
      }, {
        "storeNo": "2156",
        "address": "2551 W MAIN ST",
        "city": "ALHAMBRA, CA",
        "phone": "626-281-1637 ",
        "zip": "91801"
      }, {
        "storeNo": "6496",
        "address": "2321 HAWTHORNE BLVD",
        "city": "REDONDO BEACH, CA",
        "phone": "310-214-4891 ",
        "zip": "90278"
      }, {
        "storeNo": "7403",
        "address": "15740 WOODRUFF AVE",
        "city": "BELLFLOWER, CA",
        "phone": "562-867-5441 ",
        "zip": "90706"
      }, {
        "storeNo": "16540",
        "address": "6801 HOLLYWOOD BLVD",
        "city": "LOS ANGELES, CA",
        "phone": "323-203-3488 ",
        "zip": "90028"
      }, {
        "storeNo": "6903",
        "address": "5829 LAKEWOOD BLVD",
        "city": "LAKEWOOD, CA",
        "phone": "562-817-5690 ",
        "zip": "90712"
      }, {
        "storeNo": "9685",
        "address": "2400 N SEPULVEDA BLVD",
        "city": "MANHATTAN BEACH, CA",
        "phone": "424-241-1950 ",
        "zip": "90266"
      }, {
        "storeNo": "6854",
        "address": "2222 COLORADO BLVD",
        "city": "LOS ANGELES, CA",
        "phone": "323-254-4593 ",
        "zip": "90041"
      }, {
        "storeNo": "7211",
        "address": "11604 WHITTIER BLVD",
        "city": "WHITTIER, CA",
        "phone": "562-695-7416 ",
        "zip": "90601"
      }, {
        "storeNo": "6931",
        "address": "10407 SANTA MONICA BLVD",
        "city": "LOS ANGELES, CA",
        "phone": "310-481-7123 ",
        "zip": "90025"
      }, {
        "storeNo": "7070",
        "address": "4009 LINCOLN BLVD",
        "city": "MARINA DEL REY, CA",
        "phone": "310-823-7152 ",
        "zip": "90292"
      }, {
        "storeNo": "7244",
        "address": "3570 ATLANTIC AVE",
        "city": "LONG BEACH, CA",
        "phone": "562-424-3963 ",
        "zip": "90807"
      }, {
        "storeNo": "7481",
        "address": "22930 S WESTERN AVE",
        "city": "TORRANCE, CA",
        "phone": "310-517-1851 ",
        "zip": "90501"
      }, {
        "storeNo": "5649",
        "address": "8201 GREENLEAF AVE",
        "city": "WHITTIER, CA",
        "phone": "562-698-4906 ",
        "zip": "90602"
      }, {
        "storeNo": "12419",
        "address": "11795 W OLYMPIC BLVD",
        "city": "LOS ANGELES, CA",
        "phone": "310-312-6506 ",
        "zip": "90064"
      }, {
        "storeNo": "6251",
        "address": "2976 SEPULVEDA BLVD",
        "city": "TORRANCE, CA",
        "phone": "310-534-0078 ",
        "zip": "90505"
      }, {
        "storeNo": "5773",
        "address": "11800 ARTESIA BLVD",
        "city": "ARTESIA, CA",
        "phone": "562-924-7718 ",
        "zip": "90701"
      }, {
        "storeNo": "15469",
        "address": "105 E GLENOAKS BLVD",
        "city": "GLENDALE, CA",
        "phone": "818-745-1173 ",
        "zip": "91207"
      }, {
        "storeNo": "5650",
        "address": "2627 PACIFIC AVE",
        "city": "LONG BEACH, CA",
        "phone": "562-490-9575 ",
        "zip": "90806"
      }, {
        "storeNo": "5616",
        "address": "5913 CARSON ST",
        "city": "LAKEWOOD, CA",
        "phone": "562-429-9120 ",
        "zip": "90713"
      }, {
        "storeNo": "11442",
        "address": "310 S LAKE AVE",
        "city": "PASADENA, CA",
        "phone": "626-583-8066 ",
        "zip": "91101"
      }, {
        "storeNo": "6904",
        "address": "535 S PACIFIC COAST HWY",
        "city": "REDONDO BEACH, CA",
        "phone": "310-540-2228 ",
        "zip": "90277"
      }, {
        "storeNo": "11918",
        "address": "11000 VENTURA BLVD",
        "city": "STUDIO CITY, CA",
        "phone": "818-761-6563 ",
        "zip": "91604"
      }, {
        "storeNo": "5591",
        "address": "24930 WESTERN AVE",
        "city": "HARBOR CITY, CA",
        "phone": "310-891-1264 ",
        "zip": "90710"
      }, {
        "storeNo": "6125",
        "address": "6325 ROSEMEAD BLVD",
        "city": "SAN GABRIEL, CA",
        "phone": "626-285-5107 ",
        "zip": "91775"
      }, {
        "storeNo": "12720",
        "address": "1911 LINCOLN BLVD",
        "city": "SANTA MONICA, CA",
        "phone": "310-526-4496 ",
        "zip": "90405"
      }, {
        "storeNo": "6250",
        "address": "1028 S SAN FERNANDO BLVD",
        "city": "BURBANK, CA",
        "phone": "818-729-9283 ",
        "zip": "91502"
      }, {
        "storeNo": "2622",
        "address": "4142 PACIFIC COAST HWY",
        "city": "TORRANCE, CA",
        "phone": "310-375-9019 ",
        "zip": "90505"
      }, {
        "storeNo": "9139",
        "address": "670 N LAKE AVE",
        "city": "PASADENA, CA",
        "phone": "626-585-8926 ",
        "zip": "91101"
      }, {
        "storeNo": "5526",
        "address": "1932 WILSHIRE BLVD",
        "city": "SANTA MONICA, CA",
        "phone": "310-829-9264 ",
        "zip": "90403"
      }, {
        "storeNo": "10764",
        "address": "2376 E COLORADO BLVD",
        "city": "PASADENA, CA",
        "phone": "626-768-4040 ",
        "zip": "91107"
      }, {
        "storeNo": "13812",
        "address": "14885 TELEGRAPH RD",
        "city": "LA MIRADA, CA",
        "phone": "562-777-3405 ",
        "zip": "90638"
      }, {
        "storeNo": "7575",
        "address": "3643 PECK RD",
        "city": "EL MONTE, CA",
        "phone": "626-442-7868 ",
        "zip": "91731"
      }, {
        "storeNo": "7832",
        "address": "3339 E ANAHEIM ST",
        "city": "LONG BEACH, CA",
        "phone": "562-597-5338 ",
        "zip": "90804"
      }, {
        "storeNo": "6700",
        "address": "5224 COLDWATER CANYON AVE",
        "city": "VAN NUYS, CA",
        "phone": "818-487-2715 ",
        "zip": "91401"
      }, {
        "storeNo": "5672",
        "address": "2000 W WHITTIER BLVD",
        "city": "LA HABRA, CA",
        "phone": "562-690-5567 ",
        "zip": "90631"
      }, {
        "storeNo": "7629",
        "address": "5005 BALL RD",
        "city": "CYPRESS, CA",
        "phone": "714-886-1079 ",
        "zip": "90630"
      }, {
        "storeNo": "1606",
        "address": "3001 FOOTHILL BLVD",
        "city": "LA CRESCENTA, CA",
        "phone": "818-541-7840 ",
        "zip": "91214"
      }, {
        "storeNo": "7826",
        "address": "13231 VICTORY BLVD",
        "city": "VAN NUYS, CA",
        "phone": "818-623-9358 ",
        "zip": "91401"
      }, {
        "storeNo": "11473",
        "address": "490 W HUNTINGTON DR",
        "city": "MONROVIA, CA",
        "phone": "626-408-6590 ",
        "zip": "91016"
      }, {
        "storeNo": "7679",
        "address": "7878 CRESCENT AVE",
        "city": "BUENA PARK, CA",
        "phone": "714-226-0238 ",
        "zip": "90620"
      }, {
        "storeNo": "5702",
        "address": "934 N HACIENDA BLVD",
        "city": "LA PUENTE, CA",
        "phone": "626-934-1926 ",
        "zip": "91744"
      }, {
        "storeNo": "7654",
        "address": "14102 RAMONA BLVD",
        "city": "BALDWIN PARK, CA",
        "phone": "626-337-1082 ",
        "zip": "91706"
      }, {
        "storeNo": "6954",
        "address": "3446 W BALL RD",
        "city": "ANAHEIM, CA",
        "phone": "714-821-3112 ",
        "zip": "92804"
      }, {
        "storeNo": "11735",
        "address": "16100 VENTURA BLVD",
        "city": "ENCINO, CA",
        "phone": "818-788-6951 ",
        "zip": "91436"
      }, {
        "storeNo": "6187",
        "address": "11950 VALLEY VIEW ST",
        "city": "GARDEN GROVE, CA",
        "phone": "714-893-4196 ",
        "zip": "92845"
      }, {
        "storeNo": "6215",
        "address": "1826 W ORANGETHORPE AVE",
        "city": "FULLERTON, CA",
        "phone": "714-526-9257 ",
        "zip": "92833"
      }, {
        "storeNo": "5525",
        "address": "7155 VAN NUYS BLVD",
        "city": "VAN NUYS, CA",
        "phone": "818-989-4281 ",
        "zip": "91405"
      }, {
        "storeNo": "9560",
        "address": "2453 S AZUSA AVE",
        "city": "WEST COVINA, CA",
        "phone": "626-964-3101 ",
        "zip": "91792"
      }, {
        "storeNo": "9845",
        "address": "18308 COLIMA RD",
        "city": "ROWLAND HEIGHTS, CA",
        "phone": "626-913-1033 ",
        "zip": "91748"
      }, {
        "storeNo": "6446",
        "address": "11900 BEACH BLVD",
        "city": "STANTON, CA",
        "phone": "714-890-9063 ",
        "zip": "90680"
      }, {
        "storeNo": "6157",
        "address": "946 S BROOKHURST ST",
        "city": "ANAHEIM, CA",
        "phone": "714-520-5575 ",
        "zip": "92804"
      }, {
        "storeNo": "5798",
        "address": "401 N AZUSA AVE",
        "city": "COVINA, CA",
        "phone": "626-332-0519 ",
        "zip": "91722"
      }, {
        "storeNo": "1750",
        "address": "1250 E CHAPMAN AVE",
        "city": "FULLERTON, CA",
        "phone": "714-680-9124 ",
        "zip": "92831"
      }, {
        "storeNo": "1813",
        "address": "18568 VENTURA BLVD",
        "city": "TARZANA, CA",
        "phone": "818-776-1363 ",
        "zip": "91356"
      }, {
        "storeNo": "2162",
        "address": "8052 WESTMINSTER BLVD",
        "city": "WESTMINSTER, CA",
        "phone": "714-896-9589 ",
        "zip": "92683"
      }, {
        "storeNo": "7950",
        "address": "15316 NORDHOFF ST",
        "city": "NORTH HILLS, CA",
        "phone": "818-892-4249 ",
        "zip": "91343"
      }, {
        "storeNo": "6124",
        "address": "9750 WOODMAN AVE",
        "city": "ARLETA, CA",
        "phone": "818-899-9950 ",
        "zip": "91331"
      }, {
        "storeNo": "12140",
        "address": "4935 WARNER AVE",
        "city": "HUNTINGTON BEACH, CA",
        "phone": "714-377-3756 ",
        "zip": "92649"
      }, {
        "storeNo": "5972",
        "address": "12001 EUCLID ST",
        "city": "GARDEN GROVE, CA",
        "phone": "714-530-1071 ",
        "zip": "92840"
      }, {
        "storeNo": "6972",
        "address": "150 S GRAND AVE",
        "city": "COVINA, CA",
        "phone": "626-966-8497 ",
        "zip": "91724"
      }, {
        "storeNo": "9977",
        "address": "1802 S HARBOR BLVD",
        "city": "ANAHEIM, CA",
        "phone": "714-808-0126 ",
        "zip": "92802"
      }, {
        "storeNo": "5744",
        "address": "128 S STATE COLLEGE BLVD",
        "city": "ANAHEIM, CA",
        "phone": "714-778-2519 ",
        "zip": "92806"
      }, {
        "storeNo": "9197",
        "address": "191 E YORBA LINDA BLVD",
        "city": "PLACENTIA, CA",
        "phone": "714-854-1767 ",
        "zip": "92870"
      }, {
        "storeNo": "6640",
        "address": "550 S GRAND AVE",
        "city": "GLENDORA, CA",
        "phone": "626-857-0633 ",
        "zip": "91741"
      }, {
        "storeNo": "5771",
        "address": "17522 BEACH BLVD",
        "city": "HUNTINGTON BEACH, CA",
        "phone": "714-596-5272 ",
        "zip": "92647"
      }, {
        "storeNo": "9534",
        "address": "15990 BROOKHURST ST",
        "city": "FOUNTAIN VALLEY, CA",
        "phone": "714-775-3974 ",
        "zip": "92708"
      }, {
        "storeNo": "5848",
        "address": "20505 SHERMAN WAY",
        "city": "CANOGA PARK, CA",
        "phone": "818-719-6599 ",
        "zip": "91306"
      }, {
        "storeNo": "5566",
        "address": "17010 CHATSWORTH ST",
        "city": "GRANADA HILLS, CA",
        "phone": "818-360-0871 ",
        "zip": "91344"
      }, {
        "storeNo": "15930",
        "address": "2050 GLENOAKS BLVD",
        "city": "SAN FERNANDO, CA",
        "phone": "818-741-1103 ",
        "zip": "91340"
      }, {
        "storeNo": "6347",
        "address": "18515 DEVONSHIRE ST",
        "city": "NORTHRIDGE, CA",
        "phone": "818-363-1067 ",
        "zip": "91324"
      }, {
        "storeNo": "5797",
        "address": "1086 W ARROW HWY",
        "city": "SAN DIMAS, CA",
        "phone": "909-305-1352 ",
        "zip": "91773"
      }, {
        "storeNo": "12573",
        "address": "16201 HARBOR BLVD",
        "city": "FOUNTAIN VALLEY, CA",
        "phone": "714-839-3496 ",
        "zip": "92708"
      }, {
        "storeNo": "5881",
        "address": "19501 BEACH BLVD",
        "city": "HUNTINGTON BEACH, CA",
        "phone": "714-969-1368 ",
        "zip": "92648"
      }],
      productData:[{"name":"SO DELICIOUS VANILLA BEAN 16 OZ","price":"6.49","productCode":"0074447347711"},
        {"name":"TRU FRU PINEAPPLE WHITE 8 OZ","price":"7.99","productCode":"0085001211751"},
        {"name":"SO DELICIOUS VANILLA BEAN 1PINT","price":"4.49","productCode":"0074447347011"},
        {"name":"HALO TOP PEANUT BUTTER ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900322"},
        {"name":"SO DELICIOUS TRUFFLE CASHEW 16 ML","price":"4.49","productCode":"0074447347621"},
        {"name":"SO DELICIOUS CREAM CASHEW 1PINT","price":"4.49","productCode":"0074447300028"},
        {"name":"MAGNUM DOUBLE CARAMEL ICE CREAM 9.13 OZ","price":"5.99","productCode":"0007756713228"},
        {"name":"SO DELICIOUS ALMOND FUDGE 4.1 ML","price":"6.49","productCode":"0074447349213"},
        {"name":"MAGNUM DARK CHOCOLATE ICE CREAM 9.12 OZ","price":"5.99","productCode":"0007756713227"},
        {"name":"TRU FRU WHOLE STRAWBERRIES 8 OZ","price":"7.99","productCode":"0085001211716"},
        {"name":"TRU FRU DARK CHOCOLATE RASPBERRIES 8 OZ","price":"7.99","productCode":"0085024100884"},
        {"name":"TRU FRU CHOCOLATE RASPBERRIES 8 OZ","price":"7.99","productCode":"0085024100883"},
        {"name":"HALO TOP CREAM ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900313"},
        {"name":"SO DELICIOUS MILK MINT CHIP 1PINT","price":"4.99","productCode":"0074447347055"},
        {"name":"HALO TOP MINT CHIP ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900310"},
        {"name":"HALO TOP CARMEL MACCHIATO ICE CREAM 1PINT","price":"5.99","productCode":"0085808900335"},
        {"name":"MAGNUM ICE CREAM MINI 6 COUNT","price":"7.79","productCode":"0007756700368"},
        {"name":"TRU FRU STRAWBERRY MILK CHOCOLATE 20 OZ","price":"16.99","productCode":"0085001211727"},
        {"name":"SO DELICIOUS VANILLA SANDWICH 8 OZ","price":"6.49","productCode":"0074447347211"},
        {"name":"MAGNUM RASPBERRY ICE CREAM 3 COUNT","price":"5.99","productCode":"0007756727105"},
        {"name":"HALO TOP CARAMEL ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900315"},
        {"name":"HALO TOP CHOCOLATE MOCHA ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900311"},
        {"name":"M&M'S PEANUT BUTTER 8.6 OZ","price":"4.99","productCode":"0004000060557"},
        {"name":"CHEEZ IT SMOKED CHEDDAR 12.4 OZ","price":"4.99","productCode":"0002410012424"},
        {"name":"TATE'S BAKE SHOP BARK CHOCOLATE 4.6 OZ","price":"5.99","productCode":"0081029100771"},
        {"name":"CLUB CRACKERS SWEET HAWAIIAN 13.7 OZ","price":"3.99","productCode":"0003010012977"},
        {"name":"CHIPS AHOY CHEWY HOT COCOA 9.9 OZ","price":"4.99","productCode":"0004400007815"},
        {"name":"CHEEZ-IT CHEESE CRACKERS12.4 OZ","price":"4.99","productCode":"0002410012418"},
        {"name":"RITZ HOLIDAY SHAPES 12.3 OZ","price":"5.79","productCode":"0004400007905"},
        {"name":"OREO SANDWICH COOKIES 8.5 OZ","price":"5.49","productCode":"0004400001473"},
        {"name":"RITZ FUDGE COVERED 7.8 OZ","price":"5.49","productCode":"0004400008042"},
        {"name":"TATE'S BAKE SHOP GINGERSNAP 6.5 OZ","price":"5.99","productCode":"0081029100797"},
        {"name":"SKITTLES POP'D SOUR STAND 5.5 OZ","price":"6.99","productCode":"0002200030128"},
        {"name":"SKITTLES POP'D ORIGINALS STAND 5.5 OZ","price":"6.99","productCode":"0002200030125"},
        {"name":"CHEEZ-IT CHEESE CRACKERS 12.4 OZ","price":"4.99","productCode":"0002410010685"},
        {"name":"PREMIUM SALTINE CRACKERS 16 OZ","price":"4.79","productCode":"0004400000057"},
        {"name":"WELCHS MIXED FRUIT 40 OZ","price":"9.99","productCode":"0003485684068"},
        {"name":"OREO CHOCOLATE COOKIES 18 OZ","price":"6.79","productCode":"0004400006023"},
        {"name":"CHIPS AHOY CHOCOLATE COOKIES 13 OZ","price":"4.99","productCode":"0004400003219"},
        {"name":"RUFFLES POTATO CHIPS 13 OZ","price":"6.49","productCode":"0002840051773"},
        {"name":"CHEETOS SNACKS CHEESE FLAVORED 15 OZ","price":"6.49","productCode":"0002840031409"},
        {"name":"RITZ ORIGINAL CRACKERS 13.7 OZ","price":"5.79","productCode":"0004400003111"},
        {"name":"HONEY MAID GRAHAM CRACKERS 14.4 OZ","price":"4.99","productCode":"0004400000463"},
        {"name":"LAYS POTATO CHIPS CLASSIC 13 OZ","price":"5.99","productCode":"0002840031041"},
        {"name":"TOWN HOUSE CRACKERS 13.8 OZ","price":"3.99","productCode":"0003010010055"},
        {"name":"NUTTER BUTTER SANDWICH COOKIES 16 OZ","price":"5.99","productCode":"0004400004631"},
        {"name":"STARRY CRANBERRY BLIZZ ZERO SUGAR 2 L","price":"2.79","productCode":"0001200024029"},
        {"name":"LA COLOMBE TRIPLE DRAFT LATTE 11 OZ","price":"2.61","productCode":"0060491300488"},
        {"name":"LA COLOMBE DRAFT LATTE VANILLA 11 OZ","price":"2.61","productCode":"0060491300490"},
        {"name":"BODYARMOR FRUIT PUNCH 12 OZ","price":"7.99","productCode":"0085817600280"},
        {"name":"GATORADE G SERIES THIRST QUENCHER 18 OZ","price":"16.15","productCode":"0005200012324"},
        {"name":"GATORADE PERFORM FRUIT PUNCH 12 OZ","price":"11.93","productCode":"0005200012196"},
        {"name":"GATORADE GLAZIER FREEZE 12 OZ","price":"11.93","productCode":"0005200012251"},
        {"name":"CELSIUS ENERGY DRINK 12 OZ","price":"23.99","productCode":"0088939200093"},
        {"name":"DIET COKE SODA POP COLA 12P 12 OZ","price":"10.99","productCode":"0004900002891"},
        {"name":"COCA-COLA SODA POP CLASSIC 12P 12 OZ","price":"12.43","productCode":"0004900002890"},
        {"name":"FOLGERS CLASSIC ROAST 33.7 OZ","price":"18.99","productCode":"0002550030418"},
        {"name":"SIGNATURE SELECT JUICE APPLE 64 OZ","price":"3.49","productCode":"0002113031008"},
        {"name":"SIGNATURE SELECT SODA CLUB 12 OZ","price":"4.50","productCode":"0002113029485"},
        {"name":"RED BULL ENERGY DRINK 12 OZ","price":"3.12","productCode":"0061126981899"},
        {"name":"TOPO CHICO MINERAL WATER 12P 12 OZ","price":"15.99","productCode":"0002113605046"},
        {"name":"PEPSI SODA COLA 12P 12 OZ","price":"12.43","productCode":"0001200080994"},
        {"name":"CRAISINS ORIGINAL  6 OZ","price":"2.99","productCode":"0003120000159"},
        {"name":"LAYS POTATO CHIPS  ORIGINAL 7.75 OZ","price":"4.99","productCode":"0002840004380"},
        {"name":"SUN-MAID RAISINS NATURAL CALIFORNIA 6 OZ","price":"3.49","productCode":"0004114312010"},
        {"name":"STACY'S BAKED PITA CHIPS 7.33 OZ","price":"5.99","productCode":"0002840056463"},
        {"name":"BLUE DIAMOND ALMONDS 6 OZ","price":"5.49","productCode":"0004157002970"},
        {"name":"O ORGANICS PECAN HALVES 10 OZ","price":"8.99","productCode":"0007989315826"},
        {"name":"FRITOS SCOOPS! CORN CHIPS 9.25 OZ","price":"5.79","productCode":"0002840058932"},
        {"name":"RITZ ORIGINAL CRACKERS 13.7 OZ","price":"5.79","productCode":"0004400003111"},
        {"name":"VICENZI LADYFINGERS 7 OZ","price":"3.99","productCode":"0070175068386"},
        {"name":"MARIANI APRICOTS MEDITERRANEAN 6 OZ","price":"3.50","productCode":"0007102214009"},
        {"name":"SMARTFOOD POPCORN CHEDDAR 6.75 OZ","price":"5.19","productCode":"0002840031401"},
        {"name":"CASA SANCHEZ MEDIUM ROJA SALSA 15 OZ","price":"5.99","productCode":"0007873200413"},
        {"name":"SIGNATURE CHERRIES DRIED 5 OZ","price":"3.99","productCode":"0002113052239"},
        {"name":"SIGNATURE PECAN PIECES 12 OZ","price":"6.99","productCode":"0002113012896"},
        {"name":"SNYDER'S OF HANOVER MINI PRETZELS 16 OZ","price":"4.49","productCode":"0007797508006"},
        {"name":"O ORGANIC FRUIT STRIPS STRAWBERRY 5 OZ","price":"3.99","productCode":"0007989312530"},
        {"name":"LOTUS COOKIES BISCOFF 8.8 OZ","price":"3.00","productCode":"0002178891629"},
        {"name":"CHEETOS CHEDDAR FLAVORED PUFFS 8 OZ","price":"4.99","productCode":"0002840007165"},
        {"name":"HELUVA GOOD! FRENCH ONION DIP 12 OZ","price":"1.99","productCode":"0007357013000"},
        {"name":"NILLA WAFERS COOKIES VANILLA 11 OZ","price":"5.49","productCode":"0004400002734"},
        {"name":"SIGNATURE APRICOTS FANCY DRIED 6 OZ","price":"2.99","productCode":"0002113052223"},
        {"name":"SUN-MAID RAISINS GOLDEN 12 OZ","price":"4.49","productCode":"0004114315160"},
        {"name":"NUTELLA BISCUITS T20 9.7 OZ","price":"5.99","productCode":"0000980083003"},
        {"name":"TOSTITOS SALSA CHUNKY MILD 15.5 OZ","price":"4.99","productCode":"0002840005597"},
        {"name":"FRITOS SCOOPS PARTY SIZE 15.5 OZ","price":"6.49","productCode":"0002840031403"},
        {"name":"TOOTSIE CHILDS PLAY 3.25 L","price":"13.99","productCode":"0007172001752"},
        {"name":"PLANTERS PEANUTS DRY ROASTED 16 OZ","price":"3.50","productCode":"0002900007325"},
        {"name":"FRITOS CORN CHIPS ORIGINAL 9.25 OZ","price":"5.79","productCode":"0002840058929"},
        {"name":"PREMIUM ORIGINAL SALTINE CRACKERS 16 OZ","price":"4.79","productCode":"0004400000057"},
        {"name":"SMARTFOOD POPCORN KETTLE CORN 7.75 OZ","price":"5.19","productCode":"0002840031400"},
        {"name":"SUN-MAID RAISINS SUN DRIED 32 OZ","price":"6.99","productCode":"0004114302917"},
        {"name":"SIM MILL COOK CR CC 5.5 OZ","price":"4.99","productCode":"0085606900519"},
        {"name":"DATES PITTED 32 OZ","price":"11.99","productCode":"0007102232119"},
        {"name":"CASA SANCHEZ MILD ROJA SALSA 15 OZ","price":"6.99","productCode":"0007873200400"},
        {"name":"PLANTERS MIXED NUTS 10.3 OZ","price":"5.99","productCode":"0002900001665"},
        {"name":"SMARTFOOD POPCORN CHEDDAR 6.25 OZ","price":"7.00","productCode":"0002840067909"},
        {"name":"OREO CHOCOLATE COOKIES 13.29 OZ","price":"4.99","productCode":"0004400006011"},
        {"name":"CHESTERS FRIES FLAMIN HOT BAG 5.25 OZ","price":"2.99","productCode":"0002840070569"},
        {"name":"GUACAMOLE SPICY 14 OZ","price":"5.99","productCode":"0081004159906"},
        {"name":"MARIANI PRUNES PROBIOTIC 7 OZ","price":"2.50","productCode":"0007102234011"},
        {"name":"BREYERS ICE CREAM NATURAL VANILLA 48 OZ","price":"5.49","productCode":"0077567254238"},
        {"name":"CLIF BAR ENERGY BAR CHOCOLATE BROWNIE 2.4 OZ","price":"1.99","productCode":"0072225210180"}],
      productIceData:[
        {"name":"CLIF BAR ENERGY BAR CHOCOLATE SHIP 2.4 OZ","price":"1.99","productCode":"0072225210090"},
        {"name":"CLIF BAR ENERGY BAR CHOCOLATE SHIP 2.4 OZ","price":"1.99","productCode":"0072225210090"}]
      /*

      * */

    }
  },
  components: {
  },
  watch: {
    // 监听 newOrderId 变量的变化
    newOrderId(newValue) {
      if (newValue) {
        // console.log('newOrderId 变化，启动定时任务');
        this.startTimer();  // 启动定时任务
      } else {
        this.stopTimer();   // 如果 newOrderId 为空，停止定时任务
      }
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  mounted() {
    this.gensFuncs();
  },
  methods: {
    pageRowsInput(event) {
      this.pageRows = event.target.value.slice(0, 3); // 限制为10位
    },
    handleInput(event) {
      this.card4No = event.target.value.slice(0, 4); // 限制为10位
    },

    printElement() {
      console.log("初始化")
      if (typeof getLodop === 'undefined') {
        console.error('LODOP未加载，请确保已正确引入 LodopFuncs.js');
        return;
      }

      for (let p = 1; p <= this.pageRows; p++) {
        this.gensFuncs();
        let LODOP = getLodop();
        if(LODOP){
          LODOP.PRINT_INIT('');
          LODOP.SET_PRINTER_INDEX(-1);
          LODOP.SET_PRINT_STYLE("FontName", "Times New Roman");
          LODOP.SET_PRINT_STYLE("FontSize", 8);
          LODOP.ADD_PRINT_IMAGE(20,10,250,120,"<img src='/image/walgreens_logo.png'/>");
          LODOP.SET_PRINT_STYLEA(0,"Stretch",1);
          LODOP.ADD_PRINT_TEXT(115,10,240,80,"#"+this.storeNo+"  "+this.tempAddress);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_TEXT(130,10,240,80,this.city+"  "+this.zip);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_TEXT(145,10,240,80,this.phone);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_TEXT(175,10,240,80,this.codeFrist+ "      "+this.codeSecend+ "      "+this.codeThird+ "       "+this.orderDate);
          LODOP.ADD_PRINT_TEXT(190,10,240,80,"ORDER ID:  "+this.seqNo);
          LODOP.ADD_PRINT_TEXT(205,10,240,80,"Date Placed: "+this.orderTempDate);
          LODOP.ADD_PRINT_TEXT(220,10,240,80,"Date Paid:    "+this.orderTempDate);
          LODOP.ADD_PRINT_TEXT(250,10,300,80,"******************************************");
          var iCurLine = 250;
          if(this.showProduceData.length>0)
            for (var i = 0; i < this.showProduceData.length; i++) {
            iCurLine = iCurLine+15;
            LODOP.ADD_PRINT_TEXT(iCurLine,10,300,80,this.showProduceData[i].name);
            iCurLine = iCurLine+15;
            LODOP.ADD_PRINT_TEXT(iCurLine,60,300,80,this.showProduceData[i].productCode);
            LODOP.ADD_PRINT_TEXT(iCurLine,200,300,80,this.showProduceData[i].price);
          }
          LODOP.ADD_PRINT_TEXT(iCurLine+15,10,300,80,"******************************************");

          LODOP.ADD_PRINT_TEXT(iCurLine+45,60,300,80,"SUBTOTAL");
          LODOP.ADD_PRINT_TEXT(iCurLine+45,201,300,80,this.subTotalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+60,60,300,80,"SALES TAX");
          LODOP.ADD_PRINT_TEXT(iCurLine+60,200,300,80,this.taxPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+75,60,300,80,"TOTAL");
          LODOP.ADD_PRINT_TEXT(iCurLine+75,200,300,80,this.totalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+90,60,300,80,"VISA ACCT     "+this.card4No);
          LODOP.ADD_PRINT_TEXT(iCurLine+90,200,300,80,this.totalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+105,60,300,80,"CHANGE");
          LODOP.ADD_PRINT_TEXT(iCurLine+105,200,300,80,"0.00");

          LODOP.ADD_PRINT_TEXT(iCurLine+145,10,300,80,"THANK YOU FOR SHOPPING AT WALGREENS");
          LODOP.ADD_PRINT_TEXT(iCurLine+185,10,240,80,"RFN#: "+this.barcodeValue);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_BARCODE(iCurLine+198,10,"70mm",28, 'EAN128C', this.barcodeValue)
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
          LODOP.PRINT();
          //LODOP.PREVIEW();

          setTimeout(function() {
          }, 1000);
        }else{
          console.log("LODOP 无法获取")
        }
      }
    },

    gensFuncs(){
      this.showProduceData = [];
      this.codeFrist = Math.floor(Math.random() * (400 - 250 + 1) + 250);
      this.codeSecend = Math.floor(Math.random() * (5500 - 4500 + 1) + 4500);
      this.codeThird= '00'+Math.floor(Math.random() * (99 - 20 + 1) + 20);

      this.orderDate = this.getRandomDateTime();
      var index = Math.floor(Math.random() * this.storeData.length);
      this.storeNo =  this.storeData[index].storeNo;
      this.address =  this.storeData[index].address;
      this.tempAddress =  this.address;

      this.phone =  this.storeData[index].phone;
      this.city =  this.storeData[index].city;
      this.zip =  this.storeData[index].zip;
      this.iptRows = Math.floor(Math.random() * (18 - 12 + 1)) + 12;
      this.iceRows = Math.floor(Math.random() * (12 - 6 + 1)) + 6;
      this.iptRowsFull = this.iptRows < 10 ? '0' + this.iptRows : this.iptRows.toString();

      var difference = 0;
      var startCount = 0;
      var endCount = 0;
      difference =  this.iptRows - this.iceRows;
      var pLength =  this.productData.length
      var pIceLeng = this.productIceData.length
      console.log("difference:"+difference)
      var tempPrice = 0.00;
      if(difference>0){
        startCount  =  Math.floor(Math.random() * (difference - 1 + 1)) + 1;
        console.log("startCount:"+startCount)
        if(startCount == 1){
          for (let i = 1; i <= difference; i++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }
          for(let j = 1; j <= this.iceRows; j++) {
            var pIcaIndex = Math.floor(Math.random() * pIceLeng);
            this.showProduceData.push(this.productIceData[pIcaIndex])
            tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
          }
        }else if(startCount == difference){
          for(let j = 1; j <= this.iceRows; j++) {
            var pIcaIndex = Math.floor(Math.random() * pIceLeng);
            this.showProduceData.push(this.productIceData[pIcaIndex])
            tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
          }
          for (let i = 1; i <= difference; i++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }
        }else{
          endCount = difference - startCount;

          for(let y = 1; y <= startCount; y++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }

          for(let j = 1; j <= this.iceRows; j++) {
            var pIcaIndex = Math.floor(Math.random() * pIceLeng);
            this.showProduceData.push(this.productIceData[pIcaIndex])
            tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
          }

          for (let i = 1; i <= endCount; i++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }

        }

      }else{
        for(let j = 1; j <= this.iceRows; j++) {
          var pIcaIndex = Math.floor(Math.random() * pIceLeng);
          this.showProduceData.push(this.productIceData[pIcaIndex])
          tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
        }
      }
      tempPrice = parseFloat(tempPrice).toFixed(2);
      console.log("showProduceData:"+this.showProduceData)
      //单号
      //单号
      this.seq4No = Math.floor(Math.random() * (6000 - 3000 + 1) + 3000);
      let num2 = Math.floor(Math.random() * (99 - 11 + 1) + 11);
      let num3 = Math.floor(Math.random() * (999999 - 110000 + 1) + 110000);
      this.seqNo = this.seq4No+""+num2+""+num3;
      //条形码
      let barcodeFrist ="99";
      let barcodeSecond = Math.random().toString().slice(2, 7);
      let barcodeThired = this.storeNo;
      if(barcodeThired&&barcodeThired.length>=5){

      }else{
        barcodeThired =   ("00000"+String(barcodeThired)).substr(-5)
      }

      this.barcodeValue = barcodeFrist+''+barcodeThired+""+num2+""+this.seq4No+""+this.iptRowsFull+""+barcodeSecond;
      this.barcodeValue = this.barcodeValue.substring(0, 20);
      this.barcodeValue = this.barcodeValue.replace(/(.{4})(?=.)/g, '$1-').replace(/(.*)-$/, '$1');
      //信用卡后四位
      /*var myArray =[1972,1191,1614,1785,1798,1516,1077,1772,1892,1379,1764,1621,1342,1397,1589,1568,1074,1722,1111,1651,1986,1212,1494,1859,1633,1601,1496,1277,1945,1552,1030,1153,1244,1708,1549,1344,1038,1086,1336,1814,1635,1484,1820,1178,1490,1808,1511,1163,1717,1324,1398,1261,1940,1833,1522,1681,1269,1775,1310,1490,1891,1759,1288,1372,1168,1572,1409,1863,1879,1167,1467,1385,1083,1058,1918,1313,1117,1292,1351,1423,1910,1283,1773,1578,1122,1024,1297,1810,1941,1412,1081,1499,1117,1345,1593,1939,1971,1267,1552,1258];
      let myIndex = Math.floor(Math.random() * myArray.length);
      this.card4No = myArray[myIndex];*/

      this.code7No = Math.random().toString().slice(2, 9);
      //打印机编号后5位
      this.aId5No = Math.floor(Math.random() * (926150 - 925160 + 1)) + 925160;
      //日期
      this.orderDate = this.getRandomDateTime();
      this.orderTempDate = this.getRandomTempYear();
      //行数随机
      let num13 = this.generateRandomString(13);
      this.issuer = this.iptRows+""+num13;
      this.genRows= parseInt(this.iptRows);
      this.subTotalPrice = tempPrice;
      this.taxPrice = parseFloat(tempPrice * this.tax10).toFixed(2);
      this.totalPrice = parseFloat(this.subTotalPrice) + parseFloat(this.taxPrice);
      this.totalPrice = parseFloat(this.totalPrice).toFixed(2);
      this.showIptRows = this.iptRows < 10 ? this.iptRows.toString()+'\xa0'+'\xa0' : this.iptRows.toString();
      this.generateBarcode();
    },
    generateRandomString(num) {
      let digitsCount = 0;
      let result = '';

      while (result.length < num) {
        const randomChar = Math.random() >= 0.5 ? this.getRandomChar() : this.getRandomDigit();
        result += randomChar;
        if (randomChar >= '0' && randomChar <= '9') {
          digitsCount++;
        }
      }
      // 确保至少有4位数字
      while (digitsCount < 4) {
        const randomIndex = Math.floor(Math.random() * result.length);
        const charAtIndex = result.charAt(randomIndex);
        if (charAtIndex >= '0' && charAtIndex <= '9') {
          result = result.slice(0, randomIndex) + this.getRandomDigit() + result.slice(randomIndex);
          digitsCount++;
        }
      }
      return result;
    },
    getRandomChar() {
      return String.fromCharCode(65 + Math.floor(Math.random() * 26)); // A-Z
    },
    getRandomDigit() {
      return String.fromCharCode(48 + Math.floor(Math.random() * 10)); // 0-9
    },

    getRandomDateTime() {
      const startYear = 2016;
      const endYear = 2024;
      const startTime = new Date(startYear, 4, 21); // 2016年的4月21日
      const diff = new Date(endYear, 7, 13) - startTime; // 2024年的7月1日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      var year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }

      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      var  tempHours =  Math.floor(Math.random() * (19 - 12 + 1)) + 12;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');
      const seconds = String(randomDate.getSeconds()).padStart(2, '0');
      var tempYear = year.toString();

      var str;
      if(tempHours>12) {
        tempHours -= 12;
        str = " PM";
      }else{
        str = " AM";
      }
      tempHours = tempHours < 10 ? "0" + tempHours:tempHours;
      return `${month}/${day}/${tempYear} ${tempHours}:${minutes}${str}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },

    getRandomTempYear() {
      const startYear = 2016;
      const endYear = 2024;
      const startTime = new Date(startYear, 4, 21); // 2016年的4月21日
      const diff = new Date(endYear, 7, 13) - startTime; // 2024年的7月1日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      var year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }

      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      var  tempHours =  Math.floor(Math.random() * (19 - 12 + 1)) + 12;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');
      const seconds = String(randomDate.getSeconds()).padStart(2, '0');
      var tempYear = year.toString();
      return `${month}/${day}/${tempYear}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },
    generateBarcode() {
      // 使用 JsBarcode 库生成条形码
      JsBarcode(this.$refs.barcode, this.barcodeValue, {
        format: "CODE128", // 条形码格式，可选 CODE128、EAN 等
        lineColor: "#000",
        width: 1,
        height: 30,
        displayValue: false // 是否显示条形码值
      });
    },
    // 判断设备是否为移动端
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 使用正则检查 userAgent 中是否包含手机/平板的关键字
      return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile|webos.*mobile/i.test(userAgent);
    },
    isMobileBrowerSize() {
      return window.innerWidth <= 768;  // 视窗宽度小于等于 768px 视为移动端
    },

  },
  beforeDestroy() {

  },
  created() {

  }
}
</script>

<style scoped>
@media print {
  body {
    font-family: "Times New Roman", Times, serif;
  }
}
</style>