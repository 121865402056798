/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: Summer
 * @Date: 2021-04-29 14:26:06
 * @LastEditors: Summer
 * @LastEditTime: 2021-05-11 17:16:32
 */
export default [
  {
    path: '/',
    name: 'newHome',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/index.vue')
  },
  {
    path: '/wholeFoods',
    name: 'wholeFoods',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/wholeFoods.vue')
  },
  {
    path: '/lodop',
    name: 'lodopvue',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/indexLodop.vue')
  },
  {
    path: '/fredMeyer',
    name: 'Fred Meyer',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/indexFredMeyer.vue')
  },
  {
    path: '/safeway',
    name: 'safeway',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/indexSafeway.vue')
  },
  {
    path: '/walgreens',
    name: 'walgreens',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/indexWalgreens.vue')
  },
  {
    path: '/barFredMeyer',
    name: 'barFredMeyer',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/barFredMeyer.vue')
  },
  {
    path: '/barWalgreens',
    name: 'barWalgreens',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/barWalgreens.vue')
  },
  {
    path: '/indexWholeFoods',
    name: 'indexWholeFoods',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/indexWholeFoods.vue')
  },
  {
    path: '/beefFredMeyer',
    name: 'beefFredMeyer',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/beefFredMeyer.vue')
  },
  {
    path: '/indexTarget',
    name: 'indexTarget',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/indexTarget.vue')
  },
  {
    path: '/indexWalmart',
    name: 'indexWalmart',
    meta: {
      title: '店铺'
    },
    component: () => import('@/views/print/indexWalmart.vue')
  },
  {
    path: '/store',
    name: 'store',
    meta: {
      title: '店铺'
    },
    component: () => import('@/views/print/store.vue')
  }
]
