npm <template>

  <div style="height: 100vh">
    <div style="padding-left: 15px;font-size: 18px">
      <p>张数 ：<input v-model="pageRows"  style="width: 80px" @input="pageRowsInput" type="number"> 卡号 ：<input v-model="card4No" style="width: 200px"  @input="handleInput" type="number" >
        <button @click ="printElement()" style="margin-left:10px;width: 180px;background-color: #096c0b; height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">Lodop打印</button></p>
    </div>
    <div  style="background-color: #4AB7BD;height: auto;padding-top: 20px;">
      <div id="printContent" style="-webkit-font-smoothing:antialiased;font-weight: lighter">
        <div   style="padding-bottom:70px;line-height:5px;font-size:12px; text-align: center;width: 300px;background-color: #FFFFFF; margin: auto">
          <p style="padding-top: 60px;"><img src='/image/fredMeyer_logo.png' width="250px"/></p>
          <div style="text-align: left">
            <!--          <p style="padding-left: 20px;" v-html="'STORE' + '&nbsp;'.repeat(2*2) + '#'+storeNo + '&nbsp;'.repeat(2*2) + ' '+address"></p>-->
            <p class="container" style="padding-left: 10px; padding-top: 30px; text-align: center;" ><span style="display: inline-block; font-weight: bold; text-align: center; " v-html="'THANK YOU FOR CHOOSING FREDDY\'S!'"></span></p>
            <p  style="padding-left: 50px; padding-top: 10px; "><span v-html="''+address"/></p>
            <p  style="padding-left: 50px;"><span v-html="''+phone"/></p>
            <p  style="padding-left: 50px;"><span v-html="'YOUR CASHIER WAS '+salesPerson"/></p>
            <p  style="padding-top: 1px;"></p>
            <div style="font-size:11px;" v-if="showProduceData" v-for="(item, index) in showProduceData" :key="index">
              <p style="padding-left: 15px;" v-html="item.name"></p>
              <p style="padding-left: 30px;" v-html="item.productCode + '&nbsp;'.repeat(42*1) + item.price+' B'"></p>
            </div>
            <p style="padding-left: 60px; font-weight: bold;" v-html="'TAX'+ '&nbsp;'.repeat(47) + ''+taxPrice"></p>
            <p style="padding-left: 38px; font-weight: bold;"><span  v-html="'****  BALANCE' + '&nbsp;'.repeat(36*1)"></span><span v-html="''+totalPrice"></span></p>
            <p style="padding-left: 15px;" v-html="'************'+card4No"></p>
            <p style="padding-left: 15px;" v-html="'REF #:  '+seqNo"></p>
            <p style="padding-left: 15px;" v-html="'PURCHASE:   '+totalPrice"></p>
            <p style="padding-left: 15px;" v-html="'CASHBACK:   0.00'"></p>
            <p style="padding-left: 15px;" v-html="'TOTAL:   '+totalPrice"></p>
            <p style="padding-top: 1px;"></p>
            <p style="padding-left: 60px; font-weight: bold;" v-html="'DEBIT'+ '&nbsp;'.repeat(43)  +totalPrice"></p>
            <p style="padding-left: 60px;" v-html="'CHANGE'+ '&nbsp;'.repeat(39)  +'0.00'"></p>
            <p style="padding-left: 15px;" v-html="'TOTAL NUMBER OF ITEMS SOLD =  '+ '&nbsp;'.repeat(7)+iptRows"></p>
            <p style="padding-left: 15px;" v-html="orderDate+ '&nbsp;'.repeat(3) +codeFrist+ '&nbsp;'.repeat(3) +codeSecend+ '&nbsp;'.repeat(3)+codeThird+ '&nbsp;'.repeat(4)  +storeNo"></p>
            <p style="padding-top: 20px;"></p>
            <p style="padding-left: 15px;"><span  v-html="'**********************************************************'"/></p>
            <p style=" margin-top: -5px;font-size:15px; text-align: center;" v-html="'Tell  Us  How  We  Are  Doing!'"></p>
            <p style="padding-left: 5px; text-align: center; font-size:12px;" v-html="'Earn 50 BOUNS FUEL POINTS!'"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>
<script>
import {initApkHub, newestApkUrl, queryApkHub} from "@/api/index";
import QRCode from 'qrcode'
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';
import { Circle } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';
import { Image as VanImage } from 'vant';
import { Card } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { Notify } from 'vant';
import JsBarcode from 'jsbarcode'
Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
  data () {
    return {
      storeNo:'',
      phone:'',
      city:'',
      address:'',
      salesPerson:'',
      tempAddress:'',
      seq4No:'',
      issuer:'',
      iptRowsFull:'',
      pageRows:1,
      iptRows:3,
      showIptRows:'',
      itemPrice:5.50,
      tax10:0,
      taxPrice:0.0,
      totalPrice:0.0,
      subTotalPrice:0.0,
      genRows:3,
      card4No:9311,
      seqNo:304740,
      barcodeValue:'9900300230130764390',
      orderDate:'',
      aId5No:925650,
      code7No:1911123,
      codeFrist:328,
      codeSecend:3,
      codeThird:20,
      showProduceData:[{"name":"BREYERS ICE CREAM VANILLA 48 Oz","price":"5.50","productCode":"0077567254238"}],
      iceRows:0,
      storeData:[{
        "storeNo": "00375",
        "address": "1091 S Hoover St",
        "city": "Los Angeles, CA",
        "zip": "90006",
        "phone": "213-386-1680",
        "salesPerson": "Fantastic Dolphin"
      }, {
        "storeNo": "00016",
        "address": "670 S Western Ave",
        "city": "Los Angeles, CA",
        "zip": "90005",
        "phone": "213-383-5058",
        "salesPerson": "Sunny Banana"
      }, {
        "storeNo": "00381",
        "address": "1717 S Western Ave",
        "city": "Los Angeles, CA",
        "zip": "90006",
        "phone": "323-731-0164",
        "salesPerson": "Glowing Blueberry"
      }, {
        "storeNo": "00032",
        "address": "3410 W 3Rd St",
        "city": "Los Angeles, CA",
        "zip": "90020",
        "phone": "213-480-1421",
        "salesPerson": "Peaceful Ramen"
      }, {
        "storeNo": "00770",
        "address": "1700 W 6Th St",
        "city": "Los Angeles, CA",
        "zip": "90017",
        "phone": "213-353-0920",
        "salesPerson": "Elegant Squirrel"
      }, {
        "storeNo": "00294",
        "address": "2600 S Vermont Ave",
        "city": "Los Angeles, CA",
        "zip": "90007",
        "phone": "323-732-3863",
        "salesPerson": "Soft Fro-yo"
      }, {
        "storeNo": "00022",
        "address": "645 W 9Th St",
        "city": "Los Angeles, CA",
        "zip": "90015",
        "phone": "213-452-0840",
        "salesPerson": "Glamorous Genius"
      }, {
        "storeNo": "00359",
        "address": "1748 S. Jefferson Blvd.",
        "city": "Los Angeles, CA",
        "zip": "90018",
        "phone": "323-735-8317",
        "salesPerson": "Dazzling Wizard"
      }, {
        "storeNo": "00291",
        "address": "4760 W Pico Blvd",
        "city": "Los Angeles, CA",
        "zip": "90019",
        "phone": "323-937-4107",
        "salesPerson": "Yellow Cheese"
      }, {
        "storeNo": "00039",
        "address": "260 S La Brea Ave",
        "city": "Los Angeles, CA",
        "zip": "90036",
        "phone": "323-937-3264",
        "salesPerson": "Special Warrior"
      }, {
        "storeNo": "00289",
        "address": "5601 Wilshire Blvd",
        "city": "Los Angeles, CA",
        "zip": "90036",
        "phone": "323-936-4954",
        "salesPerson": "Red Bird"
      }, {
        "storeNo": "00206",
        "address": "5429 Hollywood Blvd",
        "city": "Los Angeles, CA",
        "zip": "90027",
        "phone": "323-957-9657",
        "salesPerson": "Glowing Pudding"
      }, {
        "storeNo": "00344",
        "address": "5318 S Main St",
        "city": "Los Angeles, CA",
        "zip": "90037",
        "phone": "323-846-8895",
        "salesPerson": "Jovial Cranberry"
      }, {
        "storeNo": "00283",
        "address": "5080 Obama Blvd",
        "city": "Los Angeles, CA",
        "zip": "90016",
        "phone": "323-292-0633",
        "salesPerson": "Joyful Caramel"
      }, {
        "storeNo": "00293",
        "address": "1233 N La Brea Ave",
        "city": "West Hollywood, CA",
        "zip": "90038",
        "phone": "323-876-8790",
        "salesPerson": "Special Driver"
      }, {
        "storeNo": "00777",
        "address": "1820 W Slauson Ave",
        "city": "Los Angeles, CA",
        "zip": "90047",
        "phone": "323-293-1369",
        "salesPerson": "Cool Pineapple"
      }, {
        "storeNo": "00100",
        "address": "7257 W Sunset Blvd",
        "city": "Los Angeles, CA",
        "zip": "90046",
        "phone": "323-512-8382",
        "salesPerson": "Rich Goose"
      }, {
        "storeNo": "00363",
        "address": "2750 E 1St St",
        "city": "Los Angeles, CA",
        "zip": "90033",
        "phone": "323-268-0461",
        "salesPerson": "Fantastic Giraffe"
      }, {
        "storeNo": "00534",
        "address": "10790 Macarthur Blvd",
        "city": "Oakland, CA",
        "zip": "94605",
        "phone": "510-924-3060",
        "salesPerson": "Peaceful Fish"
      }, {
        "storeNo": "00351",
        "address": "345 Williams Ave",
        "city": "San Francisco, CA",
        "zip": "94124",
        "phone": "415-330-1870",
        "salesPerson": "brat"
      }, {
        "storeNo": "00357",
        "address": "1800 Folsom St",
        "city": "San Francisco, CA",
        "zip": "94103",
        "phone": "415-558-9137",
        "salesPerson": "Fantastic Burger"
      }, {
        "storeNo": "00352",
        "address": "1250 Macdonald Ave",
        "city": "Richmond, CA",
        "zip": "94801",
        "phone": "510-412-4444",
        "salesPerson": "Strange Meringue"
      }, {
        "storeNo": "00764",
        "address": "300 Atlantic Ave",
        "city": "Pittsburg, CA",
        "zip": "94565",
        "phone": "925-473-2130",
        "salesPerson": "Bright Goose"
      }, {
        "storeNo": "00603",
        "address": "301 E Wallace Kneeland Blvd",
        "city": "Shelton, WA",
        "zip": "98584",
        "phone": "360-427-2979",
        "salesPerson": "Jumping Bird"
      }, {
        "storeNo": "00659",
        "address": "555 Trosper Rd SW",
        "city": "Tumwater, WA",
        "zip": "98512",
        "phone": "360-753-7915",
        "salesPerson": "Cheerful Plum"
      }, {
        "storeNo": "00659",
        "address": "555 Trosper Rd Sw",
        "city": "Tumwater, WA",
        "zip": "98512",
        "phone": "360-753-7900",
        "salesPerson": "Brave Robot"
      }, {
        "storeNo": "00186",
        "address": "700 Sleater Kinney Rd SE",
        "city": "Lacey, WA",
        "zip": "98503",
        "phone": "360-438-6465",
        "salesPerson": "Moist Sandwich"
      }, {
        "storeNo": "00186",
        "address": "700 Sleater Kinney Rd Se",
        "city": "Lacey, WA",
        "zip": "98503",
        "phone": "360-438-6450",
        "salesPerson": "Brave Rockstar"
      }, {
        "storeNo": "00126",
        "address": "4775 Whitman Ln SE",
        "city": "Lacey, WA",
        "zip": "98513",
        "phone": "360-438-3392",
        "salesPerson": "Superb Popcorn"
      }, {
        "storeNo": "00422",
        "address": "131 Market St",
        "city": "Alamosa, CO",
        "zip": "81101",
        "phone": "719-589-2492",
        "salesPerson": "Bulky Fig"
      }, {
        "storeNo": "00668",
        "address": "985 E Riggs Rd",
        "city": "Chandler, AZ",
        "zip": "85249",
        "phone": "480-883-6500",
        "salesPerson": "Ramell"
      }, {
        "storeNo": "00681",
        "address": "2929 E Ocotillo Rd",
        "city": "Chandler, AZ",
        "zip": "85249",
        "phone": "480-566-8875",
        "salesPerson": "Brave Fish"
      }, {
        "storeNo": "00069",
        "address": "2010 S Alma School Rd",
        "city": "Chandler, AZ",
        "zip": "85286",
        "phone": "480-917-7596",
        "salesPerson": "Super Pizza"
      }, {
        "storeNo": "00072",
        "address": "87 E Williams Field Rd",
        "city": "Gilbert, AZ",
        "zip": "85295",
        "phone": "480-726-3720",
        "salesPerson": "Sunny Hammer"
      }, {
        "storeNo": "00670",
        "address": "6470 S Higley Rd",
        "city": "Gilbert, AZ",
        "zip": "85298",
        "phone": "480-840-9560",
        "salesPerson": "Strong Hunter"
      }, {
        "storeNo": "00051",
        "address": "1950 W Ray Rd",
        "city": "Chandler, AZ",
        "zip": "85224",
        "phone": "480-814-7732",
        "salesPerson": "Bright Tiramisu"
      }, {
        "storeNo": "00052",
        "address": "855 W Warner Rd",
        "city": "Gilbert, AZ",
        "zip": "85233",
        "phone": "480-813-7388",
        "salesPerson": "Dazzling Fro-yo"
      }, {
        "storeNo": "00075",
        "address": "3261 E Pecos Rd",
        "city": "Gilbert, AZ",
        "zip": "85295",
        "phone": "480-988-6300",
        "salesPerson": "Lovely Apple"
      }, {
        "storeNo": "00064",
        "address": "714 S Val Vista Dr",
        "city": "Gilbert, AZ",
        "zip": "85296",
        "phone": "480-654-6968",
        "salesPerson": "Strong Garlic"
      }, {
        "storeNo": "00037",
        "address": "981 W Elliot Rd",
        "city": "Chandler, AZ",
        "zip": "85225",
        "phone": "480-821-2296",
        "salesPerson": "Gentle Ice-cream"
      }, {
        "storeNo": "00658",
        "address": "4075 E Williams Field Rd",
        "city": "Gilbert, AZ",
        "zip": "85295",
        "phone": "480-347-9812",
        "salesPerson": "Jovial Asparagus"
      }, {
        "storeNo": "00627",
        "address": "9900 S Rural Rd",
        "city": "Tempe, AZ",
        "zip": "85284",
        "phone": "480-783-6200",
        "salesPerson": "Epic Hunter"
      }, {
        "storeNo": "00624",
        "address": "1845 E Baseline Rd",
        "city": "Gilbert, AZ",
        "zip": "85233",
        "phone": "480-539-3700",
        "salesPerson": "Melissa hernandez"
      }, {
        "storeNo": "00062",
        "address": "554 W Baseline Rd",
        "city": "Mesa, AZ",
        "zip": "85210",
        "phone": "480-969-9299",
        "salesPerson": "Strong Chameleon"
      }, {
        "storeNo": "00022",
        "address": "1835 E Guadalupe Rd",
        "city": "Tempe, AZ",
        "zip": "85283",
        "phone": "480-838-7023",
        "salesPerson": "Divine Ribs"
      }, {
        "storeNo": "00607",
        "address": "5100 S Mcclintock Dr",
        "city": "Tempe, AZ",
        "zip": "85282",
        "phone": "480-752-9700",
        "salesPerson": "JessRenae"
      }, {
        "storeNo": "00693",
        "address": "25105 S Ellsworth Rd",
        "city": "Queen Creek, AZ",
        "zip": "85142",
        "phone": "480-428-7615",
        "salesPerson": "Cheerful Pug"
      }, {
        "storeNo": "00615",
        "address": "1455 N Higley Rd",
        "city": "Gilbert, AZ",
        "zip": "85234",
        "phone": "480-428-7180",
        "salesPerson": "Unique Gummy Bear"
      }, {
        "storeNo": "00628",
        "address": "3949 E Chandler Blvd",
        "city": "Phoenix, AZ",
        "zip": "85048",
        "phone": "480-706-7340",
        "salesPerson": "Karate Marshmellow"
      }, {
        "storeNo": "00123",
        "address": "2727 E Broadway Rd",
        "city": "Mesa, AZ",
        "zip": "85204",
        "phone": "480-464-5705",
        "salesPerson": "Sweet Master"
      }, {
        "storeNo": "00027",
        "address": "3616 E Ray Rd",
        "city": "Phoenix, AZ",
        "zip": "85044",
        "phone": "480-706-0601",
        "salesPerson": "Charming Brownie"
      }, {
        "storeNo": "00113",
        "address": "1245 W Main St",
        "city": "Mesa, AZ",
        "zip": "85201",
        "phone": "480-833-8879",
        "salesPerson": "Cute Giraffe"
      }, {
        "storeNo": "00124",
        "address": "3255 S Rural Rd",
        "city": "Tempe, AZ",
        "zip": "85282",
        "phone": "480-829-7799",
        "salesPerson": "Matthew miller"
      }, {
        "storeNo": "00055",
        "address": "1915 S Power Rd",
        "city": "Mesa, AZ",
        "zip": "85206",
        "phone": "480-924-5159",
        "salesPerson": "Gorgeous Paella"
      }, {
        "storeNo": "00101",
        "address": "3232 S Mill Ave",
        "city": "Tempe, AZ",
        "zip": "85282",
        "phone": "480-858-9096",
        "salesPerson": "MarioMuniz"
      }, {
        "storeNo": "00656",
        "address": "22265 E Queen Creek Rd",
        "city": "Queen Creek, AZ",
        "zip": "85142",
        "phone": "602-457-4315",
        "salesPerson": "Clever Jam"
      }, {
        "storeNo": "00043",
        "address": "2700 W Baseline Rd",
        "city": "Tempe, AZ",
        "zip": "85283",
        "phone": "602-438-1756",
        "salesPerson": "Shannon Harwood"
      }, {
        "storeNo": "00611",
        "address": "4440 E Main St",
        "city": "Mesa, AZ",
        "zip": "85205",
        "phone": "480-218-8540",
        "salesPerson": "Spinning Turquoise"
      }],
      productData:[{"name":"SO DELICIOUS VANILLA BEAN 16 OZ","price":"6.49","productCode":"0074447347711"},
        {"name":"TRU FRU PINEAPPLE WHITE 8 OZ","price":"7.99","productCode":"0085001211751"},
        {"name":"SO DELICIOUS VANILLA BEAN 1PINT","price":"4.49","productCode":"0074447347011"},
        {"name":"HALO TOP PEANUT BUTTER ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900322"},
        {"name":"SO DELICIOUS TRUFFLE CASHEW 16 ML","price":"4.49","productCode":"0074447347621"},
        {"name":"SO DELICIOUS CREAM CASHEW 1PINT","price":"4.49","productCode":"0074447300028"},
        {"name":"MAGNUM DOUBLE CARAMEL ICE CREAM 9.13 OZ","price":"5.99","productCode":"0007756713228"},
        {"name":"SO DELICIOUS ALMOND FUDGE 4.1 ML","price":"6.49","productCode":"0074447349213"},
        {"name":"MAGNUM DARK CHOCOLATE ICE CREAM 9.12 OZ","price":"5.99","productCode":"0007756713227"},
        {"name":"TRU FRU WHOLE STRAWBERRIES 8 OZ","price":"7.99","productCode":"0085001211716"},
        {"name":"TRU FRU DARK CHOCOLATE RASPBERRIES 8 OZ","price":"7.99","productCode":"0085024100884"},
        {"name":"TRU FRU CHOCOLATE RASPBERRIES 8 OZ","price":"7.99","productCode":"0085024100883"},
        {"name":"HALO TOP CREAM ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900313"},
        {"name":"SO DELICIOUS MILK MINT CHIP 1PINT","price":"4.99","productCode":"0074447347055"},
        {"name":"HALO TOP MINT CHIP ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900310"},
        {"name":"HALO TOP CARMEL MACCHIATO ICE CREAM 1PINT","price":"5.99","productCode":"0085808900335"},
        {"name":"MAGNUM ICE CREAM MINI 6 COUNT","price":"7.79","productCode":"0007756700368"},
        {"name":"TRU FRU STRAWBERRY MILK CHOCOLATE 20 OZ","price":"16.99","productCode":"0085001211727"},
        {"name":"SO DELICIOUS VANILLA SANDWICH 8 OZ","price":"6.49","productCode":"0074447347211"},
        {"name":"MAGNUM RASPBERRY ICE CREAM 3 COUNT","price":"5.99","productCode":"0007756727105"},
        {"name":"HALO TOP CARAMEL ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900315"},
        {"name":"HALO TOP CHOCOLATE MOCHA ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900311"},
        {"name":"M&M'S PEANUT BUTTER 8.6 OZ","price":"4.99","productCode":"0004000060557"},
        {"name":"CHEEZ IT SMOKED CHEDDAR 12.4 OZ","price":"4.99","productCode":"0002410012424"},
        {"name":"TATE'S BAKE SHOP BARK CHOCOLATE 4.6 OZ","price":"5.99","productCode":"0081029100771"},
        {"name":"CLUB CRACKERS SWEET HAWAIIAN 13.7 OZ","price":"3.99","productCode":"0003010012977"},
        {"name":"CHIPS AHOY CHEWY HOT COCOA 9.9 OZ","price":"4.99","productCode":"0004400007815"},
        {"name":"CHEEZ-IT CHEESE CRACKERS12.4 OZ","price":"4.99","productCode":"0002410012418"},
        {"name":"RITZ HOLIDAY SHAPES 12.3 OZ","price":"5.79","productCode":"0004400007905"},
        {"name":"OREO SANDWICH COOKIES 8.5 OZ","price":"5.49","productCode":"0004400001473"},
        {"name":"RITZ FUDGE COVERED 7.8 OZ","price":"5.49","productCode":"0004400008042"},
        {"name":"TATE'S BAKE SHOP GINGERSNAP 6.5 OZ","price":"5.99","productCode":"0081029100797"},
        {"name":"SKITTLES POP'D SOUR STAND 5.5 OZ","price":"6.99","productCode":"0002200030128"},
        {"name":"SKITTLES POP'D ORIGINALS STAND 5.5 OZ","price":"6.99","productCode":"0002200030125"},
        {"name":"CHEEZ-IT CHEESE CRACKERS 12.4 OZ","price":"4.99","productCode":"0002410010685"},
        {"name":"PREMIUM SALTINE CRACKERS 16 OZ","price":"4.79","productCode":"0004400000057"},
        {"name":"WELCHS MIXED FRUIT 40 OZ","price":"9.99","productCode":"0003485684068"},
        {"name":"OREO CHOCOLATE COOKIES 18 OZ","price":"6.79","productCode":"0004400006023"},
        {"name":"CHIPS AHOY CHOCOLATE COOKIES 13 OZ","price":"4.99","productCode":"0004400003219"},
        {"name":"RUFFLES POTATO CHIPS 13 OZ","price":"6.49","productCode":"0002840051773"},
        {"name":"CHEETOS SNACKS CHEESE FLAVORED 15 OZ","price":"6.49","productCode":"0002840031409"},
        {"name":"RITZ ORIGINAL CRACKERS 13.7 OZ","price":"5.79","productCode":"0004400003111"},
        {"name":"HONEY MAID GRAHAM CRACKERS 14.4 OZ","price":"4.99","productCode":"0004400000463"},
        {"name":"LAYS POTATO CHIPS CLASSIC 13 OZ","price":"5.99","productCode":"0002840031041"},
        {"name":"TOWN HOUSE CRACKERS 13.8 OZ","price":"3.99","productCode":"0003010010055"},
        {"name":"NUTTER BUTTER SANDWICH COOKIES 16 OZ","price":"5.99","productCode":"0004400004631"},
        {"name":"STARRY CRANBERRY BLIZZ ZERO SUGAR 2 L","price":"2.79","productCode":"0001200024029"},
        {"name":"LA COLOMBE TRIPLE DRAFT LATTE 11 OZ","price":"2.61","productCode":"0060491300488"},
        {"name":"LA COLOMBE DRAFT LATTE VANILLA 11 OZ","price":"2.61","productCode":"0060491300490"},
        {"name":"BODYARMOR FRUIT PUNCH 12 OZ","price":"7.99","productCode":"0085817600280"},
        {"name":"GATORADE G SERIES THIRST QUENCHER 18 OZ","price":"16.15","productCode":"0005200012324"},
        {"name":"GATORADE PERFORM FRUIT PUNCH 12 OZ","price":"11.93","productCode":"0005200012196"},
        {"name":"GATORADE GLAZIER FREEZE 12 OZ","price":"11.93","productCode":"0005200012251"},
        {"name":"CELSIUS ENERGY DRINK 12 OZ","price":"23.99","productCode":"0088939200093"},
        {"name":"DIET COKE SODA POP COLA 12P 12 OZ","price":"10.99","productCode":"0004900002891"},
        {"name":"COCA-COLA SODA POP CLASSIC 12P 12 OZ","price":"12.43","productCode":"0004900002890"},
        {"name":"FOLGERS CLASSIC ROAST 33.7 OZ","price":"18.99","productCode":"0002550030418"},
        {"name":"SIGNATURE SELECT JUICE APPLE 64 OZ","price":"3.49","productCode":"0002113031008"},
        {"name":"SIGNATURE SELECT SODA CLUB 12 OZ","price":"4.50","productCode":"0002113029485"},
        {"name":"RED BULL ENERGY DRINK 12 OZ","price":"3.12","productCode":"0061126981899"},
        {"name":"TOPO CHICO MINERAL WATER 12P 12 OZ","price":"15.99","productCode":"0002113605046"},
        {"name":"PEPSI SODA COLA 12P 12 OZ","price":"12.43","productCode":"0001200080994"},
        {"name":"CRAISINS ORIGINAL  6 OZ","price":"2.99","productCode":"0003120000159"},
        {"name":"LAYS POTATO CHIPS  ORIGINAL 7.75 OZ","price":"4.99","productCode":"0002840004380"},
        {"name":"SUN-MAID RAISINS NATURAL CALIFORNIA 6 OZ","price":"3.49","productCode":"0004114312010"},
        {"name":"STACY'S BAKED PITA CHIPS 7.33 OZ","price":"5.99","productCode":"0002840056463"},
        {"name":"BLUE DIAMOND ALMONDS 6 OZ","price":"5.49","productCode":"0004157002970"},
        {"name":"O ORGANICS PECAN HALVES 10 OZ","price":"8.99","productCode":"0007989315826"},
        {"name":"FRITOS SCOOPS! CORN CHIPS 9.25 OZ","price":"5.79","productCode":"0002840058932"},
        {"name":"RITZ ORIGINAL CRACKERS 13.7 OZ","price":"5.79","productCode":"0004400003111"},
        {"name":"VICENZI LADYFINGERS 7 OZ","price":"3.99","productCode":"0070175068386"},
        {"name":"MARIANI APRICOTS MEDITERRANEAN 6 OZ","price":"3.50","productCode":"0007102214009"},
        {"name":"SMARTFOOD POPCORN CHEDDAR 6.75 OZ","price":"5.19","productCode":"0002840031401"},
        {"name":"CASA SANCHEZ MEDIUM ROJA SALSA 15 OZ","price":"5.99","productCode":"0007873200413"},
        {"name":"SIGNATURE CHERRIES DRIED 5 OZ","price":"3.99","productCode":"0002113052239"},
        {"name":"SIGNATURE PECAN PIECES 12 OZ","price":"6.99","productCode":"0002113012896"},
        {"name":"SNYDER'S OF HANOVER MINI PRETZELS 16 OZ","price":"4.49","productCode":"0007797508006"},
        {"name":"O ORGANIC FRUIT STRIPS STRAWBERRY 5 OZ","price":"3.99","productCode":"0007989312530"},
        {"name":"LOTUS COOKIES BISCOFF 8.8 OZ","price":"3.00","productCode":"0002178891629"},
        {"name":"CHEETOS CHEDDAR FLAVORED PUFFS 8 OZ","price":"4.99","productCode":"0002840007165"},
        {"name":"HELUVA GOOD! FRENCH ONION DIP 12 OZ","price":"1.99","productCode":"0007357013000"},
        {"name":"NILLA WAFERS COOKIES VANILLA 11 OZ","price":"5.49","productCode":"0004400002734"},
        {"name":"SIGNATURE APRICOTS FANCY DRIED 6 OZ","price":"2.99","productCode":"0002113052223"},
        {"name":"SUN-MAID RAISINS GOLDEN 12 OZ","price":"4.49","productCode":"0004114315160"},
        {"name":"NUTELLA BISCUITS T20 9.7 OZ","price":"5.99","productCode":"0000980083003"},
        {"name":"TOSTITOS SALSA CHUNKY MILD 15.5 OZ","price":"4.99","productCode":"0002840005597"},
        {"name":"FRITOS SCOOPS PARTY SIZE 15.5 OZ","price":"6.49","productCode":"0002840031403"},
        {"name":"TOOTSIE CHILDS PLAY 3.25 L","price":"13.99","productCode":"0007172001752"},
        {"name":"PLANTERS PEANUTS DRY ROASTED 16 OZ","price":"3.50","productCode":"0002900007325"},
        {"name":"FRITOS CORN CHIPS ORIGINAL 9.25 OZ","price":"5.79","productCode":"0002840058929"},
        {"name":"PREMIUM ORIGINAL SALTINE CRACKERS 16 OZ","price":"4.79","productCode":"0004400000057"},
        {"name":"SMARTFOOD POPCORN KETTLE CORN 7.75 OZ","price":"5.19","productCode":"0002840031400"},
        {"name":"SUN-MAID RAISINS SUN DRIED 32 OZ","price":"6.99","productCode":"0004114302917"},
        {"name":"SIM MILL COOK CR CC 5.5 OZ","price":"4.99","productCode":"0085606900519"},
        {"name":"DATES PITTED 32 OZ","price":"11.99","productCode":"0007102232119"},
        {"name":"CASA SANCHEZ MILD ROJA SALSA 15 OZ","price":"6.99","productCode":"0007873200400"},
        {"name":"PLANTERS MIXED NUTS 10.3 OZ","price":"5.99","productCode":"0002900001665"},
        {"name":"SMARTFOOD POPCORN CHEDDAR 6.25 OZ","price":"7.00","productCode":"0002840067909"},
        {"name":"OREO CHOCOLATE COOKIES 13.29 OZ","price":"4.99","productCode":"0004400006011"},
        {"name":"CHESTERS FRIES FLAMIN HOT BAG 5.25 OZ","price":"2.99","productCode":"0002840070569"},
        {"name":"GUACAMOLE SPICY 14 OZ","price":"5.99","productCode":"0081004159906"},
        {"name":"MARIANI PRUNES PROBIOTIC 7 OZ","price":"2.50","productCode":"0007102234011"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.59","productCode":"0077567254238"}],
      productIceData:[
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 10 CT","price":"7.99","productCode":"0007756760300"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 16 OZ","price":"3.19","productCode":"0007756725423"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.69","productCode":"0077567254238"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.69","productCode":"0077567254238"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.69","productCode":"0077567254238"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.69","productCode":"0077567254238"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.69","productCode":"0077567254238"}]

      /*
      {"name":"CLIF BAR CHOCOLATE SHIP ENERGY BAR 10 BARS","price":"11.49","productCode":"0072225216019"},
      {"name":"CLIF BAR CHOCOLATE SHIP ENERGY BAR 5 BARS","price":"6.49","productCode":"0072225216019"},
      {"name":"CLIF BAR CHOCOLATE SHIP ENERGY BAR 2.4 OZ","price":"1.99","productCode":"0072225216019"}
      * */
    }
  },
  components: {
  },
  watch: {
    // 监听 newOrderId 变量的变化
    newOrderId(newValue) {
      if (newValue) {
        // console.log('newOrderId 变化，启动定时任务');
        this.startTimer();  // 启动定时任务
      } else {
        this.stopTimer();   // 如果 newOrderId 为空，停止定时任务
      }
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  mounted() {
    this.gensFuncs();
  },
  methods: {
    pageRowsInput(event) {
      this.pageRows = event.target.value.slice(0, 3); // 限制为10位
    },
    handleInput(event) {
      this.card4No = event.target.value.slice(0, 4); // 限制为10位
    },

    printElement() {
      console.log("初始化")
      if (typeof getLodop === 'undefined') {
        console.error('LODOP未加载，请确保已正确引入 LodopFuncs.js');
        return;
      }

      for (let p = 1; p <= this.pageRows; p++) {
        this.gensFuncs();
        let LODOP = getLodop();
        if(LODOP){
          LODOP.PRINT_INIT('');
          LODOP.SET_PRINTER_INDEX(-1);
          LODOP.SET_PRINT_STYLE("FontName", "Arial");
          LODOP.SET_PRINT_STYLE("FontSize", 8);
          LODOP.ADD_PRINT_IMAGE(20,15,245,45,"<img src='/image/fredMeyer_logo.png'/>");
          LODOP.SET_PRINT_STYLEA(0,"Stretch",1);

          LODOP.ADD_PRINT_TEXT(100,20,240,80,"THANK YOU FOR CHOOSING FREDDY'S!");
          LODOP.SET_PRINT_STYLEA(0,"LetterSpacing",-0.5);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.SET_PRINT_STYLEA(2,"Bold",1);
          LODOP.ADD_PRINT_TEXT(130,50,300,80,this.tempAddress);
          LODOP.ADD_PRINT_TEXT(145,50,300,80,this.phone);
          LODOP.ADD_PRINT_TEXT(160,50,300,80,'YOUR CASHIER WAS  '+this.salesPerson);
          var iCurLine = 175;
          if(this.showProduceData.length>0)
            for (var i = 0; i < this.showProduceData.length; i++) {
              iCurLine = iCurLine+15;
              LODOP.ADD_PRINT_TEXT(iCurLine,20,300,80,this.showProduceData[i].name);
              iCurLine = iCurLine+15;
              LODOP.ADD_PRINT_TEXT(iCurLine,30,300,80,this.showProduceData[i].productCode);
              LODOP.ADD_PRINT_TEXT(iCurLine,220,300,80,this.showProduceData[i].price+" F");
            }
          LODOP.ADD_PRINT_TEXT(iCurLine+15,70,300,80,"TAX");
          LODOP.ADD_PRINT_TEXT(iCurLine+15,221,300,80,this.taxPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+30,55,300,80,"****BALANCE");
          LODOP.ADD_PRINT_TEXT(iCurLine+30,220,300,80,this.totalPrice);
          LODOP.SET_PRINT_STYLEA(this.iptRows*3+6,"Bold",1);
          LODOP.SET_PRINT_STYLEA(this.iptRows*3+7,"Bold",1);
          LODOP.SET_PRINT_STYLEA(this.iptRows*3+8,"Bold",1);
          LODOP.SET_PRINT_STYLEA(this.iptRows*3+9,"Bold",1);

          LODOP.ADD_PRINT_TEXT(iCurLine+45,20,300,80,"************ "+this.card4No);
          LODOP.ADD_PRINT_TEXT(iCurLine+60,20,300,80,"REF#: "+this.seqNo);
          LODOP.ADD_PRINT_TEXT(iCurLine+75,20,300,80,"PURCHASE: "+this.totalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+90,20,300,80,"CASHBACK: 0.00");
          LODOP.ADD_PRINT_TEXT(iCurLine+105,20,300,80,"TOTAL: "+this.totalPrice);

          LODOP.ADD_PRINT_TEXT(iCurLine+135,70,300,80,"DEBIT");
          LODOP.ADD_PRINT_TEXT(iCurLine+135,220,300,80,this.totalPrice);
          LODOP.SET_PRINT_STYLEA(this.iptRows*3+15,"Bold",1);
          LODOP.SET_PRINT_STYLEA(this.iptRows*3+16,"Bold",1);
          LODOP.ADD_PRINT_TEXT(iCurLine+150,70,300,80,"CHANGE");
          LODOP.ADD_PRINT_TEXT(iCurLine+150,220,300,80,"0.00");
          LODOP.ADD_PRINT_TEXT(iCurLine+165,20,300,80,"TOTAL NUMBER OF ITEMS SOLD =        "+this.iptRows);
          LODOP.ADD_PRINT_TEXT(iCurLine+180,20,300,80,this.orderDate+"   "+this.codeFrist+"  "+this.codeSecend+"   "+this.codeThird+"    "+this.storeNo);

          LODOP.ADD_PRINT_TEXT(iCurLine+225,20,300,80,"**********************************************************");
          LODOP.SET_PRINT_STYLE("FontSize", 9.5);
          LODOP.ADD_PRINT_TEXT(iCurLine+235,20,240,80,"Tell Us How We Are Doing!");
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.SET_PRINT_STYLE("FontSize", 8);
          LODOP.ADD_PRINT_TEXT(iCurLine+250,28,240,80,"Earn 50 BOUNS FUEL POINTS!");
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.PRINT();
          //LODOP.PREVIEW();

          setTimeout(function() {
          }, 1000);
        }else{
          console.log("LODOP 无法获取")
        }
      }
    },

    gensFuncs(){
      this.showProduceData = [];
      this.codeFrist = Math.floor(Math.random() * (400 - 250 + 1) + 250);
      this.codeSecend = Math.floor(Math.random() * (9 - 1 + 1) + 1);
      this.codeThird= Math.floor(Math.random() * (99 - 20 + 1) + 20);

      this.orderDate = this.getRandomDateTime();
      var index = Math.floor(Math.random() * this.storeData.length);
      this.storeNo =  this.storeData[index].storeNo;

      if(this.storeNo.length>=7){
      }else{
        this.storeNo  =   ("1111111"+String(this.storeNo)).substr(-7)
      }
      this.address =  this.storeData[index].address;
      this.salesPerson = this.storeData[index].salesPerson;
      if(this.address.length>25){
        this.address = this.address.substring(0, 25);
      }
      this.tempAddress =  this.address;
      this.phone =  this.storeData[index].phone;
      this.city =  this.storeData[index].city;

      this.iptRows = Math.floor(Math.random() * (18 - 12 + 1)) + 12;
      this.iceRows = Math.floor(Math.random() * (12 - 6 + 1)) + 6;

      this.iptRowsFull = this.iptRows < 10 ? '0' + this.iptRows : this.iptRows.toString();
      console.log("iptRows:"+this.iptRows)
      console.log("iceRows:"+this.iceRows)
      var difference = 0;
      var startCount = 0;
      var endCount = 0;
      difference =  this.iptRows - this.iceRows;
      var pLength =  this.productData.length
      var pIceLeng = this.productIceData.length
      console.log("difference:"+difference)
      var tempPrice = 0.00;
      if(difference>0){
        startCount  =  Math.floor(Math.random() * (difference - 1 + 1)) + 1;
        console.log("startCount:"+startCount)
        if(startCount == 1){
          for (let i = 1; i <= difference; i++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }
          for(let j = 1; j <= this.iceRows; j++) {
            var pIcaIndex = Math.floor(Math.random() * pIceLeng);
            this.showProduceData.push(this.productIceData[pIcaIndex])
            tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
          }
        }else if(startCount == difference){
          for(let j = 1; j <= this.iceRows; j++) {
            var pIcaIndex = Math.floor(Math.random() * pIceLeng);
            this.showProduceData.push(this.productIceData[pIcaIndex])
            tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
          }
          for (let i = 1; i <= difference; i++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }
        }else{
          endCount = difference - startCount;

          for(let y = 1; y <= startCount; y++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }

          for(let j = 1; j <= this.iceRows; j++) {
            var pIcaIndex = Math.floor(Math.random() * pIceLeng);
            this.showProduceData.push(this.productIceData[pIcaIndex])
            tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
          }

          for (let i = 1; i <= endCount; i++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }

        }

      }else{
        for(let j = 1; j <= this.iceRows; j++) {
          var pIcaIndex = Math.floor(Math.random() * pIceLeng);
          this.showProduceData.push(this.productIceData[pIcaIndex])
          tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
        }
      }
      tempPrice = parseFloat(tempPrice).toFixed(2);

      //单号
      this.seq4No = Math.floor(Math.random() * (6000000000 - 5000000000 + 1) + 5000000000);
      let num2 = Math.floor(Math.random() * (99 - 11 + 1) + 11);
      this.seqNo = this.seq4No+""+num2;

      this.auth = Math.floor(Math.random() * (80000 - 70000 + 1) + 70000);
      //条形码
      let barcodeFrist ="99";
      let barcodeSecond = Math.random().toString().slice(2, 7);
      this.barcodeValue = barcodeFrist+''+this.storeNo+""+num2+""+this.seq4No+""+this.iptRowsFull+""+barcodeSecond;
      //信用卡后四位
      /*var myArray =[1972,1191,1614,1785,1798,1516,1077,1772,1892,1379,1764,1621,1342,1397,1589,1568,1074,1722,1111,1651,1986,1212,1494,1859,1633,1601,1496,1277,1945,1552,1030,1153,1244,1708,1549,1344,1038,1086,1336,1814,1635,1484,1820,1178,1490,1808,1511,1163,1717,1324,1398,1261,1940,1833,1522,1681,1269,1775,1310,1490,1891,1759,1288,1372,1168,1572,1409,1863,1879,1167,1467,1385,1083,1058,1918,1313,1117,1292,1351,1423,1910,1283,1773,1578,1122,1024,1297,1810,1941,1412,1081,1499,1117,1345,1593,1939,1971,1267,1552,1258];
      let myIndex = Math.floor(Math.random() * myArray.length);
      this.card4No = myArray[myIndex];*/

      this.code7No = Math.random().toString().slice(2, 9);
      //打印机编号后5位
      this.aId5No = Math.floor(Math.random() * (926150 - 925160 + 1)) + 925160;
      //日期
      this.orderDate = this.getRandomDateTime();
      //行数随机
      let num13 = this.generateRandomString(13);
      this.issuer = this.iptRows+""+num13;
      this.genRows= parseInt(this.iptRows);
      this.subTotalPrice = tempPrice;
      this.taxPrice = parseFloat(tempPrice * this.tax10).toFixed(2);
      this.totalPrice = parseFloat(this.subTotalPrice) + parseFloat(this.taxPrice);
      this.totalPrice = parseFloat(this.totalPrice).toFixed(2);
      this.showIptRows = this.iptRows < 10 ? this.iptRows.toString()+'\xa0'+'\xa0' : this.iptRows.toString();

    },
    generateRandomString(num) {
      let digitsCount = 0;
      let result = '';

      while (result.length < num) {
        const randomChar = Math.random() >= 0.5 ? this.getRandomChar() : this.getRandomDigit();
        result += randomChar;
        if (randomChar >= '0' && randomChar <= '9') {
          digitsCount++;
        }
      }
      // 确保至少有4位数字
      while (digitsCount < 4) {
        const randomIndex = Math.floor(Math.random() * result.length);
        const charAtIndex = result.charAt(randomIndex);
        if (charAtIndex >= '0' && charAtIndex <= '9') {
          result = result.slice(0, randomIndex) + this.getRandomDigit() + result.slice(randomIndex);
          digitsCount++;
        }
      }
      return result;
    },
    getRandomChar() {
      return String.fromCharCode(65 + Math.floor(Math.random() * 26)); // A-Z
    },
    getRandomDigit() {
      return String.fromCharCode(48 + Math.floor(Math.random() * 10)); // 0-9
    },

    getRandomDateTime() {
      const startYear = 2016;
      const endYear = 2024;
      const startTime = new Date(startYear, 4, 21); // 2016年的4月21日
      const diff = new Date(endYear, 7, 13) - startTime; // 2024年的7月1日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      var year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }

      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      var  tempHours =  Math.floor(Math.random() * (19 - 12 + 1)) + 12;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');
      const seconds = String(randomDate.getSeconds()).padStart(2, '0');
      var tempYear = year.toString();

      var str;
      if(tempHours>12) {
        tempHours -= 12;
        str = " PM";
      }else{
        str = " AM";
      }
      tempHours = tempHours < 10 ? "0" + tempHours:tempHours;
      return `${month}/${day}/${tempYear.substring(tempYear.length-2,tempYear.length)} ${tempHours}:${minutes}${str}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },

    // 判断设备是否为移动端
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 使用正则检查 userAgent 中是否包含手机/平板的关键字
      return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile|webos.*mobile/i.test(userAgent);
    },
    isMobileBrowerSize() {
      return window.innerWidth <= 768;  // 视窗宽度小于等于 768px 视为移动端
    },

  },
  beforeDestroy() {

  },
  created() {

  }
}
</script>

<style scoped>
@media print {
  body {
    font-family: "Times New Roman", Times, serif;
  }
}
</style>