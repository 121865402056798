npm <template>

  <div style="height: 100vh">
    <div style="padding-left: 15px;font-size: 18px">
      <p>张数 ：<input v-model="pageRows"  style="width: 80px" @input="pageRowsInput" type="number"><button @click ="printElement()" style="margin-left:10px;width: 180px;background-color: #096c0b; height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">Lodop打印</button></p>
    </div>
    <div  style="background-color: #4AB7BD;height: auto;padding-top: 20px;">
      <div id="printContent" style="-webkit-font-smoothing:antialiased;font-family: 'Times New Roman', Times, serif;font-weight: lighter">
        <div style="padding-bottom:70px;line-height:6px;text-align: center;width: 300px; font-size: 12px; background-color: #FFFFFF; margin: auto">
          <p style="padding-top: 10px;padding-left: 10px;"><img src='/image/walmart-logo.png' width="200px"/></p>
          <div style="text-align: left; margin-top: 10px">
            <p class="container" style="padding-left: 10px; text-align: center;" ><span style="display: inline-block; text-align: right; " v-html="phone+ '&nbsp;'.repeat(5*1)+'Mgr: '+manager"></span></p>
            <p style="padding-left: 10px;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="address"></span></p>
            <p style="padding-left: 10px;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="city +'&nbsp;'.repeat(2*2) +zip"></span></p>
            <p style="padding-left: 15px;" v-html="'ST#'+'&nbsp;'.repeat(2) +storeNo+ '&nbsp;'.repeat(3)+'OP#'+'&nbsp;'.repeat(2) +codeFrist+ '&nbsp;'.repeat(3)+'TE#'+'&nbsp;'.repeat(2) +codeSecend+ '&nbsp;'.repeat(3)  +'TR#'+'&nbsp;'.repeat(3) +codeThird"></p>

            <div v-if="showProduceData" v-for="(item, index) in showProduceData" :key="index">
              <p class="container2" style="padding-left: 15px;"><span style="display: inline-block;width: 45%; text-align: left; word-spacing: 5px;" v-html="item.name"/>
                <span style="display: inline-block;width: 30%; text-align: left; " v-html="item.productCode"/>
                <span style="display: inline-block;width: 20%; text-align: left;" v-html="item.price+'&nbsp;'.repeat(3)+'X'"/></p>
            </div>
            <p style="padding-left: 120px;" v-html="'SUBTOTAL'+ '&nbsp;'.repeat(16*1) +subTotalPrice"></p>
            <p style="padding-left: 60px;" v-html="'TAX '+ '&nbsp;'.repeat(5) +'1'+ '&nbsp;'.repeat(8)+'7.000'+ '&nbsp;'.repeat(2)+'%'+ '&nbsp;'.repeat(18) +taxPrice"></p>
            <p style="padding-left: 140px;" v-html="'TOTAL'+ '&nbsp;'.repeat(17*1) +totalPrice"></p>
            <p style="padding-left: 95px;" v-html="'DEBIT'+ '&nbsp;'.repeat(5) +'TENT'+ '&nbsp;'.repeat(17*1) +totalPrice"></p>
            <p style="padding-left: 87px;" v-html="'CHANGE'+ '&nbsp;'.repeat(5) +'DUE'+ '&nbsp;'.repeat(17*1) +'0.00'"></p>
            <p style="padding-left: 15px;" v-html="'EFT'+'&nbsp;'.repeat(2)+'DEBIT'+ '&nbsp;'.repeat(10) +'PAY'+ '&nbsp;'.repeat(2)+'FROM'+ '&nbsp;'.repeat(2) +'PRINSRY'"></p>
            <p style="padding-left: 25px;" v-html="totalPrice+'&nbsp;'.repeat(5) +'TOTAL'+ '&nbsp;'.repeat(3) +'PURCHASE'"></p>
            <p style="padding-left: 15px;" v-html="'US'+ '&nbsp;'.repeat(2) +'DEBIT'+'&nbsp;'.repeat(15) +'****'+'&nbsp;'.repeat(3)+'****'+'&nbsp;'.repeat(3)+'****'+'&nbsp;'.repeat(3)+card4No"></p>
            <p style="padding-left: 15px;" v-html="'REF'+'&nbsp;'.repeat(3) +'#'+ '&nbsp;'.repeat(3) +refNo"></p>
            <p style="padding-left: 15px;" v-html="'US'+ '&nbsp;'.repeat(2) +'DEBIT'"></p>
            <p style="padding-left: 15px;" v-html="'AID'+ '&nbsp;'.repeat(2) +'A00000000' +aId5No"></p>
            <p style="padding-left: 15px;" v-html="'TERNINAL'+'&nbsp;'.repeat(3) +'#'+ '&nbsp;'.repeat(2) +issuer"></p>
            <p style="padding-left: 15px; padding-top:6px;font-size: 16px; text-align: center;"><span style="display: inline-block; text-align: center; " v-html="'#'+ '&nbsp;'.repeat(4) +'ITEMS'+ '&nbsp;'.repeat(4) +'SOLD'+'&nbsp;'.repeat(8) +iptRows"></span></p>
            <p style="padding-top:6px; text-align: center; " v-html="'TC#'+'&nbsp;'.repeat(2)+barcodeValue"></p>
            <p style="text-align: center;margin-top: -10px"  > <svg ref="barcode"></svg></p>
            <p style="padding-left: 20px; margin-top: -10px; word-spacing: 8px;" v-html="'Low Prices You Can Trust. Every Day.'"></p>
            <p style="padding-left: 10px;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="orderTempDate+'&nbsp;'.repeat(15) +orderDate"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>
<script>
import {initApkHub, newestApkUrl, queryApkHub} from "@/api/index";
import QRCode from 'qrcode'
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';
import { Circle } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';
import { Image as VanImage } from 'vant';
import { Card } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { Notify } from 'vant';
import JsBarcode from 'jsbarcode'
Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
  data () {
    return {
      storeNo:'',
      phone:'',
      manager:'',
      city:'',
      address:'',
      tempAddress:'',
      seq4No:'',
      issuer:'',
      iptRowsFull:'',
      pageRows:1,
      iptRows:3,
      showIptRows:'',
      itemPrice:5.50,
      tax10:0.07,
      taxPrice:0.0,
      totalPrice:0.0,
      subTotalPrice:0.0,
      genRows:3,
      card4No:9311,
      seqNo:304740,
      barcodeValue:'9900300230130764390',
      orderDate:'',
      orderTempDate:'',
      aId5No:925650,
      code6No:1911123,
      refNo:5454654545,
      codeFrist:'258',
      codeSecend:'4658',
      codeThird:'0022',
      showProduceData:[{"name":"BREYERS","price":"5.50","productCode":"077567254238"}],
      iceRows:0,
      zip:92565,
      storeData:[
          {"city":"DOWNEY CA","phone":"562-622-4891","address":"12270 PARAMOUNT BLVD","postalCode":"90242","storeNo":"05603","manager":"FREEMAN MARK"},
        {"city":"PICO RIVERA CA","phone":"562-801-2413","address":"8500 WASHINGTON BLVD","postalCode":"90660","storeNo":"02886","manager":"EDDY M CALZADO"},
        {"city":"DOWNEY CA","phone":"562-803-3507","address":"9001 APOLLO WAY","postalCode":"90242","storeNo":"04132","manager":"NORMAN NOBLE"},
        {"city":"HAWTHORNE CA","phone":"424-336-2212","address":"14441 INGLEWOOD AVE","postalCode":"90250","storeNo":"05604","manager":"AMANDA CARPENTER"},
        {"city":"PARAMOUNT CA","phone":"562-531-8240","address":"14501 LAKEWOOD BLVD","postalCode":"90723","storeNo":"02110","manager":"NATHANIEL GOLSON"},
        {"city":"ROSEMEAD CA","phone":"626-307-1010","address":"1827 WALNUT GROVE AVE","postalCode":"91770","storeNo":"05154","manager":"MARK WATKINS"},
        {"city":"TORRANCE CA","phone":"310-782-6022","address":"19503 NORMANDIE AVE","postalCode":"90501","storeNo":"05072","manager":"BRITTANY CORLISS"},
        {"city":"CARSON CA","phone":"424-233-3317","address":"20226 AVALON BLVD","postalCode":"90746","storeNo":"05602","manager":"TERESA DOTSON"},
        {"city":"TORRANCE CA","phone":"310-750-1983","address":"19340 HAWTHORNE BLVD","postalCode":"90503","storeNo":"02226","manager":"BLANCA GUERRERO"},
        {"city":"LONG BEACH CA","phone":"424-296-6525","address":"3705 E SOUTH ST","postalCode":"90805","storeNo":"04101","manager":"COX STEVEN"},
        {"city":"SANTA FE SPRINGS CA","phone":"562-946-6343","address":"13310 TELEGRAPH RD","postalCode":"90670","storeNo":"02948","manager":"BRENDA WEBSTER"},
        {"city":"BURBANK CA","phone":"747-261-7243","address":"1301 N VICTORY PL","postalCode":"91502","storeNo":"05686","manager":"KARIM BERRABEH"},
        {"city":"LAKEWOOD CA","phone":"562-429-6239","address":"2770 CARSON ST","postalCode":"90712","storeNo":"02609","manager":"SHAYLA SOREL"},
        {"city":"TORRANCE CA","phone":"310-750-0179","address":"22015 HAWTHORNE BLVD","postalCode":"90503","storeNo":"05874","manager":"TARA BONGIARDINA"},
        {"city":"CERRITOS CA","phone":"562-924-1468","address":"12701 TOWNE CENTER DR","postalCode":"90703","storeNo":"02082","manager":"CORY HARROD"},
        {"city":"LA MIRADA CA","phone":"562-567-6488","address":"14865 TELEGRAPH ROAD","postalCode":"90638","storeNo":"05661","manager":"BIRTIE PACK"},
        {"city":"LONG BEACH CA","phone":"562-425-5113","address":"7250 CARSON BLVD","postalCode":"90808","storeNo":"03477","manager":"LESLIE SEDILLO"},
        {"city":"LA PALMA CA","phone":"714-822-3152","address":"5420 LA PALMA AVE","postalCode":"90623","storeNo":"03099","manager":"JERROD CRIDER"},
        {"city":"BALDWIN PARK CA","phone":"626-814-2235","address":"3250 BIG DALTON AVE","postalCode":"91706","storeNo":"03522","manager":"FRANK PRICE"},
        {"city":"LA PUENTE CA","phone":"626-251-1877","address":"1425 N HACIENDA BLVD","postalCode":"91744","storeNo":"03133","manager":"ORLANDO PEMBERTON"},
        {"city":"LA HABRA CA","phone":"562-694-2707","address":"1340 S BEACH BLVD","postalCode":"90631","storeNo":"03248","manager":"DONNA SHIFFLETT"},
        {"city":"DUARTE CA","phone":"626-359-7708","address":"1600 MOUNTAIN AVE","postalCode":"91010","storeNo":"02401","manager":"NIKIA WATLEY"},
        {"city":"PANORAMA CITY CA","phone":"818-830-0350","address":"8333 VAN NUYS BLVD","postalCode":"91402","storeNo":"02568","manager":"WELDON HATHAWAY"},
        {"city":"CITY OF INDUSTRY CA","phone":"626-854-1166","address":"17150 GALE AVE","postalCode":"91745","storeNo":"02251","manager":"GRACIEUSE PIERRE"},
        {"city":"PANORAMA CITY CA","phone":"818-672-0142","address":"14530 NORDHOFF ST","postalCode":"91402","storeNo":"05605","manager":"CYRIA JARAMILLO"},
        {"city":"BUENA PARK CA","phone":"714-484-8668","address":"8450 LA PALMA AVE","postalCode":"90620","storeNo":"05032","manager":"STEPHANIE WATTELL"},
        {"city":"ANAHEIM CA","phone":"714-822-3179","address":"121 N BEACH BLVD","postalCode":"92801","storeNo":"03101","manager":"SHANNON HULL"},
        {"city":"LA HABRA CA","phone":"714-869-0530","address":"1000 E IMPERIAL HWY","postalCode":"90631","storeNo":"05641","manager":"AIDA PALOMINN"},
        {"city":"COVINA CA","phone":"626-339-4161","address":"1275 N AZUSA AVE","postalCode":"91722","storeNo":"02292","manager":"MATTHEW SULLIVAN"},
        {"city":"ANAHEIM CA","phone":"714-491-0744","address":"440 N EUCLID ST","postalCode":"92801","storeNo":"02242","manager":"KEITH BEDFORD"},
        {"city":"ANAHEIM CA","phone":"714-447-6751","address":"88 E ORANGETHORPE AVE","postalCode":"92801","storeNo":"05930","manager":"HOROLYN JONES"},
        {"city":"STANTON CA","phone":"714-230-0126","address":"12840 BEACH BLVD","postalCode":"90680","storeNo":"04134","manager":"JAMES MERAZ"},
        {"city":"WESTMINSTER CA","phone":"714-799-0020","address":"13331 BEACH BLVD","postalCode":"92683","storeNo":"02495","manager":"AIMEE ADAMS"},
        {"city":"GARDEN GROVE CA","phone":"714-591-1300","address":"11822 GILBERT ST","postalCode":"92841","storeNo":"04171","manager":"SHIRLEY JONES"},
        {"city":"BREA CA","phone":"714-529-0596","address":"2595 E IMPERIAL HWY","postalCode":"92821","storeNo":"02523","manager":"BEVERLY RODRIGUEZ"},
        {"city":"ANAHEIM CA","phone":"657-208-5201","address":"1120 S ANAHEIM BLVD","postalCode":"92805","storeNo":"05640","manager":"DOTY VICTORIA"},
        {"city":"WEST HILLS CA","phone":"818-719-8602","address":"6433 FALLBROOK AVE","postalCode":"91307","storeNo":"05152","manager":"SANDRA MILLER"},
        {"city":"PORTER RANCH CA","phone":"818-832-0643","address":"19821 RINALDI ST","postalCode":"91326","storeNo":"02526","manager":"SABRENA JANUARY"},
        {"city":"DIAMOND BAR CA","phone":"909-378-4055","address":"1180 S DIAMOND BAR BLVD","postalCode":"91765","storeNo":"04762","manager":"MOON COLBY"},
        {"city":"GLENDORA CA","phone":"909-592-4866","address":"1950 AUTO CENTER DRIVE","postalCode":"91740","storeNo":"01941","manager":"TIFFANEY NEWMA"},
        {"city":"HUNTINGTON BEACH CA","phone":"714-841-5390","address":"8230 TALBERT AVE","postalCode":"92646","storeNo":"02636","manager":"ROBERT GRANBERRY"},
        {"city":"ORANGE CA","phone":"714-998-4473","address":"2300 N TUSTIN ST","postalCode":"92865","storeNo":"02546","manager":"MORGAN FYFE"},
        {"city":"SANTA ANA CA","phone":"714-775-1804","address":"3600 W MCFADDEN AVE","postalCode":"92704","storeNo":"02517","manager":"ERVIN HOOKS"},
        {"city":"POMONA CA","phone":"909-620-4602","address":"80 RIO RANCHO RD","postalCode":"91766","storeNo":"02288","manager":"LORENCE ROBINSON"},
        {"city":"HUNTINGTON BEACH CA","phone":"714-274-4484","address":"21132 BEACH BLVD","postalCode":"92648","storeNo":"05601","manager":"VICTORIA LUJAN"},
        {"city":"POMONA CA","phone":"909-962-5011","address":"778 E ARROW HWY","postalCode":"91767","storeNo":"03132","manager":"LAUREN OTIS"},
        {"city":"CHINO CA","phone":"909-364-1968","address":"3943 GRAND AVE","postalCode":"91710","storeNo":"03464","manager":"TINA MICHELIN"},
        {"city":"SANTA CLARITA CA","phone":"661-259-0863","address":"26471 CARL BOYER DR","postalCode":"91350","storeNo":"03523","manager":"MONSERRAT SOLIS"},
        {"city":"AURORA CO","phone":"720-374-0278","address":"3301 TOWER RD","postalCode":"80011","storeNo":"05334","manager":"KIMBERLEY BOOKER"},
        {"city":"AURORA CO","phone":"303-368-1115","address":"14000 E EXPOSITION AVE","postalCode":"80012","storeNo":"01492","manager":"MONICA COLON"},
        {"city":"DENVER CO","phone":"303-218-6237","address":"5141 CHAMBERS RD","postalCode":"80239","storeNo":"03127","manager":"ROBERTO GRIEGO"},
        {"city":"AURORA CO","phone":"303-481-3904","address":"15240 E ILIFF AVE","postalCode":"80014","storeNo":"04196","manager":"ALISA BALDWIN"},
        {"city":"DENVER CO","phone":"720-941-0411","address":"7800 SMITH RD","postalCode":"80207","storeNo":"03533","manager":"STEPHANIE WAY"},
        {"city":"DENVER CO","phone":"303-481-0182","address":"1442 S PARKER RD","postalCode":"80231","storeNo":"03128","manager":"JOSEPH MURDOCK"},
        {"city":"DENVER CO","phone":"720-748-1000","address":"9400 E HAMPDEN AVE","postalCode":"80231","storeNo":"03566","manager":"SAMANTHA HARRIS"},
        {"city":"COMMERCE CITY CO","phone":"303-287-0600","address":"5990 DAHLIA ST","postalCode":"80022","storeNo":"02752","manager":"LINDA KOHL"},
        {"city":"AURORA CO","phone":"303-248-4048","address":"21475 E QUINCY AVE","postalCode":"80015","storeNo":"03740","manager":"RHONDA COOK"},
        {"city":"CENTENNIAL CO","phone":"303-305-1110","address":"16746 E SMOKY HILL RD","postalCode":"80015","storeNo":"03126","manager":"FLORA BILELLO"},
        {"city":"AURORA CO","phone":"303-690-6322","address":"5650 S CHAMBERS RD","postalCode":"80015","storeNo":"01689","manager":"STEPHANIE CHIA"},
        {"city":"AURORA CO","phone":"303-617-1261","address":"6101 S AURORA PKWY","postalCode":"80016","storeNo":"05137","manager":"ASHLEY SULLIVAN"},
        {"city":"CENTENNIAL CO","phone":"303-706-0071","address":"10900 E BRIARWOOD AVE","postalCode":"80112","storeNo":"03313","manager":"MICHAEL UNZUETA"},
        {"city":"ENGLEWOOD CO","phone":"303-789-7201","address":"601 ENGLEWOOD PKWY","postalCode":"80110","storeNo":"02751","manager":"KEVIN GILL"},
        {"city":"THORNTON CO","phone":"303-287-4404","address":"850 E 88TH AVE","postalCode":"80229","storeNo":"03019","manager":"THERESA STULTZ"},
        {"city":"DENVER CO","phone":"303-222-7043","address":"2770 WEST EVANS AVENUE","postalCode":"80219","storeNo":"05676","manager":"SCOTT EDWARDS"},
        {"city":"THORNTON CO","phone":"303-451-1003","address":"9901 GRANT ST","postalCode":"80229","storeNo":"01231","manager":"HESTER TERESA"},
        {"city":"HIGHLANDS RANCH CO","phone":"303-683-3641","address":"6675 BUSINESS CENTER DR","postalCode":"80130","storeNo":"01252","manager":"JUSTIN HORN"},
        {"city":"LAKESIDE CO","phone":"303-222-4455","address":"5957 W 44TH AVE","postalCode":"80212","storeNo":"05957","manager":"RENEE TUTTLE"},
        {"city":"NORTHGLENN CO","phone":"303-200-1490","address":"10755 WASHINGTON STREET","postalCode":"80233","storeNo":"04747","manager":"BRIDGETT MCKIVER"},
        {"city":"BRIGHTON CO","phone":"303-209-4339","address":"7101 E 128TH AVE","postalCode":"80602","storeNo":"04567","manager":"AMY CRIDER"},
        {"city":"WESTMINSTER CO","phone":"303-487-1412","address":"7155 SHERIDAN BLVD","postalCode":"80003","storeNo":"03824","manager":"MADDOX ADRIAN"},
        {"city":"LAKEWOOD CO","phone":"303-274-5211","address":"7455 W COLFAX AVE","postalCode":"80214","storeNo":"02125","manager":"LINDA GARDNER"},
        {"city":"LAKEWOOD CO","phone":"303-205-0754","address":"440 WADSWORTH BLVD","postalCode":"80226","storeNo":"04284","manager":"SASHA GORIN"},
        {"city":"BRIGHTON CO","phone":"303-659-1560","address":"60 W BROMLEY LN","postalCode":"80601","storeNo":"01659","manager":"REBA GODAT"},
        {"city":"PARKER CO","phone":"303-805-0029","address":"11101 S PARKER RD","postalCode":"80134","storeNo":"02892","manager":"MICHAEL BOURG"},
        {"city":"WESTMINSTER CO","phone":"303-427-4882","address":"9499 SHERIDAN BLVD","postalCode":"80031","storeNo":"02223","manager":"ASIA UPTON"},
        {"city":"ARVADA CO","phone":"720-624-0346","address":"9400 RALSTON ROAD","postalCode":"80002","storeNo":"04734","manager":"THOMAS HARTLEY"},
        {"city":"LITTLETON CO","phone":"303-971-0321","address":"7700 W QUINCY AVE","postalCode":"80123","storeNo":"01308","manager":"CONQUEST KNIQUEA"},
        {"city":"WESTMINSTER CO","phone":"720-929-1758","address":"200 W 136TH AVE","postalCode":"80234","storeNo":"03867","manager":"ISHEBA CAMERON"},
        {"city":"BROOMFIELD CO","phone":"303-217-9374","address":"4651 W 121ST AVE","postalCode":"80020","storeNo":"04288","manager":"RHONDA COLEMAN"},
        {"city":"LITTLETON CO","phone":"303-979-2064","address":"13420 W COAL MINE AVE","postalCode":"80127","storeNo":"05049","manager":"RITA RODRIGUEZ"},
        {"city":"BROOMFIELD CO","phone":"303-466-3928","address":"500 SUMMIT BLVD","postalCode":"80021","storeNo":"05341","manager":"MURRAY TIMOTHY"},
        {"city":"CASTLE ROCK CO","phone":"303-688-8200","address":"133 SAM WALTON LANE","postalCode":"80104","storeNo":"00984","manager":"DEBRA NELSON"},
        {"city":"LAFAYETTE CO","phone":"303-666-0340","address":"745 US HIGHWAY 287","postalCode":"80026","storeNo":"01045","manager":"GORDON MAYS"},
        {"city":"EVERGREEN CO","phone":"303-526-1649","address":"952 SWEDE GULCH RD","postalCode":"80439","storeNo":"02293","manager":"RUTH LEGALL"},
        {"city":"ELIZABETH CO","phone":"303-646-2599","address":"2100 LEGACY CIR","postalCode":"80107","storeNo":"04639","manager":"MAURICE RIDDLE"},
        {"city":"LONGMONT CO","phone":"303-678-0803","address":"2285 E KEN PRATT BLVD","postalCode":"80504","storeNo":"00905","manager":"KEITH MCKOY"},
        {"city":"LONGMONT CO","phone":"303-774-7513","address":"2514 MAIN ST","postalCode":"80504","storeNo":"05370","manager":"STEPHANIE STEVENS"},
        {"city":"GREELEY CO","phone":"970-330-1452","address":"3103 S 23RD AVE","postalCode":"80631","storeNo":"00980","manager":"YVONNE GREGOIRE"},
        {"city":"MONUMENT CO","phone":"719-484-0912","address":"16218 JACKSON CREEK PKWY","postalCode":"80132","storeNo":"03227","manager":"LILIANA RAMIREZ"},
        {"city":"GREELEY CO","phone":"970-353-4231","address":"920 47TH AVE","postalCode":"80634","storeNo":"05051","manager":"JAPERA LAWTON"},
        {"city":"LOVELAND CO","phone":"970-669-4579","address":"1325 DENVER AVE","postalCode":"80537","storeNo":"00953","manager":"JOHNNY HIMES"},
        {"city":"HARAHAN LA","phone":"504-733-4923","address":"5110 JEFFERSON HWY","postalCode":"70123","storeNo":"01353","manager":"VANESSA POPE"},
        {"city":"METAIRIE LA","phone":"504-465-0155","address":"8912 VETERANS MEMORIAL BLVD","postalCode":"70003","storeNo":"00989","manager":"MICHAEL TIJERINA"},
        {"city":"KENNER LA","phone":"504-466-6882","address":"3520 WILLIAMS BLVD","postalCode":"70065","storeNo":"03703","manager":"LEANN CARTER"},
        {"city":"KENNER LA","phone":"504-464-1653","address":"300 W ESPLANADE AVE","postalCode":"70065","storeNo":"01342","manager":"TAMARA STAFFORD"},
        {"city":"NEW ORLEANS LA","phone":"504-522-4142","address":"1901 TCHOUPITOULAS ST","postalCode":"70130","storeNo":"05022","manager":"BELINDA MCDANIEL"},
        {"city":"NEW ORLEANS LA","phone":"504-434-6076","address":"4301 CHEF MENTEUR HWY","postalCode":"70126","storeNo":"03167","manager":"AMY KOSS"},
        {"city":"GRETNA LA","phone":"504-249-7148","address":"99 WESTBANK EXPY","postalCode":"70053","storeNo":"05102","manager":"PAMELA ANDREYKO"},
        {"city":"MARRERO LA","phone":"504-341-0075","address":"4810 LAPALCO BLVD","postalCode":"70072","storeNo":"00911","manager":"PEGGY LLOYD"},
        {"city":"HARVEY LA","phone":"504-366-5255","address":"1501 MANHATTAN BLVD","postalCode":"70058","storeNo":"02706","manager":"KAITLYN BETO"},
        {"city":"NEW ORLEANS LA","phone":"504-364-0414","address":"4001 BEHRMAN PL","postalCode":"70114","storeNo":"01163","manager":"DOUGLAS BANKS"},
        {"city":"CHALMETTE LA","phone":"504-278-2331","address":"8101 W JUDGE PEREZ DR","postalCode":"70043","storeNo":"00909","manager":"LISA MILLER"},
        {"city":"HARVEY LA","phone":"504-366-1404","address":"3265 MANHATTAN BLVD","postalCode":"70058","storeNo":"05722","manager":"SHARIE HEADING"},
        {"city":"NEW ORLEANS LA","phone":"504-434-6266","address":"6000 BULLARD AVE","postalCode":"70128","storeNo":"00912","manager":"CARMELA BOUTTE"},
        {"city":"BOUTTE LA","phone":"985-785-0855","address":"13001 HIGHWAY 90","postalCode":"70039","storeNo":"02913","manager":"LEEANN RICHARDSON"},
        {"city":"MERAUX LA","phone":"504-249-7757","address":"2500 ARCHBISHOP HANNAN BLVD","postalCode":"70075","storeNo":"05081","manager":"ALEXANDER BLACK"},
        {"city":"LA PLACE LA","phone":"985-652-8994","address":"1616 W AIRLINE HWY","postalCode":"70068","storeNo":"00961","manager":"TINA RAMIREZ"},
        {"city":"MANDEVILLE LA","phone":"985-626-1113","address":"3009 E CAUSEWAY APPROACH","postalCode":"70448","storeNo":"05832","manager":"AARON PILLING"},
        {"city":"SLIDELL LA","phone":"985-288-6518","address":"3130 PONTCHATRAIN DRIVE","postalCode":"70458","storeNo":"06588","manager":"BARBARA MATNEY"},
        {"city":"SLIDELL LA","phone":"985-690-0123","address":"167 NORTHSHORE BLVD","postalCode":"70460","storeNo":"02665","manager":"DEMETRIA PRUETT"},
        {"city":"RACELAND LA","phone":"985-532-6936","address":"4858 HIGHWAY 1","postalCode":"70394","storeNo":"00761","manager":"BESSIE LARKINS"},
        {"city":"COVINGTON LA","phone":"985-867-8701","address":"880 N HIGHWAY 190","postalCode":"70433","storeNo":"00541","manager":"WILLIAM ZIRGER"},
        {"city":"SLIDELL LA","phone":"985-641-8572","address":"39142 NATCHEZ DR","postalCode":"70461","storeNo":"00553","manager":"STEPHANIE SMITH"},
        {"city":"SLIDELL LA","phone":"985-288-6118","address":"637 ROBERT BLVD","postalCode":"70458","storeNo":"06577","manager":"L.B. DANIELS"},
        {"city":"COVINGTON LA","phone":"985-327-6312","address":"2800 N HWY 190","postalCode":"70433","storeNo":"03042","manager":"GIANNA M JONES"},
        {"city":"PONCHATOULA LA","phone":"985-467-8046","address":"1331 HWY 51","postalCode":"70454","storeNo":"04129","manager":"CURTIS WHITMIRE"},
        {"city":"GALLIANO LA","phone":"985-632-4040","address":"16759 HIGHWAY 3235","postalCode":"70345","storeNo":"00502","manager":"FREDERICO MURO"},
        {"city":"HAMMOND LA","phone":"985-345-8876","address":"2799 W THOMAS ST","postalCode":"70401","storeNo":"00489","manager":"KAY BRADLEY"},
        {"city":"THIBODAUX LA","phone":"985-446-2257","address":"410 N CANAL BLVD","postalCode":"70301","storeNo":"01016","manager":"KEAIRA ROBERTS"},
        {"city":"THIBODAUX LA","phone":"985-492-5305","address":"224 W MAIN ST","postalCode":"70301","storeNo":"05464","manager":"JUSTIN RAND"},
        {"city":"HOUMA LA","phone":"985-917-0151","address":"933 GRAND CAILLOU RD","postalCode":"70363","storeNo":"03483","manager":"DAVID HUGHES"},
        {"city":"HOUMA LA","phone":"985-217-3665","address":"6411 W PARK AVE","postalCode":"70364","storeNo":"05774","manager":"MARIA G GARCIA"},
        {"city":"HOUMA LA","phone":"985-851-6373","address":"1633 MARTIN LUTHER KING JR BLVD","postalCode":"70360","storeNo":"00542","manager":"PAUL E GRANADO"},
        {"city":"PICAYUNE MS","phone":"601-799-3455","address":"235 FRONTAGE RD","postalCode":"39466","storeNo":"00970","manager":"BRADENTON TAHAN"},
        {"city":"GONZALES LA","phone":"225-647-8950","address":"308 N AIRLINE HWY","postalCode":"70737","storeNo":"00532","manager":"CURTIS WILLIAM"},
        {"city":"DONALDSONVILLE LA","phone":"225-473-6687","address":"37000 HWY 3089","postalCode":"70346","storeNo":"00485","manager":"TERESA GARCIA"},
        {"city":"PALATINE IL","phone":"847-202-9189","address":"1555 N RAND RD","postalCode":"60074","storeNo":"05276","manager":"LAKITA TOLESTON"},
        {"city":"CARPENTERSVILLE IL","phone":"847-426-2800","address":"365 LAKE MARIAN ROAD","postalCode":"60110","storeNo":"01531","manager":"ALYSSA THOMAS"},
        {"city":"CRYSTAL LAKE IL","phone":"815-455-4200","address":"1205 S IL ROUTE 31","postalCode":"60014","storeNo":"01413","manager":"GARZA SANDRA"},
        {"city":"WHEELING IL","phone":"847-537-5090","address":"1455 E LAKE COOK RD","postalCode":"60090","storeNo":"01735","manager":"SCOTT ADAMS"},
        {"city":"STREAMWOOD IL","phone":"630-213-7000","address":"850 S BARRINGTON RD","postalCode":"60107","storeNo":"01420","manager":"PEDRO PINEIRO"},
        {"city":"ROLLING MEADOWS IL","phone":"847-734-0456","address":"1460 GOLF RD","postalCode":"60008","storeNo":"02815","manager":"BELINDA COOPER"},
        {"city":"ALGONQUIN IL","phone":"847-458-5620","address":"1410 S RANDALL RD","postalCode":"60102","storeNo":"05060","manager":"HOBERT HENDERSON"},
        {"city":"VERNON HILLS IL","phone":"847-918-0555","address":"555 E TOWNLINE RD","postalCode":"60061","storeNo":"01489","manager":"PRICE DOROTHRA"},
        {"city":"ELK GROVE VILLAGE IL","phone":"847-584-7080","address":"801 MEACHAM RD","postalCode":"60007","storeNo":"01897","manager":"LINDA PORTER"},
        {"city":"MOUNT PROSPECT IL","phone":"847-590-0002","address":"930 MOUNT PROSPECT PLZ","postalCode":"60056","storeNo":"01681","manager":"TANYA GRAVES"},
        {"city":"ELGIN IL","phone":"847-468-9600","address":"1100 S RANDALL RD","postalCode":"60123","storeNo":"01814","manager":"OMAR MARTINEZ"},
        {"city":"ADDISON IL","phone":"630-889-1826","address":"1050 N ROHLWING RD","postalCode":"60101","storeNo":"05442","manager":"ROYAL MARIO"},
        {"city":"BLOOMINGDALE IL","phone":"630-893-5000","address":"314 WEST ARMY TRAIL ROAD","postalCode":"60108","storeNo":"01553","manager":"LAURA NORRIS"},
        {"city":"HUNTLEY IL","phone":"847-669-7126","address":"12300 ROUTE 47","postalCode":"60142","storeNo":"04641","manager":"JOICE DUNSTON"},
        {"city":"JOHNSBURG IL","phone":"815-344-7702","address":"3801 RUNNING BRK FARM BLVD","postalCode":"60051","storeNo":"01377","manager":"GREEN RYAN"},
        {"city":"NILES IL","phone":"847-966-7904","address":"8500 W GOLF RD","postalCode":"60714","storeNo":"03725","manager":"RENESHA JOHNSON"},
        {"city":"SAINT CHARLES IL","phone":"630-513-9559","address":"150 SMITH RD","postalCode":"60174","storeNo":"01898","manager":"IVAN SIERRA"},
        {"city":"ROUND LAKE BEACH IL","phone":"847-265-3687","address":"2680 N IL ROUTE 83","postalCode":"60073","storeNo":"01228","manager":"DAVID DESOTO"},
        {"city":"WOODSTOCK IL","phone":"815-206-0263","address":"1275 LAKE AVE","postalCode":"60098","storeNo":"03434","manager":"JENNIFER OSHEA"},
        {"city":"WAUKEGAN IL","phone":"847-473-2193","address":"3900 FOUNTAIN SQUARE PL","postalCode":"60085","storeNo":"03891","manager":"JOSE BLANCAS"},
        {"city":"GURNEE IL","phone":"847-855-1230","address":"6590 GRAND AVE","postalCode":"60031","storeNo":"01668","manager":"WANDA SWOFFORD"},
        {"city":"NORTHLAKE IL","phone":"708-409-0049","address":"137 W NORTH AVE","postalCode":"60164","storeNo":"01933","manager":"CARMAN HANCHEY"},
        {"city":"NILES IL","phone":"847-647-8641","address":"5630 W TOUHY AVE","postalCode":"60714","storeNo":"02816","manager":"KAY CAMPBELL"},
        {"city":"VILLA PARK IL","phone":"630-530-2550","address":"900 SOUTH ROUTE 83","postalCode":"60181","storeNo":"01737","manager":"MARITZA FELICIANO"},
        {"city":"ANTIOCH IL","phone":"847-838-2148","address":"475 E IL ROUTE 173","postalCode":"60002","storeNo":"05199","manager":"JORDANY SIMON"},
        {"city":"BATAVIA IL","phone":"630-879-3970","address":"801 N RANDALL RD","postalCode":"60510","storeNo":"05352","manager":"JOSE CANO"},
        {"city":"GLEN ELLYN IL","phone":"630-545-1060","address":"3S100 ROUTE 53","postalCode":"60137","storeNo":"01848","manager":"CALVIN MCCOY"},
        {"city":"SKOKIE IL","phone":"847-983-1409","address":"3626 TOUHY AVE","postalCode":"60076","storeNo":"01998","manager":"JODY STARNES"},
        {"city":"AURORA IL","phone":"630-375-6207","address":"2900 KIRK RD","postalCode":"60502","storeNo":"04405","manager":"BEVERLY J SYLLA"},
        {"city":"CHICAGO IL","phone":"773-628-1880","address":"4626 W DIVERSEY AVE","postalCode":"60639","storeNo":"05617","manager":"JACK B LAMB"},
        {"city":"ZION IL","phone":"847-731-8172","address":"4000 IL ROUTE 173","postalCode":"60099","storeNo":"03893","manager":"IDIANA MORALES"},
        {"city":"FOREST PARK IL","phone":"708-771-2270","address":"1300 DES PLAINES AVE","postalCode":"60130","storeNo":"02204","manager":"FRANCES CASHOYA"},
        {"city":"CHICAGO IL","phone":"773-252-7465","address":"4650 W NORTH AVE","postalCode":"60639","storeNo":"05402","manager":"RONALD PETERSON"},
        {"city":"NAPERVILLE IL","phone":"630-416-1000","address":"2552 W 75TH ST","postalCode":"60564","storeNo":"01401","manager":"TERI GATZKE"},
        {"city":"DARIEN IL","phone":"630-434-0490","address":"2189 75TH ST","postalCode":"60561","storeNo":"02215","manager":"DIANE CURRY"},
        {"city":"HODGKINS IL","phone":"708-387-2090","address":"9450 JOLIET RD","postalCode":"60525","storeNo":"01892","manager":"MICHAEL PRETTYMAN"},
        {"city":"CICERO IL","phone":"708-735-8456","address":"3320 S CICERO AVE","postalCode":"60804","storeNo":"03004","manager":"FREDDIE SCOTT"},
        {"city":"HARVARD IL","phone":"815-943-7496","address":"21101 MCGUIRE RD","postalCode":"60033","storeNo":"01211","manager":"CARLTON ATKINS"},
        {"city":"BOLINGBROOK IL","phone":"630-739-4800","address":"200 S BOLINGBROOK DR","postalCode":"60440","storeNo":"01596","manager":"EVELYN GILLISPIE"},
        {"city":"OSWEGO IL","phone":"630-554-3014","address":"2300 US HIGHWAY 34","postalCode":"60543","storeNo":"03400","manager":"ANGEL TIRADO"},
        {"city":"MONTGOMERY IL","phone":"630-844-0292","address":"2000 ORCHARD RD","postalCode":"60538","storeNo":"04286","manager":"BRITTANY EVANS"},
        {"city":"DEKALB IL","phone":"815-758-6225","address":"2300 SYCAMORE RD","postalCode":"60115","storeNo":"00786","manager":"TRINITY ALSTON"},
        {"city":"BEDFORD PARK IL","phone":"708-496-0230","address":"7050 S CICERO AVE","postalCode":"60638","storeNo":"01938","manager":"TRISTEN LOONEY"},
        {"city":"LAKE GENEVA WI","phone":"262-248-2266","address":"201 S EDWARDS BLVD","postalCode":"53147","storeNo":"00910","manager":"GUY BRIDGEMAN"},
        {"city":"KENOSHA WI","phone":"262-652-1039","address":"3500 BRUMBACK BLVD","postalCode":"53144","storeNo":"01167","manager":"ROBERT ZIMMER"},
        {"city":"BRIDGEVIEW IL","phone":"708-499-2088","address":"10260 S HARLEM AVE","postalCode":"60455","storeNo":"01779","manager":"KEATON BRADLEY"},
        {"city":"BELVIDERE IL","phone":"815-547-5447","address":"2101 GATEWAY CENTER DR","postalCode":"61008","storeNo":"03597","manager":"DAVID JACKSON"},
        {"city":"CHICAGO IL","phone":"773-420-0289","address":"7535 S ASHLAND AVE","postalCode":"60620","storeNo":"03110","manager":"WILLIE DAVIS"},
        {"city":"EVERGREEN PARK IL","phone":"708-229-0611","address":"2500 W 95TH ST","postalCode":"60805","storeNo":"05485","manager":"PATSY SURGINER"},
        {"city":"INDIANOLA IA","phone":"515-961-8955","address":"1500 N JEFFERSON WAY","postalCode":"50125","storeNo":"01491","manager":"WILLIE WRIGHT"},
        {"city":"DES MOINES IA","phone":"515-287-7700","address":"5101 SE 14TH ST","postalCode":"50320","storeNo":"01723","manager":"KATHERINE BAUGH"},
        {"city":"KNOXVILLE IA","phone":"641-828-7584","address":"814 W BELL AVE","postalCode":"50138","storeNo":"02935","manager":"JIL SHINER"},
        {"city":"ALTOONA IA","phone":"515-967-1711","address":"3501 8TH ST SW","postalCode":"50009","storeNo":"02764","manager":"JACALYN KEYS"},
        {"city":"WINDSOR HEIGHTS IA","phone":"515-274-6224","address":"1001 73RD ST","postalCode":"50324","storeNo":"01764","manager":"MARK NICHOLS"},
        {"city":"WEST DES MOINES IA","phone":"515-453-2747","address":"6365 STAGECOACH DR","postalCode":"50266","storeNo":"03762","manager":"DERICK WILKINS"},
        {"city":"ANKENY IA","phone":"515-963-1111","address":"1002 SE NATIONAL DR","postalCode":"50021","storeNo":"00892","manager":"ALFRED JENKINS"},
        {"city":"PELLA IA","phone":"641-628-9881","address":"1650 WASHINGTON ST","postalCode":"50219","storeNo":"00751","manager":"JAMES CLIFFORD"},
        {"city":"GRIMES IA","phone":"515-986-1783","address":"2150 E 1ST ST","postalCode":"50111","storeNo":"05748","manager":"CRAIG SALOW"},
        {"city":"OSCEOLA IA","phone":"641-342-1650","address":"2400 COLLEGE DR","postalCode":"50213","storeNo":"04606","manager":"NATASHA SCOTT"},
        {"city":"NEWTON IA","phone":"641-791-5322","address":"300 IOWA SPEEDWAY DR","postalCode":"50208","storeNo":"00748","manager":"LORENA BOLANOS"},
        {"city":"OSKALOOSA IA","phone":"641-673-3839","address":"2203 AVENUE A WEST","postalCode":"52577","storeNo":"01393","manager":"ALICE ST AMAND"},
        {"city":"GRINNELL IA","phone":"641-236-4999","address":"415 INDUSTRIAL AVE","postalCode":"50112","storeNo":"00647","manager":"LAMENIA POWELL"},
        {"city":"AMES IA","phone":"515-956-3536","address":"534 S DUFF AVE","postalCode":"50010","storeNo":"04256","manager":"SHANTANE MARSHALL"},
        {"city":"AMES IA","phone":"515-233-1345","address":"3105 GRAND AVE","postalCode":"50010","storeNo":"00749","manager":"CARLA LIBERTY"},
        {"city":"CARROLLTON KY","phone":"502-732-0645","address":"200 FLOYD DR","postalCode":"41008","storeNo":"02968","manager":"LYDIA SIMPSON"},
        {"city":"LA GRANGE KY","phone":"502-222-4260","address":"1015 NEW MOODY LN","postalCode":"40031","storeNo":"01053","manager":"PAMELA ADAMS"},
        {"city":"CRESTWOOD KY","phone":"502-241-6271","address":"6501 VETERANS MEMORIAL PKWY","postalCode":"40014","storeNo":"07290","manager":"MARK FUCHS"},
        {"city":"MADISON IN","phone":"812-273-4993","address":"567 IVY TECH DR","postalCode":"47250","storeNo":"01327","manager":"STACIE LYMAN"},
        {"city":"SHELBYVILLE KY","phone":"502-633-0705","address":"500 TAYLORSVILLE RD","postalCode":"40065","storeNo":"00497","manager":"LATOYA WILSON"},
        {"city":"LOUISVILLE KY","phone":"502-326-9166","address":"3706 DIANN MARIE RD","postalCode":"40241","storeNo":"00526","manager":"RODNEY RICH"},
        {"city":"MIDDLETOWN KY","phone":"502-244-2551","address":"12981 SHELBYVILLE RD","postalCode":"40243","storeNo":"04450","manager":"ANGELA MATTHEW"},
        {"city":"FRANKFORT KY","phone":"502-875-5533","address":"301 LEONARDWOOD RD","postalCode":"40601","storeNo":"00720","manager":"EDWARD J DENEHY"},
        {"city":"JEFFERSONTOWN KY","phone":"502-266-2685","address":"3800 RUCKRIEGEL PKWY","postalCode":"40299","storeNo":"05183","manager":"KRISTINA JOHNSON"},
        {"city":"SAINT MATTHEWS KY","phone":"502-893-8088","address":"143 THIERMAN LN","postalCode":"40207","storeNo":"05297","manager":"PEDRO ELIZONDO"},
        {"city":"DRY RIDGE KY","phone":"859-824-0575","address":"20 FERGUSON BLVD","postalCode":"41035","storeNo":"00584","manager":"ASHLEY REICK"},
        {"city":"CLARKSVILLE IN","phone":"812-284-9926","address":"1351 VETERANS PKWY","postalCode":"47129","storeNo":"01476","manager":"EDGER HUCK"},
        {"city":"LOUISVILLE KY","phone":"502-231-4880","address":"7101 CEDAR SPRINGS BLVD","postalCode":"40291","storeNo":"03294","manager":"KIM LANDDECK"},
        {"city":"LOUISVILLE KY","phone":"502-451-6766","address":"2020 BASHFORD MANOR LN","postalCode":"40218","storeNo":"05418","manager":"MARGRET S PUGH"},
        {"city":"SCOTTSBURG IN","phone":"812-752-7122","address":"1618 W MCCLAIN AVE","postalCode":"47170","storeNo":"01142","manager":"SHELIA J RAY"},
        {"city":"LAWRENCEBURG KY","phone":"502-839-5178","address":"1000 BYPASS N","postalCode":"40342","storeNo":"00507","manager":"KIMBERLY HOGAN"},
        {"city":"NEW ALBANY IN","phone":"812-944-0635","address":"2910 GRANT LINE RD","postalCode":"47150","storeNo":"02691","manager":"KRISTI R ZAMORA"},
        {"city":"LOUISVILLE KY","phone":"502-313-6351","address":"4840 OUTER LOOP","postalCode":"40219","storeNo":"06931","manager":"JOSHUA WALKER"},
        {"city":"LOUISVILLE KY","phone":"502-968-6800","address":"11901 STANDIFORD PLAZA DR","postalCode":"40229","storeNo":"00589","manager":"ALICIA DAVISSON"},
        {"city":"GEORGETOWN KY","phone":"502-867-0547","address":"112 OSBOURNE WAY","postalCode":"40324","storeNo":"00571","manager":"TRICIA ELAM"},
        {"city":"AURORA IN","phone":"812-926-4322","address":"100 SYCAMORE ESTATES DR","postalCode":"47001","storeNo":"01160","manager":"LORRAINE DRAEGER"},
        {"city":"NORTH VERNON IN","phone":"812-346-5100","address":"2410 N STATE HWY 3","postalCode":"47265","storeNo":"01157","manager":"AMANDA GARCIA"},
        {"city":"LOUISVILLE KY","phone":"502-361-0225","address":"175 OUTER LOOP","postalCode":"40214","storeNo":"05417","manager":"STEPHANIE JOHNSON"},
        {"city":"LOUISVILLE KY","phone":"502-447-4757","address":"5360 DIXIE HWY","postalCode":"40216","storeNo":"04524","manager":"DARRELL RILLERA"},
        {"city":"FLORENCE KY","phone":"859-282-8333","address":"7625 DOERING DR","postalCode":"41042","storeNo":"01510","manager":"DINAH JOSEPH"},
        {"city":"SHEPHERDSVILLE KY","phone":"502-281-5005","address":"545 CONESTOGA PKWY","postalCode":"40165","storeNo":"04118","manager":"DANIEL DIANDA"},
        {"city":"SEYMOUR IN","phone":"812-522-8838","address":"1600 E TIPTON ST","postalCode":"47274","storeNo":"01033","manager":"KAREN HUFFMON"},
        {"city":"LOUISVILLE KY","phone":"502-935-3233","address":"10445 DIXIE HWY","postalCode":"40272","storeNo":"01170","manager":"JENNIFER MCKIM"},
        {"city":"CYNTHIANA KY","phone":"859-234-3232","address":"805 US HIGHWAY 27 S","postalCode":"41031","storeNo":"00591","manager":"MARITA WOODARD"},
        {"city":"SALEM IN","phone":"812-883-7473","address":"1309 E HACKBERRY STREET","postalCode":"47167","storeNo":"07087","manager":"RUSLI MAKMOER"},
        {"city":"LEXINGTON KY","phone":"859-224-0840","address":"3735 PALOMAR CENTRE DR","postalCode":"40513","storeNo":"04607","manager":"DENNIS LAKEISHA"},
        {"city":"FT WRIGHT KY","phone":"859-341-7900","address":"3450 VALLEY PLAZA PKWY","postalCode":"41017","storeNo":"02967","manager":"JAMES GARRETT"},
        {"city":"LEXINGTON KY","phone":"859-381-9370","address":"500 W NEW CIRCLE RD","postalCode":"40511","storeNo":"02783","manager":"MICHAEL AVILA"},
        {"city":"WINDER GA","phone":"770-867-8642","address":"440 ATLANTA HWY N.W.","postalCode":"30680","storeNo":"00520","manager":"MICHAEL GALLAGHER"},
        {"city":"BUFORD GA","phone":"678-546-6464","address":"3250 SARDIS CHURCH RD","postalCode":"30519","storeNo":"03786","manager":"JOSEPH CLAYTER"},
        {"city":"LAWRENCEVILLE GA","phone":"770-995-0102","address":"630 COLLINS HILL RD","postalCode":"30046","storeNo":"00548","manager":"DANIELLE SALCHOW"},
        {"city":"BUFORD GA","phone":"770-271-8210","address":"3795 BUFORD DR","postalCode":"30519","storeNo":"01314","manager":"JENNIFER PI"},
        {"city":"SUWANEE GA","phone":"678-482-5441","address":"3245 LAWRENCEVILLE SUWANEE RD","postalCode":"30024","storeNo":"03462","manager":"LINDA KNIGHT"},
        {"city":"LAWRENCEVILLE GA","phone":"770-682-1992","address":"1400 LAWRENCEVILLE HWY","postalCode":"30044","storeNo":"03388","manager":"JENNIFER FLAKES"},
        {"city":"LOGANVILLE GA","phone":"770-554-7481","address":"4221 ATLANTA HWY","postalCode":"30052","storeNo":"05252","manager":"SHARELLE COLEMAN"},
        {"city":"SNELLVILLE GA","phone":"770-979-2447","address":"1550 SCENIC HWY N","postalCode":"30078","storeNo":"01720","manager":"MARGIE BUCKMAN"},
        {"city":"OAKWOOD GA","phone":"770-535-6543","address":"3875 MUNDY MILL RD","postalCode":"30566","storeNo":"03627","manager":"ENGER SIMS"},
        {"city":"LAWRENCEVILLE GA","phone":"678-259-0050","address":"3059 LAWRENCEVILLE HWY","postalCode":"30044","storeNo":"03074","manager":"NIGEAL MENTOR"},
        {"city":"SNELLVILLE GA","phone":"770-225-0949","address":"2912 W MAIN ST","postalCode":"30078","storeNo":"03072","manager":"THUY HUYNH"},
        {"city":"MONROE GA","phone":"770-267-4527","address":"2050 W SPRING ST","postalCode":"30655","storeNo":"00780","manager":"DEANDRA BROWN"},
        {"city":"LILBURN GA","phone":"770-921-9224","address":"4004 LAWRENCEVILLE HWY NW","postalCode":"30047","storeNo":"01373","manager":"JANET SNYDER"},
        {"city":"GAINESVILLE GA","phone":"678-971-6367","address":"2892 BROWNS BRIDGE RD","postalCode":"30504","storeNo":"02731","manager":"DARNELL WILLIAMS"},
        {"city":"DULUTH GA","phone":"770-418-0162","address":"2635 PLEASANT HILL RD","postalCode":"30096","storeNo":"02154","manager":"TARA COLEMAN"},
        {"city":"GAINESVILLE GA","phone":"770-503-9300","address":"400 SHALLOWFORD RD NW","postalCode":"30504","storeNo":"00510","manager":"ANDREW SMITH"},
        {"city":"SNELLVILLE GA","phone":"770-972-7572","address":"3435 CENTERVILLE HWY","postalCode":"30039","storeNo":"03389","manager":"GAIL TREADWELL"},
        {"city":"CUMMING GA","phone":"770-406-5165","address":"2395 PEACHTREE PKWY","postalCode":"30041","storeNo":"04185","manager":"SUSAN JONES"},
        {"city":"CUMMING GA","phone":"770-889-3436","address":"1500 MARKET PLACE BLVD","postalCode":"30041","storeNo":"00878","manager":"JOSEPH BONNELL"},
        {"city":"GAINESVILLE GA","phone":"678-971-6384","address":"1435 THOMPSON BRIDGE RD","postalCode":"30501","storeNo":"02699","manager":"ARELIA D WARD"},
        {"city":"STONE MOUNTAIN GA","phone":"770-469-8660","address":"1825 ROCKBRIDGE RD","postalCode":"30087","storeNo":"01184","manager":"LAUREN SIMS"},
        {"city":"CUMMING GA","phone":"770-203-4860","address":"3655 BROWNS BRIDGE RD","postalCode":"30028","storeNo":"07185","manager":"KAREN GANTT"},
        {"city":"NORCROSS GA","phone":"770-225-9882","address":"4975 JIMMY CARTER BLVD","postalCode":"30093","storeNo":"03067","manager":"ANGEL RIVERS"},
        {"city":"ATHENS GA","phone":"706-715-6238","address":"3130 ATLANTA HWY","postalCode":"30606","storeNo":"05267","manager":"KYLE JERGER"},
        {"city":"ATHENS GA","phone":"706-549-1423","address":"1911 EPPS BRIDGE PKWY","postalCode":"30606","storeNo":"01400","manager":"ALANA RUSSELL"},
        {"city":"TUCKER GA","phone":"770-225-6010","address":"3201 TUCKER NORCROSS RD","postalCode":"30084","storeNo":"03071","manager":"PATRICIA MAY"},
        {"city":"TUCKER GA","phone":"770-939-2671","address":"4375 LAWRENCEVILLE HWY","postalCode":"30084","storeNo":"02584","manager":"ERIC MCCARTHY"},
        {"city":"ALPHARETTA GA","phone":"770-475-4101","address":"5455 ATLANTA HWY","postalCode":"30004","storeNo":"04436","manager":"JANICE BARTON"},
        {"city":"DUNWOODY GA","phone":"770-280-0193","address":"5025 WINTERS CHAPEL RD","postalCode":"30360","storeNo":"03070","manager":"YEZENIA CORDOVA"},
        {"city":"ALPHARETTA GA","phone":"770-772-9033","address":"5200 WINDWARD PKWY","postalCode":"30004","storeNo":"02941","manager":"JEROME NEDD"},
        {"city":"STONE MOUNTAIN GA","phone":"404-260-6142","address":"5935 MEMORIAL DR","postalCode":"30083","storeNo":"03188","manager":"JOHNNY LEWIS"},
        {"city":"DAWSONVILLE GA","phone":"706-265-8787","address":"98 POWER CENTER DR","postalCode":"30534","storeNo":"03874","manager":"CYNTHIA PENA"},
        {"city":"LITHONIA GA","phone":"770-225-0428","address":"8424 MALL PARKWAY","postalCode":"30038","storeNo":"04472","manager":"ERICA BRIDGES"},
        {"city":"COMMERCE GA","phone":"706-335-7563","address":"30983 HIGHWAY 441 S","postalCode":"30529","storeNo":"00003","manager":"THULIEU LE"},
        {"city":"CONYERS GA","phone":"770-860-8544","address":"1436 DOGWOOD DR SE","postalCode":"30013","storeNo":"02475","manager":"DAVID NEWAGO"},
        {"city":"ROSWELL GA","phone":"770-993-0533","address":"970 MANSELL RD","postalCode":"30076","storeNo":"01578","manager":"WILLIAM COWAN"},
        {"city":"COVINGTON GA","phone":"770-787-8030","address":"10300 INDUSTRIAL BLVD NE","postalCode":"30014","storeNo":"00459","manager":"BENJAMIN MCDAVID"},
        {"city":"CHAMBLEE GA","phone":"770-455-0422","address":"1871 CHAMBLEE TUCKER RD","postalCode":"30341","storeNo":"03621","manager":"RUBY FIGUEROA"},
        {"city":"LITHONIA GA","phone":"770-593-3540","address":"5401 FAIRINGTON RD","postalCode":"30038","storeNo":"01340","manager":"KAMILAH LEWIS"},
        {"city":"DECATUR GA","phone":"404-464-4480","address":"2525 N DECATUR RD","postalCode":"30033","storeNo":"03118","manager":"AUDRA CLOUSE"},
        {"city":"DECATUR GA","phone":"404-284-0500","address":"3580 MEMORIAL DR","postalCode":"30032","storeNo":"03710","manager":"FRANK DIXON"},
        {"city":"ATHENS GA","phone":"706-355-3966","address":"4375 LEXINGTON RD","postalCode":"30605","storeNo":"02811","manager":"LONNY LINDERBAUM"},
        {"city":"COVINGTON GA","phone":"678-212-3195","address":"4200 SALEM RD","postalCode":"30016","storeNo":"04558","manager":"MAXWELL WALTER"},
        {"city":"MARIETTA GA","phone":"770-640-7225","address":"3100 JOHNSON FERRY RD","postalCode":"30062","storeNo":"01766","manager":"SALVADOR MOLINA"},
        {"city":"ATLANTA GA","phone":"404-244-3034","address":"2427 GRESHAM RD SE","postalCode":"30316","storeNo":"03709","manager":"VALERIE CHANCE"},
        {"city":"DAHLONEGA GA","phone":"706-867-6912","address":"270 WALMART WAY","postalCode":"30533","storeNo":"02513","manager":"LAURA LANGE"},
        {"city":"WOODSTOCK GA","phone":"770-516-4719","address":"12182 HIGHWAY 92","postalCode":"30188","storeNo":"00575","manager":"KEN SMITH"},
        {"city":"ELLENWOOD GA","phone":"404-361-6811","address":"2940 ANVIL BLOCK RD","postalCode":"30294","storeNo":"03609","manager":"WALTER TURNER"},
        {"city":"MADISON GA","phone":"706-342-9988","address":"1681 EATONTON RD","postalCode":"30650","storeNo":"01363","manager":"CLARENCE COOPER"},
        {"city":"ATLANTA GA","phone":"404-460-2703","address":"835 MARTIN LUTHER KING JR DR NW","postalCode":"30314","storeNo":"07601","manager":"ANALINDA HALE"},
        {"city":"WASHINGTON DC","phone":"202-719-3770","address":"5929 GEORGIA AVE NW","postalCode":"20011","storeNo":"05968","manager":"JESSE MARFIL"},
        {"city":"WASHINGTON DC","phone":"202-756-4418","address":"310 RIGGS RD NE","postalCode":"20011","storeNo":"03035","manager":"MATHEW LAHTI"},
        {"city":"LANDOVER HILLS MD","phone":"301-773-7848","address":"6210 ANNAPOLIS RD","postalCode":"20784","storeNo":"05129","manager":"PAUL CARNLEY"},
        {"city":"VIENNA VA","phone":"571-623-3300","address":"1500 B CORNERSIDE BLVD","postalCode":"22182","storeNo":"05936","manager":"VALOREE MARTELL"},
        {"city":"ALEXANDRIA VA","phone":"703-253-9901","address":"6303 RICHMOND HWY","postalCode":"22306","storeNo":"05753","manager":"BRANDAN AFUNGIA"},
        {"city":"ALEXANDRIA VA","phone":"703-924-8800","address":"5885 KINGSTOWNE BLVD","postalCode":"22315","storeNo":"02194","manager":"ERIC A BREWER"},
        {"city":"CLINTON MD","phone":"301-877-0502","address":"8745 BRANCH AVE","postalCode":"20735","storeNo":"02799","manager":"TEON HARRIS"},
        {"city":"ALEXANDRIA VA","phone":"703-799-8815","address":"7910 RICHMOND HWY","postalCode":"22306","storeNo":"02258","manager":"BRANDEN WILSON"},
        {"city":"LAUREL MD","phone":"301-604-0180","address":"3549 RUSSETT GRN","postalCode":"20724","storeNo":"01985","manager":"FRANCINA MORALES"},
        {"city":"BOWIE MD","phone":"301-805-8850","address":"3300 CRAIN HWY","postalCode":"20716","storeNo":"01893","manager":"MARLENE SCHOEN"},
        {"city":"FAIRFAX VA","phone":"703-995-5061","address":"11181 LEE HWY","postalCode":"22030","storeNo":"05880","manager":"CRECENCIO BEIZA"},
        {"city":"BURKE VA","phone":"703-250-9280","address":"6000 BURKE COMMONS RD","postalCode":"22015","storeNo":"05227","manager":"REGINA PICKETT"},
        {"city":"COLUMBIA MD","phone":"410-740-2448","address":"6405 DOBBIN RD","postalCode":"21045","storeNo":"05228","manager":"AMBER RANDALL"},
        {"city":"HANOVER MD","phone":"410-579-8725","address":"7081 ARUNDEL MILLS CIR","postalCode":"21076","storeNo":"03490","manager":"TYRUS BELCHER"},
        {"city":"GERMANTOWN MD","phone":"301-515-6700","address":"20910 FREDERICK RD","postalCode":"20876","storeNo":"02357","manager":"DEANTRIS HEWING"},
        {"city":"FAIRFAX VA","phone":"703-631-9450","address":"13059 FAIR LAKES PARKWAY","postalCode":"22033","storeNo":"02015","manager":"RACHEL CONTRERES"},
        {"city":"STERLING VA","phone":"571-434-9434","address":"45415 DULLES CROSSING PLZ","postalCode":"20166","storeNo":"02038","manager":"KELLIE SATTLER"},
        {"city":"WALDORF MD","phone":"301-705-7070","address":"11930 ACTON LN","postalCode":"20601","storeNo":"01717","manager":"ROBERT KIPLE"},
        {"city":"CHANTILLY VA","phone":"571-392-3081","address":"4368 CHANTILLY SHOPPING CENTER DR","postalCode":"20151","storeNo":"05969","manager":"MAYFERET BAMBA"},
        {"city":"SEVERN MD","phone":"410-582-9990","address":"407 GEORGE CLAUS BLVD","postalCode":"21144","storeNo":"01875","manager":"BOBBY FRANKLIN"},
        {"city":"ELLICOTT CITY MD","phone":"410-418-5780","address":"3200 NORTH RIDGE RD","postalCode":"21043","storeNo":"02412","manager":"SHERITA SANDERS"},
        {"city":"DUNKIRK MD","phone":"410-257-2610","address":"10600 TOWNE CENTER BLVD","postalCode":"20754","storeNo":"05345","manager":"JENNIFER MYERS"},
        {"city":"WOODBRIDGE VA","phone":"703-497-2590","address":"14000 WORTH AVE","postalCode":"22192","storeNo":"01852","manager":"TIM ALT"},
        {"city":"PASADENA MD","phone":"410-689-1509","address":"8107 GOVERNOR RITCHIE HWY","postalCode":"21122","storeNo":"05382","manager":"JUANIKA KELSON"},
        {"city":"MANASSAS VA","phone":"703-257-0403","address":"9401 LIBERIA AVE","postalCode":"20110","storeNo":"03573","manager":"MELISSA BUTLER"},
        {"city":"CATONSVILLE MD","phone":"410-719-0600","address":"6205 BALTIMORE NATIONAL PIKE","postalCode":"21228","storeNo":"02248","manager":"JESSIE GARCIA"},
        {"city":"DULLES VA","phone":"571-367-3036","address":"24635 DULLES LANDING DR","postalCode":"20166","storeNo":"03639","manager":"JUAN ARIAS"},
        {"city":"GLEN BURNIE MD","phone":"410-863-1280","address":"6721 CHESAPEAKE CENTER DR","postalCode":"21060","storeNo":"02279","manager":"MICHEAL HOSEY"},
        {"city":"ARBUTUS MD","phone":"410-737-7700","address":"3601 WASHINGTON BLVD","postalCode":"21227","storeNo":"03720","manager":"SONIA SUMPTER"},
        {"city":"LA PLATA MD","phone":"301-392-9112","address":"40 DRURY DR","postalCode":"20646","storeNo":"02853","manager":"DEADERICK ROBERT"},
        {"city":"MANASSAS VA","phone":"703-330-5253","address":"8386 SUDLEY RD","postalCode":"20109","storeNo":"01825","manager":"JAMIE DIXON"},
        {"city":"MOUNT AIRY MD","phone":"301-829-4433","address":"209 E RIDGEVILLE BLVD","postalCode":"21771","storeNo":"02551","manager":"TARUS DUKES"},
        {"city":"DUMFRIES VA","phone":"703-221-4116","address":"17041 RICHMOND HWY","postalCode":"22026","storeNo":"03588","manager":"ALCIRA GUILLEN"},
        {"city":"LEESBURG VA","phone":"703-779-0102","address":"19360 COMPASS CREEK PKWY","postalCode":"20175","storeNo":"01904","manager":"HENRY JONES"},
        {"city":"SYKESVILLE MD","phone":"410-549-5400","address":"6400A RIDGE RD","postalCode":"21784","storeNo":"02436","manager":"BENJAMIN MCNEAL"},
        {"city":"RANDALLSTOWN MD","phone":"443-576-3132","address":"8730 LIBERTY RD","postalCode":"21133","storeNo":"03804","manager":"LYLE DUNGEE"},
        {"city":"HAYMARKET VA","phone":"703-468-2445","address":"6530 TRADING SQ","postalCode":"20169","storeNo":"03077","manager":"LUCAS DARIUS"},
        {"city":"DUNDALK MD","phone":"410-284-5412","address":"2399 NORTH POINT BLVD","postalCode":"21222","storeNo":"02435","manager":"KADESTRICK RUSSELL"},
        {"city":"PRINCE FREDERICK MD","phone":"410-535-3790","address":"150 SOLOMONS ISLAND RD N","postalCode":"20678","storeNo":"01716","manager":"DOROTHY DIXON"},
        {"city":"FREDERICK MD","phone":"301-631-0805","address":"7400 GUILFORD DR","postalCode":"21704","storeNo":"02233","manager":"RUDOLPH RHODD"},
        {"city":"BALTIMORE MD","phone":"410-687-4858","address":"6420 PETRIE WAY","postalCode":"21237","storeNo":"03489","manager":"OPAL MITCHELL"},
        {"city":"STAFFORD VA","phone":"540-720-0059","address":"217 GARRISONVILLE RD","postalCode":"22554","storeNo":"02438","manager":"BOBBY DRIVER"},
        {"city":"FREDERICK MD","phone":"301-644-2440","address":"2421 MONOCACY BLVD","postalCode":"21701","storeNo":"02756","manager":"JOSE CISNEROS"},
        {"city":"NOTTINGHAM MD","phone":"410-882-9815","address":"8118 PERRY HILLS CT","postalCode":"21236","storeNo":"02045","manager":"NANCY VINTON"},
        {"city":"STAFFORD VA","phone":"540-602-6125","address":"14 N STAFFORD COMPLEX CTR","postalCode":"22556","storeNo":"06936","manager":"JANETTE JACKSON"},
        {"city":"COCKEYSVILLE MD","phone":"410-628-0980","address":"1 FRANKEL WAY","postalCode":"21030","storeNo":"02577","manager":"EDDIE ORTEGA"},
        {"city":"KING GEORGE VA","phone":"540-413-3037","address":"16375 MERCHANT LN","postalCode":"22485","storeNo":"05779","manager":"LISA HANSON"},
        {"city":"BALTIMORE MD","phone":"410-335-5669","address":"112 CARROLL ISLAND RD","postalCode":"21220","storeNo":"03481","manager":"STEIN NIELSEN"},
        {"city":"WESTMINSTER MD","phone":"410-857-8307","address":"280 WOODWARD RD","postalCode":"21157","storeNo":"01867","manager":"WILBERT LUCAS"},
        {"city":"WARRENTON VA","phone":"540-341-3568","address":"700 JAMES MADISON HWY","postalCode":"20186","storeNo":"02437","manager":"RAYMOND MALONE"},
        {"city":"SECAUCUS NJ","phone":"201-325-9280","address":"400 PARK PL","postalCode":"07094","storeNo":"03520","manager":"AMY HENRY"},
        {"city":"NORTH BERGEN NJ","phone":"201-758-2810","address":"2100 88TH ST","postalCode":"07047","storeNo":"03795","manager":"JANIECE JACKSON"},
        {"city":"KEARNY NJ","phone":"201-955-0280","address":"150 HARRISON AVE","postalCode":"07032","storeNo":"05447","manager":"DONNA SANTY"},
        {"city":"BAYONNE NJ","phone":"201-620-6137","address":"500 BAYONNE CROSSING WAY","postalCode":"07002","storeNo":"05867","manager":"JWAADE HAZZIEZ"},
        {"city":"TETERBORO NJ","phone":"201-375-4002","address":"1 TETERBORO LANDING DR","postalCode":"07608","storeNo":"03159","manager":"JESSE KROHN"},
        {"city":"GARFIELD NJ","phone":"973-330-3550","address":"174 PASSAIC ST","postalCode":"07026","storeNo":"05752","manager":"DAPHANIE EVANS"},
        {"city":"SADDLE BROOK NJ","phone":"201-226-0575","address":"189 US HIGHWAY 46","postalCode":"07663","storeNo":"03562","manager":"THOMAS KNUDSEN"},
        {"city":"VALLEY STREAM NY","phone":"516-887-0127","address":"77 GREEN ACRES RD","postalCode":"11581","storeNo":"05293","manager":"DAVID FIE"},
        {"city":"LINDEN NJ","phone":"908-474-9055","address":"1050 W EDGAR RD","postalCode":"07036","storeNo":"03469","manager":"SCOTTY SCOTTY"},
        {"city":"UNION NJ","phone":"908-624-0644","address":"900 SPRINGFIELD RD","postalCode":"07083","storeNo":"03292","manager":"BENJAMIN GREEK"},
        {"city":"WOODBRIDGE NJ","phone":"732-826-4652","address":"360 US HIGHWAY 9 ROUTE N","postalCode":"07095","storeNo":"05281","manager":"WILDER SALAZAR"},
        {"city":"WESTBURY NY","phone":"516-794-7280","address":"1220 OLD COUNTRY RD","postalCode":"11590","storeNo":"03420","manager":"PATRICIA VALDIVIA"},
        {"city":"UNIONDALE NY","phone":"516-505-1508","address":"1123 JERUSALEM AVE","postalCode":"11553","storeNo":"02583","manager":"JOSHUA WORKMAN"},
        {"city":"WATCHUNG NJ","phone":"908-756-1925","address":"1501 US HIGHWAY 22","postalCode":"07069","storeNo":"05111","manager":"WHEELER MELANIE"},
        {"city":"RIVERDALE NJ","phone":"973-835-5812","address":"48 STATE RT 23","postalCode":"07457","storeNo":"03443","manager":"ANTHONY FARRAH"},
        {"city":"BOONTON NJ","phone":"973-299-3943","address":"300 WOOTTON ST","postalCode":"07005","storeNo":"05077","manager":"MARCIA ALLEN"},
        {"city":"EAST MEADOW NY","phone":"516-579-3307","address":"2465 HEMPSTEAD TPKE","postalCode":"11554","storeNo":"02916","manager":"ELOISE PELAEZ"},
        {"city":"CEDAR KNOLLS NJ","phone":"973-889-8646","address":"235 RIDGEDALE AVE","postalCode":"07927","storeNo":"03598","manager":"MICHAEL BRIERE"},
        {"city":"EDISON NJ","phone":"732-650-1297","address":"2220 STATE ROUTE 27","postalCode":"08817","storeNo":"05201","manager":"IVONNE GARCIA"},
        {"city":"LEVITTOWN NY","phone":"516-490-0438","address":"3335 HEMPSTEAD TPKE","postalCode":"11756","storeNo":"04184","manager":"ASANTA HARRISON"},
        {"city":"OLD BRIDGE NJ","phone":"732-525-8030","address":"1126 US HIGHWAY 9","postalCode":"08857","storeNo":"02825","manager":"YOE FOYO"},
        {"city":"SUFFERN NY","phone":"845-368-4705","address":"250 ROUTE 59","postalCode":"10901","storeNo":"02905","manager":"ALONYA JONES"},
        {"city":"PISCATAWAY NJ","phone":"732-562-1771","address":"1303 CENTENNIAL AVE","postalCode":"08854","storeNo":"02633","manager":"CHRISTINA DAVIS"},
        {"city":"EAST BRUNSWICK NJ","phone":"732-387-1059","address":"290 STATE ROUTE 18","postalCode":"08816","storeNo":"03078","manager":"TERRY BOULT"},
        {"city":"OLD BRIDGE NJ","phone":"732-955-0139","address":"2825 STATE ROUTE 18","postalCode":"08857","storeNo":"04153","manager":"JOSE BURGOS"},
        {"city":"FARMINGDALE NY","phone":"631-752-8768","address":"965 BROADHOLLOW RD","postalCode":"11735","storeNo":"05295","manager":"EDWARD HUGHEY"},
        {"city":"NORTH BRUNSWICK NJ","phone":"732-545-4499","address":"979 ROUTE 1","postalCode":"08902","storeNo":"02003","manager":"JASMINE BENNETT"},
        {"city":"ROCKAWAY NJ","phone":"973-361-6089","address":"220 ENTERPRISE DR","postalCode":"07866","storeNo":"05178","manager":"BERNARD CROCKETT"},
        {"city":"MANVILLE NJ","phone":"908-575-8997","address":"100 NORTH MAIN STREET","postalCode":"08835","storeNo":"02651","manager":"CHRISTAL WHITE"},
        {"city":"LEDGEWOOD NJ","phone":"973-598-4088","address":"461 RT 10 SUITE A100","postalCode":"07852","storeNo":"03912","manager":"KHRISTNA POULIOT"},
        {"city":"NEPTUNE NJ","phone":"732-922-8084","address":"3575 STATE ROUTE 66","postalCode":"07753","storeNo":"05142","manager":"JAMES WARD"},
        {"city":"COMMACK NY","phone":"631-864-0806","address":"85 CROOKED HILL RD","postalCode":"11725","storeNo":"05202","manager":"VALERIE JAMES"},
        {"city":"FREEHOLD NJ","phone":"732-780-3048","address":"326 W MAIN ST","postalCode":"07728","storeNo":"03236","manager":"DEMETRICE MORTON"},
        {"city":"FLANDERS NJ","phone":"973-347-7400","address":"40 INTERNATIONAL DR S","postalCode":"07836","storeNo":"03291","manager":"ISAAC ARGUETA"},
        {"city":"FRANKLIN NJ","phone":"973-209-4242","address":"230 STATE HIGHWAY","postalCode":"07416","storeNo":"02090","manager":"TIERRA WATTS"},
        {"city":"MOHEGAN LAKE NY","phone":"914-526-1100","address":"3133 E MAIN ST","postalCode":"10547","storeNo":"02531","manager":"PEDRO CERVANTES"},
        {"city":"MONROE NY","phone":"845-783-3505","address":"288 LARKIN DR","postalCode":"10950","storeNo":"02637","manager":"CHARLOTTE BELL"},
        {"city":"NORWALK CT","phone":"203-846-4514","address":"650 MAIN AVE","postalCode":"06851","storeNo":"02954","manager":"DONALD MOWREY"},
        {"city":"ISLANDIA NY","phone":"631-851-0468","address":"1850 VETERANS HWY","postalCode":"11749","storeNo":"02917","manager":"SARAH BENNETT"},
        {"city":"HOWELL NJ","phone":"732-886-9100","address":"4900 US HIGHWAY 9","postalCode":"07731","storeNo":"02195","manager":"RUTH GOOD"},
        {"city":"HACKETTSTOWN NJ","phone":"908-979-9342","address":"1885 STATE ROUTE 57 STE 100","postalCode":"07840","storeNo":"02503","manager":"BILLY RAY"},
        {"city":"EAST WINDSOR NJ","phone":"609-443-6159","address":"839 US HIGHWAY 130","postalCode":"08520","storeNo":"03266","manager":"RICHARD FAIRLEY"},
        {"city":"NEWTON NJ","phone":"973-300-1859","address":"26 HAMPTON HOUSE RD","postalCode":"07860","storeNo":"02604","manager":"DAMINA JOHNSON"},
        {"city":"BRICK NJ","phone":"732-840-7772","address":"1872 ROUTE 88","postalCode":"08724","storeNo":"01977","manager":"BRENDA FELTON"},
        {"city":"FLEMINGTON NJ","phone":"908-788-6769","address":"152 ROUTE 31 NORTH","postalCode":"08822","storeNo":"02294","manager":"MARTHA RIVERA"},
        {"city":"CLINTON NJ","phone":"908-730-8665","address":"ROUTE 513 AND I78","postalCode":"08809","storeNo":"02582","manager":"MICHAEL ELLIS"},
        {"city":"CENTEREACH NY","phone":"631-467-4825","address":"161 CENTEREACH MALL","postalCode":"11720","storeNo":"02286","manager":"JOHN TAYLOR"},
        {"city":"EAST SETAUKET NY","phone":"631-474-3287","address":"3990 NESCONSET HWY","postalCode":"11733","storeNo":"02915","manager":"DENISE YOUNG"}],
      productData:[
        {"name":"SO DELICIOUS","price":"6.49","productCode":"074447347711"},
        {"name":"TRU FRU ","price":"7.99","productCode":"085001211751"},
        {"name":"SO DELICIOUS","price":"4.49","productCode":"074447347011"},
        {"name":"HALO TOP","price":"5.99","productCode":"085808900322"},
        {"name":"SO DELICIOUS","price":"4.49","productCode":"074447347621"},
        {"name":"SO DELICIOUS","price":"4.49","productCode":"074447300028"},
        {"name":"MAGNUM DOUBLE","price":"5.99","productCode":"007756713228"},
        {"name":"SO DELICIOUS","price":"6.49","productCode":"074447349213"},
        {"name":"MAGNUM DARK","price":"5.99","productCode":"007756713227"},
        {"name":"TRU FRU","price":"7.99","productCode":"085001211716"},
        {"name":"TRU FRU","price":"7.99","productCode":"085024100884"},
        {"name":"TRU FRU","price":"7.99","productCode":"085024100883"},
        {"name":"HALO TOP","price":"5.99","productCode":"085808900313"},
        {"name":"SO DELICIOUS","price":"4.99","productCode":"074447347055"},
        {"name":"HALO TOP","price":"5.99","productCode":"085808900310"},
        {"name":"HALO TOP","price":"5.99","productCode":"085808900335"},
        {"name":"MAGNUM","price":"7.79","productCode":"007756700368"},
        {"name":"TRU FRU","price":"16.99","productCode":"085001211727"},
        {"name":"SO DELICIOUS","price":"6.49","productCode":"074447347211"},
        {"name":"MAGNUM","price":"5.99","productCode":"07756727105"},
        {"name":"HALO TOP","price":"5.99","productCode":"085808900315"},
        {"name":"HALO TOP","price":"5.99","productCode":"085808900311"},
        {"name":"M&M'S PEANUT","price":"4.99","productCode":"004000060557"},
        {"name":"CHEEZ IT","price":"4.99","productCode":"002410012424"},
        {"name":"TATE'S BAKE","price":"5.99","productCode":"081029100771"},
        {"name":"CLUB CRACKERS","price":"3.99","productCode":"003010012977"},
        {"name":"CHIPS AHOY","price":"4.99","productCode":"004400007815"},
        {"name":"CHEEZ-IT","price":"4.99","productCode":"002410012418"},
        {"name":"RITZ HOLIDAY","price":"5.79","productCode":"004400007905"},
        {"name":"OREO SANDWICH","price":"5.49","productCode":"004400001473"},
        {"name":"RITZ FUDGE","price":"5.49","productCode":"004400008042"},
        {"name":"TATE'S","price":"5.99","productCode":"081029100797"},
        {"name":"SKITTLES","price":"6.99","productCode":"002200030128"},
        {"name":"SKITTLES","price":"6.99","productCode":"002200030125"},
        {"name":"CHEEZ-IT","price":"4.99","productCode":"002410010685"},
        {"name":"PREMIUM","price":"4.79","productCode":"004400000057"},
        {"name":"WELCHS MIXED","price":"9.99","productCode":"003485684068"},
        {"name":"OREO","price":"6.79","productCode":"004400006023"},
        {"name":"CHIPS AHOY","price":"4.99","productCode":"004400003219"},
        {"name":"RUFFLES","price":"6.49","productCode":"002840051773"},
        {"name":"CHEETOS","price":"6.49","productCode":"002840031409"},
        {"name":"RITZ ORIGINAL","price":"5.79","productCode":"004400003111"},
        {"name":"HONEY MAID","price":"4.99","productCode":"004400000463"},
        {"name":"LAYS POTATO","price":"5.99","productCode":"002840031041"},
        {"name":"TOWN HOUSE","price":"3.99","productCode":"003010010055"},
        {"name":"NUTTER BUTTER","price":"5.99","productCode":"004400004631"},
        {"name":"STARRY","price":"2.79","productCode":"001200024029"},
        {"name":"LA COLOMBE","price":"2.61","productCode":"060491300488"},
        {"name":"LA COLOMBE","price":"2.61","productCode":"060491300490"},
        {"name":"BODYARMOR","price":"7.99","productCode":"085817600280"},
        {"name":"GATORADE","price":"16.15","productCode":"005200012324"},
        {"name":"CELSIUS","price":"23.99","productCode":"088939200093"},
        {"name":"DIET COKE","price":"10.99","productCode":"004900002891"},
        {"name":"COCA-COLA","price":"12.43","productCode":"004900002890"},
        {"name":"FOLGERS","price":"18.99","productCode":"002550030418"},
        {"name":"SIGNATURE","price":"3.49","productCode":"002113031008"},
        {"name":"SIGNATURE","price":"4.50","productCode":"002113029485"},
        {"name":"RED BULL","price":"3.12","productCode":"061126981899"},
        {"name":"TOPO CHICO","price":"15.99","productCode":"002113605046"},
        {"name":"PEPSI SODA","price":"12.43","productCode":"001200080994"},
        {"name":"CRAISINS","price":"2.99","productCode":"003120000159"},
        {"name":"LAYS POTATO","price":"4.99","productCode":"002840004380"},
        {"name":"SUN-MAID","price":"3.49","productCode":"004114312010"},
        {"name":"STACY'S","price":"5.99","productCode":"002840056463"},
        {"name":"BLUE DIAMOND","price":"5.49","productCode":"004157002970"},
        {"name":"O ORGANICS","price":"8.99","productCode":"007989315826"},
        {"name":"FRITOS","price":"5.79","productCode":"002840058932"},
        {"name":"RITZ","price":"5.79","productCode":"004400003111"},
        {"name":"VICENZI","price":"3.99","productCode":"070175068386"},
        {"name":"MARIANI","price":"3.50","productCode":"007102214009"},
        {"name":"SMARTFOOD","price":"5.19","productCode":"002840031401"},
        {"name":"CASA SANCHEZ","price":"5.99","productCode":"007873200413"},
        {"name":"SIGNATURE","price":"3.99","productCode":"002113052239"},
        {"name":"SIGNATURE","price":"6.99","productCode":"002113012896"},
        {"name":"SNYDER'S","price":"4.49","productCode":"007797508006"},
        {"name":"O ORGANIC","price":"3.99","productCode":"007989312530"},
        {"name":"LOTUS COOKIES","price":"3.00","productCode":"002178891629"},
        {"name":"CHEETOS","price":"4.99","productCode":"002840007165"},
        {"name":"HELUVA GOOD","price":"1.99","productCode":"007357013000"},
        {"name":"NILLA WAFERS","price":"5.49","productCode":"004400002734"},
        {"name":"SIGNATURE","price":"2.99","productCode":"002113052223"},
        {"name":"SUN-MAID","price":"4.49","productCode":"004114315160"},
        {"name":"NUTELLA","price":"5.99","productCode":"000980083003"},
        {"name":"TOSTITOS SALSA","price":"4.99","productCode":"002840005597"},
        {"name":"FRITOS SCOOPS","price":"6.49","productCode":"002840031403"},
        {"name":"TOOTSIE","price":"13.99","productCode":"007172001752"},
        {"name":"PLANTERS","price":"3.50","productCode":"002900007325"},
        {"name":"FRITOS CORN","price":"5.79","productCode":"002840058929"},
        {"name":"PREMIUM","price":"4.79","productCode":"004400000057"},
        {"name":"SMARTFOOD","price":"5.19","productCode":"002840031400"},
        {"name":"SUN-MAID","price":"6.99","productCode":"004114302917"},
        {"name":"SIM MILL","price":"4.99","productCode":"085606900519"},
        {"name":"DATES PITTED","price":"11.99","productCode":"007102232119"},
        {"name":"CASA SANCHEZ","price":"6.99","productCode":"007873200400"},
        {"name":"PLANTERS","price":"5.99","productCode":"002900001665"},
        {"name":"SMARTFOOD","price":"7.00","productCode":"002840067909"},
        {"name":"OREO","price":"4.99","productCode":"004400006011"},
        {"name":"CHESTERS ","price":"2.99","productCode":"002840070569"},
        {"name":"GUACAMOLE","price":"5.99","productCode":"081004159906"},
        {"name":"MARIANI","price":"2.50","productCode":"007102234011"},
        {"name":"BREYERS","price":"5.50","productCode":"077567254238"}],
      productIceData:[
        {"name":"FISHER-PRICE","price":"28.99","productCode":"007204103330"},
        {"name":"FISHER-PRICE","price":"39.99","productCode":"007204103343"},
        {"name":"FISHER-PRICE","price":"46.49","productCode":"007204105320"},
        {"name":"FISHER-PRICE","price":"58.99","productCode":"007204203636"},
        {"name":"FISHER-PRICE","price":"72.99","productCode":"007204103330"},
        {"name":"FISHER-PRICE","price":"78.99","productCode":"007204603835"},
        {"name":"FISHER-PRICE","price":"88.99","productCode":"007204803396"},
        {"name":"FISHER-PRICE","price":"93.49","productCode":"007204903758"},
        {"name":"FISHER-PRICE","price":"96.99","productCode":"007204183365"},
        {"name":"FISHER-PRICE","price":"98.99","productCode":"007204103370"},
        {"name":"FISHER-PRICE","price":"100.99","productCode":"007204603885"},
        {"name":"FISHER-PRICE","price":"110.99","productCode":"072048033596"},
        {"name":"FISHER-PRICE","price":"118.49","productCode":"007204903763"},
        {"name":"FISHER-PRICE","price":"128.99","productCode":"007204183335"},
        {"name":"FISHER-PRICE","price":"129.99","productCode":"007204173339"}]
    }
  },
  components: {
  },
  watch: {
    // 监听 newOrderId 变量的变化
    newOrderId(newValue) {
      if (newValue) {
        // console.log('newOrderId 变化，启动定时任务');
        this.startTimer();  // 启动定时任务
      } else {
        this.stopTimer();   // 如果 newOrderId 为空，停止定时任务
      }
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  mounted() {
    this.gensFuncs();
  },
  methods: {
    pageRowsInput(event) {
      this.pageRows = event.target.value.slice(0, 3); // 限制为10位
    },
    handleInput(event) {
      this.card4No = event.target.value.slice(0, 4); // 限制为10位
    },

    printElement() {
      console.log("初始化")
      if (typeof getLodop === 'undefined') {
        console.error('LODOP未加载，请确保已正确引入 LodopFuncs.js');
        return;
      }
      for (let p = 1; p <= this.pageRows; p++) {
        this.gensFuncs();
        let LODOP = getLodop();
        if(LODOP){
          LODOP.PRINT_INIT('');
          LODOP.SET_PRINTER_INDEX(-1);
          LODOP.SET_PRINT_STYLE("FontName", "Arial");
          LODOP.SET_PRINT_STYLE("FontSize", 8);
          LODOP.ADD_PRINT_IMAGE(20,40,200,50,"<img src='/image/walmart-logo.png'/>");
          LODOP.SET_PRINT_STYLEA(0,"Stretch",1);
          LODOP.ADD_PRINT_TEXT(75,10,240,80,this.phone+"  Mgr:"+this.manager);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_TEXT(90,10,240,80,this.address);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_TEXT(105,10,240,80,this.city+"  "+this.zip);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_TEXT(120,10,240,80,"ST# "+this.storeNo+ "  OP# "+this.codeFrist+ "  TE# "+this.codeSecend+ "  TR# "+this.codeThird);
          var iCurLine = 120;
          if(this.showProduceData.length>0)
            for (var i = 0; i < this.showProduceData.length; i++) {
            iCurLine = iCurLine+15;
            LODOP.ADD_PRINT_TEXT(iCurLine,10,300,80,this.showProduceData[i].name);
            LODOP.ADD_PRINT_TEXT(iCurLine,120,300,80,this.showProduceData[i].productCode);
            LODOP.ADD_PRINT_TEXT(iCurLine,215,300,80,this.showProduceData[i].price +' X');
          }
          LODOP.ADD_PRINT_TEXT(iCurLine+15,100,300,80,"SUBTOTAL");
          LODOP.ADD_PRINT_TEXT(iCurLine+15,215,300,80,this.subTotalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+30,70,300,80,"TAX  1    7.000 %");
          LODOP.ADD_PRINT_TEXT(iCurLine+30,215,300,80,this.taxPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+45,120,300,80,"TOTAL");
          LODOP.ADD_PRINT_TEXT(iCurLine+45,215,300,80,this.totalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+60,85,300,80,"DEBIT   TENT");
          LODOP.ADD_PRINT_TEXT(iCurLine+60,215,300,80,this.totalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+75,80,300,80,"CHANGE   DUE");
          LODOP.ADD_PRINT_TEXT(iCurLine+75,215,300,80,'0.00');
          LODOP.ADD_PRINT_TEXT(iCurLine+90,10,300,80,"EFT  DEBIT       PAY  FROM  PRINSRY");
          LODOP.ADD_PRINT_TEXT(iCurLine+105,20,300,80,this.totalPrice+"     TOTAL    PURCHASE");
          LODOP.ADD_PRINT_TEXT(iCurLine+120,10,300,80,"US  DEBIT");
          LODOP.ADD_PRINT_TEXT(iCurLine+120,130,300,80,"****  ****  ****  "+this.card4No);
          LODOP.ADD_PRINT_TEXT(iCurLine+135,10,300,80,"REF   #  "+this.refNo);
          LODOP.ADD_PRINT_TEXT(iCurLine+150,10,300,80,"US  DEBIT");
          LODOP.ADD_PRINT_TEXT(iCurLine+165,10,300,80,"AID  A00000000"+this.aId5No);
          LODOP.ADD_PRINT_TEXT(iCurLine+180,10,300,80,"TERNINAL   #  "+this.issuer);
          LODOP.SET_PRINT_STYLE("FontSize", 12);
          LODOP.ADD_PRINT_TEXT(iCurLine+202,10,240,80,"# ITEMS SOLD   "+this.iptRows);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);

          LODOP.SET_PRINT_STYLE("FontSize", 8);
          LODOP.ADD_PRINT_TEXT(iCurLine+230,10,240,80,"TC# "+this.barcodeValue);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_BARCODE(iCurLine+245,10,"70mm",38, 'EAN128C', this.barcodeValue)
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
          LODOP.ADD_PRINT_TEXT(iCurLine+290,20,240,80,"Low Prices You Can Trust. Every Day.");
          LODOP.SET_PRINT_STYLEA(0,"LetterSpacing",1);
          LODOP.ADD_PRINT_TEXT(iCurLine+305,10,240,80,this.orderTempDate+"   "+this.orderDate);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_BARCODE(iCurLine+325,100,100,100,"QRCode","https://www.walmart.com");
          LODOP.PRINT();
          //LODOP.PREVIEW();

          setTimeout(function() {
          }, 1000);
        }else{
          console.log("LODOP 无法获取")
        }
      }
    },

    gensFuncs(){
      this.showProduceData = [];
      this.codeFrist = '00'+Math.floor(Math.random() * (9999 - 2001 + 1) + 2001);
      this.codeSecend = Math.floor(Math.random() * (99 - 11 + 1) + 11);
      this.codeThird= '0'+Math.floor(Math.random() * (9999 - 2001 + 1) + 2001);
      this.orderDate = this.getRandomDateTime();
      this.orderTempDate = this.getRandomTempYear();
      this.card4No = Math.floor(Math.random() * (9999 - 1001 + 1) + 1001);
      var index = Math.floor(Math.random() * this.storeData.length);
      this.storeNo =  this.storeData[index].storeNo;
      this.address =  this.storeData[index].address;
      this.tempAddress =  this.address;
      this.manager =  this.storeData[index].manager;
      this.phone =  this.storeData[index].phone;
      this.city =  this.storeData[index].city;
      this.zip =  this.storeData[index].postalCode;
      this.iptRows = Math.floor(Math.random() * (3 - 1 + 1)) + 1;
      this.iceRows = 1;
      this.iptRowsFull = this.iptRows < 10 ? '0' + this.iptRows : this.iptRows.toString();

      var difference = 0;
      var startCount = 0;
      var endCount = 0;
      difference =  this.iptRows - this.iceRows;
      var pLength =  this.productData.length
      var pIceLeng = this.productIceData.length
      console.log("difference:"+difference)
      var tempPrice = 0.00;
      if(difference>0){
        for (let i = 1; i <= difference; i++) {
          var pIindex = Math.floor(Math.random() * (pLength-1));
          this.showProduceData.push(this.productData[pIindex])
          tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
        }
      }
      var pIcaIndex = Math.floor(Math.random() * pIceLeng);
      this.showProduceData.push(this.productIceData[pIcaIndex])
      tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);

      tempPrice = parseFloat(tempPrice).toFixed(2);
      console.log("showProduceData:"+this.showProduceData)
      //单号
      //单号
      this.seq4No = Math.floor(Math.random() * (6000 - 3000 + 1) + 3000);
      let num2 = Math.floor(Math.random() * (99 - 11 + 1) + 11);
      let num3 = Math.floor(Math.random() * (999999 - 110000 + 1) + 110000);
      this.seqNo = this.seq4No+""+num2+""+num3;
      //条形码
      let barcodeFrist ="99";
      let barcodeSecond = Math.random().toString().slice(2, 7);
      let barcodeThired = this.storeNo;
      if(barcodeThired&&barcodeThired.length>=5){
      }else{
        barcodeThired =   ("00000"+String(barcodeThired)).substr(-5)
      }
      this.barcodeValue = barcodeFrist+''+barcodeThired+""+num2+""+this.seq4No+""+this.iptRowsFull+""+barcodeSecond;
      this.barcodeValue = this.barcodeValue.substring(0, 20);
      this.barcodeValue = this.barcodeValue.replace(/(.{4})(?=.)/g, '$1 ').replace(/(.*)-$/, '$1');

      this.code6No = Math.random().toString().slice(2, 8);
      this.refNo = Math.floor(Math.random() * (1999 - 1001 + 1) + 1001)+"00"+Math.random().toString().slice(2, 8);
      //打印机编号后5位
      this.aId5No = Math.floor(Math.random() * (926150 - 925160 + 1)) + 925160;
      //行数随机
      this.issuer = this.generateRandomString(8);
      this.genRows= parseInt(this.iptRows);
      this.subTotalPrice = tempPrice;
      this.taxPrice = parseFloat(tempPrice * this.tax10).toFixed(2);
      this.totalPrice = parseFloat(this.subTotalPrice) + parseFloat(this.taxPrice);
      this.totalPrice = parseFloat(this.totalPrice).toFixed(2);
      this.showIptRows = this.iptRows < 10 ? this.iptRows.toString()+'\xa0'+'\xa0' : this.iptRows.toString();
      this.generateBarcode();
    },
    generateRandomString(num) {
      let digitsCount = 0;
      let result = '';

      while (result.length < num) {
        const randomChar = Math.random() >= 0.5 ? this.getRandomChar() : this.getRandomDigit();
        result += randomChar;
        if (randomChar >= '0' && randomChar <= '9') {
          digitsCount++;
        }
      }
      // 确保至少有4位数字
      while (digitsCount < 4  ) {
        const randomIndex = Math.floor(Math.random() * result.length);
        const charAtIndex = result.charAt(randomIndex);
        if (charAtIndex >= '0' && charAtIndex <= '9') {
          result = result.slice(0, randomIndex) + this.getRandomDigit() + result.slice(randomIndex);
          digitsCount++;
        }
      }
      return result;
    },
    getRandomChar() {
      return String.fromCharCode(65 + Math.floor(Math.random() * 26)); // A-Z
    },
    getRandomDigit() {
      return String.fromCharCode(48 + Math.floor(Math.random() * 10)); // 0-9
    },

    getRandomDateTime() {
      const startYear = 2017;
      const endYear = 2019;
      const startTime = new Date(startYear, 4, 12); // 2018年的6月30日
      const diff = new Date(endYear, 3, 11) - startTime; // 2024年的7月14日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      var year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }

      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      var  tempHours =  Math.floor(Math.random() * (19 - 12 + 1)) + 12;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');
      const seconds = String(randomDate.getSeconds()).padStart(2, '0');
      var tempYear = year.toString();
      tempHours = tempHours < 10 ? "0" + tempHours:tempHours;
      return `${tempHours}:${minutes}:${seconds}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },

    getRandomTempYear() {
      const startYear = 2017;
      const endYear = 2019;
      const startTime = new Date(startYear, 4, 12); // 2018年的6月30日
      const diff = new Date(endYear, 3, 11) - startTime; // 2024年的7月14日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      var year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }

      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      var  tempHours =  Math.floor(Math.random() * (19 - 12 + 1)) + 12;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');
      const seconds = String(randomDate.getSeconds()).padStart(2, '0');
      var tempYear = year.toString();
      return `${month}/${day}/${tempYear}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },
    generateBarcode() {
      // 使用 JsBarcode 库生成条形码
      JsBarcode(this.$refs.barcode, this.barcodeValue, {
        format: "CODE128", // 条形码格式，可选 CODE128、EAN 等
        lineColor: "#000",
        width: 1,
        height: 40,
        displayValue: false // 是否显示条形码值
      });
    },
    // 判断设备是否为移动端
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 使用正则检查 userAgent 中是否包含手机/平板的关键字
      return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile|webos.*mobile/i.test(userAgent);
    },
    isMobileBrowerSize() {
      return window.innerWidth <= 768;  // 视窗宽度小于等于 768px 视为移动端
    },

  },
  beforeDestroy() {

  },
  created() {

  }
}
</script>

<style scoped>
@media print {
  body {
    font-family: "Times New Roman", Times, serif;
  }
}
</style>