npm <template>

  <div style="height: 100vh">
    <div style="padding-left: 15px;font-size: 18px">
      <p>卡号 ：<input v-model="card4No" style="width: 200px"  size="4" type="number" >
        <button @click ="gensFuncs()" style="margin-left:10px;width: 80px;background-color: #00afff;height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">生成</button>
        <button @click ="printElement()" style="margin-left:10px;width: 80px;background-color: #096c0b; height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">打印</button></p>
    </div>
    <div  style="background-color: #4AB7BD;height: auto;padding-top: 20px;">
      <div id="printContent" style="-webkit-font-smoothing:antialiased;font-family: 'Times New Roman', Times, serif;font-size: 14px;font-weight: lighter">
        <div   style="padding-bottom:70px;line-height:5px;font-size:13px; text-align: center;width: 300px;background-color: #FFFFFF;font-family: 'Times New Roman';margin: auto">
          <p style="padding-top: 10px;"><img src='http://149.115.234.7:16587/wholeFoods_logo.png' width="250px"/></p>
          <div style="text-align: left; margin-top: 15px">
            <p class="container" style="padding-left: 10px; font-weight: bold; text-align: center;" ><span v-html="distr+ '&nbsp;'.repeat(2*1)+distrSu+'&nbsp;'.repeat(2*1)+phone"></span></p>
            <p style="padding-left: 5px; font-weight: bold;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="address"></span></p>
            <p style="padding-left: 5px; font-weight: bold;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="city+ '&nbsp;'.repeat(2*1)+zip+'&nbsp;'.repeat(2*1)+zipSu"></span></p>
            <div style="font-size:11px;" v-if="showProduceData" v-for="(item, index) in showProduceData" :key="index">
              <p class="container2" style="padding-left: 10px;"><span style="display: inline-block;width: 190px; text-align: left; " v-html="item.name"></span><span style="display: inline-block;width: 80px; text-align: right; " v-html="'$'+ item.price+'&nbsp;'.repeat(4*1)+'F'"></span><span style="display: inline-block;width: 20px;" v-html="'&nbsp;'.repeat(4*1)"></span></p>
            </div>

            <p style="padding-left: 50px; " v-html="'Subtotal：' + '&nbsp;'.repeat(40*1) +'$'+totalPrice"></p>
            <p style="padding-left: 50px;" v-html="'Net Sales：'+ '&nbsp;'.repeat(38*1) +'$'+totalPrice"></p>
            <p style="padding-left: 70px;font-weight: bold;"><span  v-html="'Total：' + '&nbsp;'.repeat(38*1)"></span><span v-html="'$'+totalPrice"></span></p>
            <p style="padding-left: 70px;font-weight: bold;"><span  v-html="'Sold Items：' + '&nbsp;'.repeat(37*1)+iptRows"/></p>
            <p style="padding-left: 50px;" v-html="'Paid：'"></p>
            <p style="padding-left: 50px;" v-html="'Cash：'+ '&nbsp;'.repeat(45*1)+'$'+cashAmount"></p>
            <p style="padding-left: 50px;" v-html="'Change：'+ '&nbsp;'.repeat(41*1)+'-$'+changeAmount"></p>
            <p style="padding-top: 1px;"></p>
            <p style="padding-left: 10px;  word-spacing: 5px; letter-spacing: 1px; text-align: center;"><span v-html="'RETURNS: All returns require a receipt.'"></span></p>
            <p style="padding-left: 10px;  word-spacing: 3px; letter-spacing: 2px; text-align: center;"><span v-html="'No returns on items purchased after'"></span></p>
            <p style="padding-left: 10px;  word-spacing: 3px; letter-spacing: 2px; text-align: center;"><span v-html="'90 days. For additional information'"></span></p>
            <p style="padding-left: 10px;  word-spacing: 3px; letter-spacing: 2px; text-align: center;"><span v-html="'please visit wfm.com/returns.'"></span></p>
            <p style="padding-left: 10px; text-align: center;font-weight: bold;letter-spacing: 1px;"><span v-html="'****************************'"></span></p>
            <p style="padding-left: 10px; text-align: center;font-weight: bold;letter-spacing: 1px;"><span v-html="'Earn 5% back at Whole Foods Market'"></span></p>
            <p style="padding-left: 10px; text-align: center;font-weight: bold;letter-spacing: 1px;"><span v-html="'with Prime Visa'"></span></p>
            <p style="padding-left: 10px; text-align: center;font-weight: bold;letter-spacing: 1px;"><span v-html="'and an eligible Prime membership'"></span></p>
            <p style="padding-left: 10px; text-align: center;font-weight: bold;letter-spacing: 1px;"><span v-html="'Learn more at amazon.com/wfmvisa'"></span></p>
            <p style="padding-left: 10px; text-align: center;font-weight: bold;letter-spacing: 1px;"><span v-html="'****************************'"></span></p>
            <p style="padding-left: 10px; text-align: center;"><span v-html="'HOW WAS YOUR SHOPPING EXPERIENCE?'"></span></p>
            <p style="padding-left: 10px; word-spacing: 3px; letter-spacing: 2px; text-align: center;"><span v-html="'Go to:   http://www.wfm.com/feedback'"></span></p>
            <p style="padding-left: 10px; font-size:12px; text-align: center;"><span v-html="'ENTER FOR A CHANCE TO WIN A $250 GIFT CARD'"></span></p>

            <p style="padding-left: 25px;" v-html="codeFrist+ '&nbsp;'.repeat(6*1) +codeSecend+ '&nbsp;'.repeat(10*1) +orderTempDate+ '&nbsp;'.repeat(10*1) +orderDate"></p>
            <p style="text-align: center;margin-top: -11px"  > <svg ref="barcode"></svg></p>
            <p style="text-align: center; letter-spacing: 1px; font-size: 13px; margin-top: -10px" v-html="barcodeValue"></p>
            <p style="text-align: center; " v-html="issuer"></p>
            <p style="padding-left: 10px;padding-top: 8px; letter-spacing: 1px; text-align: center;"><span v-html="'Thanks for shopping at whole Foods Market'"></span></p>
            <p style="padding-left: 10px; letter-spacing: 1px; text-align: center;"><span v-html="'WholeFoodsMarket .com'"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>
<script>
import {initApkHub, newestApkUrl, queryApkHub} from "@/api/index";
import QRCode from 'qrcode'
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';
import { Circle } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';
import { Image as VanImage } from 'vant';
import { Card } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { Notify } from 'vant';
import JsBarcode from 'jsbarcode'
Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);
export default {
  data () {
    return {
      phone:'',
      city:'',
      address:'',
      distrSu:"ABN",
      distr:"Albany",
      zipSu:"1124",
      tempAddress:'',
      seq4No:'',
      issuer:'',
      iptRowsFull:'',
      pageRows:1,
      iptRows:3,
      showIptRows:'',
      itemPrice:5.50,
      tax10:0.02,
      taxPrice:0.0,
      totalPrice:0.0,
      cashAmount:100.00,
      changeAmount:0.00,
      subTotalPrice:0.0,
      genRows:3,
      card4No:9311,
      seqNo:304740,
      barcodeValue:'9900300230130764390',
      orderDate:'',
      orderTempDate:'',
      aId5No:925650,
      code7No:1911123,
      codeFrist:'205',
      codeSecend:'71776',
      codeThird:'0022',
      showProduceData:[{"name":"BMEAT PESTO SUN SAUSAGE","price":"9.99","productCode":"0077567254238"}],
      iceRows:0,
      zip:92565,
      storeData:[{"phone":"913-663-2951","address":"11900 Metcalf Ave","city":"Overland Park, KS","zip":"66213","zipSu":"1124","distrSu":"OVP","distr":"Overland Park New"},
        {"phone":"518-621-5300","address":"1425 Central Ave","city":"Albany, NY","zip":"12205","zipSu":"2702","distrSu":"ABN","distr":"Albany"},
        {"phone":"703-527-6596","address":"2700 Wilson Blvd","city":"Arlington, VA","zip":"22201","zipSu":"3804","distrSu":"ARL","distr":"Arlington"},
        {"phone":"225-218-0452","address":"7529 Corporate Blvd","city":"Baton Rouge, LA","zip":"70809","zipSu":"1176","distrSu":"BTR","distr":"Baton Rouge"},
        {"phone":"713-667-4090","address":"4004 Bellaire Blvd","city":"Houston, TX","zip":"77025","zipSu":"1122","distrSu":"BEL","distr":"Bellaire"},
        {"phone":"425-462-1400","address":"888 116th Ave Ne","city":"Bellevue, WA","zip":"98004","zipSu":"4607","distrSu":"BLV","distr":"Bellevue"},
        {"phone":"510-649-1333","address":"3000 Telegraph Ave","city":"Berkeley, CA","zip":"94705","zipSu":"2014","distrSu":"BRK","distr":"Berkeley"},
        {"phone":"510-809-8293","address":"1025 Gilman St","city":"Berkeley, CA","zip":"94710","zipSu":"1515","distrSu":"BRK","distr":"Berkeley"},
        {"phone":"408-266-3700","address":"1146 Blossom Hill Rd","city":"California, CA","zip":"95118","zipSu":"3113","distrSu":"BLL","distr":"Blossom Hill"},
        {"phone":"212-420-1320","address":"95 East Houston St","city":"New York, NY","zip":"10002","zipSu":"1002","distrSu":"HOU","distr":"Bowery"},
        {"phone":"714-528-7400","address":"3301 E Imperial Hwy","city":"Brea, CA","zip":"92823","zipSu":"6387","distrSu":"BRE","distr":"Brea"},
        {"phone":"714-594-6850","address":"10081 Adams Ave","city":"Huntington Beach, CA","zip":"92646","zipSu":"4904","distrSu":"HBC","distr":"Brookhurst"},
        {"phone":"718-907-3622","address":"214 3rd St","city":"Brooklyn, NY","zip":"11215","zipSu":"2702","distrSu":"BKN","distr":"Brooklyn"},
        {"phone":"917-728-5700","address":"1095 6th Ave","city":"New York, NY","zip":"10036","zipSu":"8002","distrSu":"BPK","distr":"Bryant Park"},
        {"phone":"818-239-5380","address":"3401 W Olive Ave","city":"Burbank, CA","zip":"91505","zipSu":"4615","distrSu":"BBK","distr":"Burbank"},
        {"phone":"602-761-4750","address":"4701 N 20th St","city":"Phoenix, AZ","zip":"85016","zipSu":"4786","distrSu":"CMA","distr":"Came lback"},
        {"phone":"408-371-5000","address":"1690 S Bascom Ave","city":"Campbell, CA","zip":"95008","zipSu":"0606","distrSu":"CBL","distr":"Campbell"},
        {"phone":"813-264-3600","address":"3802 Northdale Blvd","city":"Tampa, FL","zip":"33624","zipSu":"1833","distrSu":"CWD","distr":"Carrollwood"},
        {"phone":"415-282-4700","address":"2001 Market St","city":"San Francisco, CA","zip":"94114","zipSu":"1316","distrSu":"CAS","distr":"Castro"},
        {"phone":"480-821-9447","address":"2955 W Ray Rd","city":"Chandler, AZ","zip":"85224","zipSu":"3499","distrSu":"CHR","distr":"Chandler"},
        {"phone":"720-643-1750","address":"900 E 11th Ave","city":"Denver, CO","zip":"80218","zipSu":"2804","distrSu":"CCK","distr":"Cherry Creek"},
        {"phone":"720-941-4100","address":"2375 E 1st Ave","city":"Denver, CO","zip":"80206","zipSu":"5653","distrSu":"CCK","distr":"Cherry Creek"},
        {"phone":"303-733-6201","address":"1111 S Washington St","city":"Denver, CO","zip":"80210","zipSu":"1615","distrSu":"CCK","distr":"Cherry Creek"},
        {"phone":"720-572-8800","address":"1701 Wewatta St","city":"Denver, CO","zip":"80202","zipSu":"6315","distrSu":"CCK","distr":"Cherry Creek"},
        {"phone":"513-531-8015","address":"2693 Edmondson Rd","city":"Cincinnati, OH","zip":"45209","zipSu":"1910","distrSu":"CIN","distr":"Cincinnati"},
        {"phone":"214-273-0902","address":"1411 E Renner Rd","city":"Richardson, TX","zip":"75082","zipSu":"2005","distrSu":"CTL","distr":"Cityline "},
        {"phone":"303-691-0101","address":"870 S Colorado Blvd","city":"Glendale, CO","zip":"80246","zipSu":"2080","distrSu":"COL","distr":"Colorado Blvd"},
        {"phone":"470-237-7340","address":"1555 Church St","city":"Decatur, GA","zip":"30033","zipSu":"5921","distrSu":"DCT","distr":"Decatur"},
        {"phone":"847-444-1900","address":"760 Waukegan Rd","city":"Deerfield, IL","zip":"60015","zipSu":"4305","distrSu":"DRF","distr":"Deerfield"},
        {"phone":"850-269-7400","address":"4402 Legendary Dr","city":"Destin, FL","zip":"32541","zipSu":"5324","distrSu":"DES","distr":"Destin"},
        {"phone":"610-688-9400","address":"821 Lancaster Ave","city":"Wayne, PA","zip":"19087","zipSu":"2550","distrSu":"DEV","distr":"Devon"},
        {"phone":"213-873-4745","address":"788 S Grand Ave","city":"Los Angeles, CA","zip":"90017","zipSu":"3813","distrSu":"DLA","distr":"Downtown LA"},
        {"phone":"925-452-2226","address":"5200 Dublin Blvd","city":"Dublin, CA","zip":"94568","zipSu":"7133","distrSu":"DUN","distr":"Dublin"},
        {"phone":"919-286-2290","address":"621 Broad St","city":"Durham, NC","zip":"27705","zipSu":"4832","distrSu":"DRH","distr":"Durham"},
        {"phone":"915-236-1420","address":"100 Pitt St","city":"El Paso, TX","zip":"79912","zipSu":"4454","distrSu":"ELP","distr":"El Paso"},
        {"phone":"323-964-6800","address":"6350 W 3rd St","city":"Los Angeles, CA","zip":"90036","zipSu":"3155","distrSu":"FFX","distr":"Fairfax"},
        {"phone":"928-774-5747","address":"320 S Cambridge Ln","city":"Flagstaff, AZ","zip":"86001","zipSu":"5883","distrSu":"FLG","distr":"Flagstaff"},
        {"phone":"702-254-8655","address":"8855 W Charleston Blvd","city":"Las Vegas, NV","zip":"89117","zipSu":"5455","distrSu":"FTA","distr":"Fort Apache"},
        {"phone":"718-290-1010","address":"292 Ashland Pl","city":"Brooklyn, NY","zip":"11217","zipSu":"1110","distrSu":"FTG","distr":"Fort Greene"},
        {"phone":"510-401-5880","address":"3111 Mowry Ave","city":"Fremont, CA","zip":"94538","zipSu":"1505","distrSu":"FRE","distr":"Fremont Mowry"},
        {"phone":"336-398-2880","address":"3202 W Friendly Ave","city":"Greensboro, NC","zip":"27408","zipSu":"7604","distrSu":"GFR","distr":"Friendly Plaza "},
        {"phone":"954-398-2000","address":"2000 N Federal Hwy","city":"Fort Lauderdale, FL","zip":"33305","zipSu":"2530","distrSu":"FTL","distr":"Ft Lauderdale"},
        {"phone":"954-380-8440","address":"501 Se 17th St","city":"Fort Lauderdale, FL","zip":"33316","zipSu":"2539","distrSu":"FTL","distr":"17th Street"},
        {"phone":"682-316-8040","address":"3720 Vision Dr","city":"Fort Worth, TX","zip":"76109","zipSu":"1179","distrSu":"FTW","distr":"Ft Worth"},
        {"phone":"860-652-9800","address":"55 Welles St","city":"Glastonbury, CT","zip":"06033","zipSu":"2047","distrSu":"GLA","distr":"Glastonbury"},
        {"phone":"818-548-3695","address":"331 N Glendale Ave","city":"Glendale, CA","zip":"91206","zipSu":"3759","distrSu":"GLN","distr":"Glendale"},
        {"phone":"847-733-1600","address":"1640 Chicago Ave","city":"Evanston, IL","zip":"60201","zipSu":"4505","distrSu":"GBR","distr":"GREEB BAY ROAD"},
        {"phone":"847-424-5700","address":"2748 Green Bay Rd","city":"Evanston, IL","zip":"60201","zipSu":"1481","distrSu":"GBR","distr":"GREEB BAY ROAD"},
        {"phone":"413-586-9932","address":"327 Russell St","city":"Hadley, MA","zip":"01035","zipSu":"3535","distrSu":"HAD","distr":"Hadley"},
        {"phone":"415-876-6740","address":"690 Stanyan St","city":"San Francisco, CA","zip":"94117","zipSu":"1863","distrSu":"STN","distr":"Haight "},
        {"phone":"212-678-1940","address":"100 W 125th St","city":"New York, NY","zip":"10027","zipSu":"4693","distrSu":"HRM","distr":"Harlem"},
        {"phone":"510-834-9800","address":"230 Bay Pl","city":"Oakland, CA","zip":"94612","zipSu":"3805","distrSu":"HAR","distr":"Harrison"},
        {"phone":"702-361-8183","address":"100 S Green Valley Pkwy","city":"Henderson, NV","zip":"89012","zipSu":"3111","distrSu":"HEN","distr":"Henderson"},
        {"phone":"214-520-7993","address":"4100 Lomo Alto Dr","city":"Highland Park, TX","zip":"75219","zipSu":"1535","distrSu":"HPK","distr":"Highland Park"},
        {"phone":"619-294-2800","address":"711 University Ave","city":"San Diego, CA","zip":"92103","zipSu":"3202","distrSu":"HIL","distr":"Hillcrest"},
        {"phone":"617-904-1000","address":"348 Harrison Ave","city":"Boston, MA","zip":"02118","zipSu":"2237","distrSu":"INK","distr":"Ink Block"},
        {"phone":"617-723-0004","address":"181 Cambridge St","city":"Boston, MA","zip":"02114","zipSu":"2786","distrSu":"INK","distr":"Ink Block"},
        {"phone":"617-375-1010","address":"15 Westland Ave","city":"Boston, MA","zip":"02115","zipSu":"4558","distrSu":"INK","distr":"Ink Block"},
        {"phone":"206-352-5440","address":"2001 15th Ave W","city":"Seattle, WA","zip":"98119","zipSu":"2995","distrSu":"INT","distr":"Interbay "},
        {"phone":"949-777-8500","address":"8525 Irvine Center Dr","city":"Irvine, CA","zip":"92618","zipSu":"4298","distrSu":"IRV","distr":"Irvine"},
        {"phone":"516-932-1733","address":"429 North Broadway","city":"Jericho, NY","zip":"11753","zipSu":"2106","distrSu":"JER","distr":"Jericho"},
        {"phone":"301-258-9500","address":"316 Kentlands Blvd","city":"Gaithersburg, MD","zip":"20878","zipSu":"5458","distrSu":"KTL","distr":"Kentlands"},
        {"phone":"858-642-6700","address":"8825 Villa La Jolla Dr","city":"La Jolla, CA","zip":"92037","zipSu":"1949","distrSu":"LAJ","distr":"La Jolla"},
        {"phone":"925-284-5305","address":"3502 Mt Diablo Blvd","city":"Lafayette, CA","zip":"94549","zipSu":"3852","distrSu":"LAF","distr":"Lafayette"},
        {"phone":"949-900-5830","address":"23932 Aliso Creek Rd","city":"Laguna Niguel, CA","zip":"92677","zipSu":"3900","distrSu":"LGN","distr":"Laguna Niguel"},
        {"phone":"512-542-2200","address":"525 N Lamar Blvd","city":"Austin, TX","zip":"78703","zipSu":"5411","distrSu":"LMR","distr":"Lamar"},
        {"phone":"702-589-7711","address":"6689 Las Vegas Blvd","city":"Las Vegas, NV","zip":"89119","zipSu":"3301","distrSu":"LVB","distr":"Las Vegas"},
        {"phone":"408-358-4434","address":"15980 Los Gatos Blvd","city":"Los Gatos, CA","zip":"95032","zipSu":"3424","distrSu":"LGT","distr":"Los Gatos"},
        {"phone":"502-899-5545","address":"4944 Shelbyville Rd","city":"Louisville, KY","zip":"40207","zipSu":"3306","distrSu":"LOU","distr":"Louisville"},
        {"phone":"985-231-3328","address":"3450 Hwy 190","city":"Mandeville, LA","zip":"70471","zipSu":"3494","distrSu":"MDV","distr":"Mandeville"},
        {"phone":"516-869-8900","address":"2101 Northern Blvd","city":"Manhasset, NY","zip":"11030","zipSu":"3528","distrSu":"MHS","distr":"Manhasset"},
        {"phone":"317-559-9360","address":"320 E Market St","city":"Indianapolis, IN","zip":"46204","zipSu":"2839","distrSu":"MKS","distr":"Market Square"},
        {"phone":"313-576-5300","address":"115 Mack Ave","city":"Detroit, MI","zip":"48201","zipSu":"2415","distrSu":"MDT","distr":"Midtown Detroit"},
        {"phone":"813-323-9466","address":"3740 Midtown Dr","city":"Tampa, FL","zip":"33607","zipSu":"4801","distrSu":"WTP","distr":"Midtown Tampa"},
        {"phone":"310-576-4707","address":"1425 Montana Ave","city":"Santa Monica, CA","zip":"90403","zipSu":"1795","distrSu":"MON","distr":"Montana Avenue"},
        {"phone":"713-284-1260","address":"701 Waugh Dr","city":"Houston, TX","zip":"77019","zipSu":"2005","distrSu":"MIS","distr":"Montrose"},
        {"phone":"707-224-6300","address":"3682 Bel Aire Plaza","city":"Napa, CA","zip":"94558","zipSu":"2831","distrSu":"NPA","distr":"Napa"},
        {"phone":"239-552-5100","address":"9101 Strada Pl","city":"Naples, FL","zip":"34108","zipSu":"2684","distrSu":"NPL","distr":"Naples "},
        {"phone":"973-755-5080","address":"633 Broad St","city":"Newark, NJ","zip":"07102","zipSu":"4403","distrSu":"NWK","distr":"Newark"},
        {"phone":"949-999-8572","address":"415 Newport Center Dr","city":"Newport Beach, CA","zip":"92660","zipSu":"6936","distrSu":"NPT","distr":"Newport Beach"},
        {"phone":"415-282-4700","address":"3950 24th St","city":"San Francisco, CA","zip":"94114","zipSu":"3704","distrSu":"NDE","distr":"Noe Valley"},
        {"phone":"305-892-5500","address":"12150 Biscayne Blvd","city":"North Miami, FL","zip":"33181","zipSu":"2712","distrSu":"SSC","distr":"North Miami"},
        {"phone":"415-333-7400","address":"1150 Ocean Ave","city":"San Francisco, CA","zip":"94112","zipSu":"1843","distrSu":"OCN","distr":"Ocean"},
        {"phone":"405-879-3500","address":"6001 North Western Ave","city":"Oklahoma City, OK","zip":"73118","zipSu":"1200","distrSu":"OKC","distr":"Oklahoma City"},
        {"phone":"312-932-9600","address":"3 West Chicago Ave","city":"Chicago, IL","zip":"60654","zipSu":"3206","distrSu":"GOC","distr":"One Chicago"},
        {"phone":"805-751-4300","address":"650 Town Center Dr","city":"Oxnard, CA","zip":"93036","zipSu":"1120","distrSu":"OXN","distr":"Oxnard"},
        {"phone":"202-332-4300","address":"1440 P Street Nw","city":"Washington, DC","zip":"20005","zipSu":"1908","distrSu":"PST","distr":"P Street"},
        {"phone":"201-226-1244","address":"300 Bergen Town Center","city":"Paramus, NJ","zip":"07652","zipSu":"5018","distrSu":"PAR","distr":"Paramus"},
        {"phone":"503-525-4343","address":"1210 Nw Couch St","city":"Portland, OR","zip":"97209","zipSu":"4115","distrSu":"PRT","distr":"Partland"},
        {"phone":"626-351-5994","address":"3751 E Foothill Blvd","city":"Pasadena, CA","zip":"91107","zipSu":"2202","distrSu":"PAS","distr":"Pasadena"},
        {"phone":"215-557-0015","address":"2101 Pennsylvania Ave","city":"Philadelphia, PA","zip":"19130","zipSu":"3896","distrSu":"PCC","distr":"Philly Center City"},
        {"phone":"719-531-9999","address":"7635 N Academy Blvd","city":"Colorado Springs, CO","zip":"80920","zipSu":"3207","distrSu":"PPK","distr":"Pikes Peak"},
        {"phone":"972-612-6729","address":"2201 Preston Rd","city":"Plano, TX","zip":"75093","zipSu":"2307","distrSu":"PLN","distr":"Plano"},
        {"phone":"310-862-9900","address":"12746 W Jefferson Blvd","city":"Playa Vista, CA","zip":"90094","zipSu":"2885","distrSu":"PLV","distr":"Playa Vista"},
        {"phone":"404-853-1681","address":"650 Ponce De Leon Ave Ne","city":"Atlanta, GA","zip":"30308","zipSu":"1804","distrSu":"PNC","distr":"Ponce de Leon"},
        {"phone":"901-969-4368","address":"5014 Poplar Ave","city":"Memphis, TN","zip":"38117","zipSu":"7604","distrSu":"PPL","distr":"Poplar Avenue"},
        {"phone":"415-552-1155","address":"450 Rhode Island St","city":"San Francisco, CA","zip":"94107","zipSu":"2320","distrSu":"PTH","distr":"Potrero Hill"},
        {"phone":"425-881-2600","address":"17991 Ne Redmond Way","city":"Redmond, WA","zip":"98052","zipSu":"4462","distrSu":"RHD","distr":"Redmond"},
        {"phone":"916-488-2800","address":"4315 Arden Way","city":"Sacramento, CA","zip":"95864","zipSu":"3102","distrSu":"ARD","distr":"Sacramento"},
        {"phone":"650-358-6900","address":"1010 Park Pl","city":"San Mateo, CA","zip":"94403","zipSu":"1572","distrSu":"SMT","distr":"San Mateo"},
        {"phone":"925-355-9000","address":"100 Sunset Dr","city":"San Ramon, CA","zip":"94583","zipSu":"2340","distrSu":"RAM","distr":"San Ramon"},
        {"phone":"404-236-0810","address":"5930 Roswell Rd","city":"Atlanta, GA","zip":"30328","zipSu":"4908","distrSu":"SDY","distr":"Sandy Springs"},
        {"phone":"805-837-6959","address":"3761 State St","city":"Santa Barbara, CA","zip":"93105","zipSu":"3116","distrSu":"SBR","distr":"Santa Barbara"},
        {"phone":"310-315-0662","address":"2201 Wilshire Blvd","city":"Santa Monica, CA","zip":"90403","zipSu":"5705","distrSu":"SMC","distr":"Santa Monica"},
        {"phone":"310-752-1155","address":"2121 Cloverfield Blvd","city":"Santa Monica, CA","zip":"90404","zipSu":"5226","distrSu":"PIC","distr":"Santa Monica Pico"},
        {"phone":"847-585-5800","address":"750 N Martingale Rd","city":"Schaumburg, IL","zip":"60173","zipSu":"5915","distrSu":"SCH","distr":"Schaumburg"},
        {"phone":"980-213-2400","address":"6610 Fairview Rd","city":"Charlotte, NC","zip":"28210","zipSu":"3322","distrSu":"APK","distr":"Sharon Road "},
        {"phone":"818-382-3700","address":"4520 N Sepulveda Blvd","city":"Sherman Oaks, CA","zip":"91403","zipSu":"4046","distrSu":"SHW","distr":"Sherman Oaks West"},
        {"phone":"508-749-5520","address":"193 Boston Turnpike","city":"Shrewsbury, MA","zip":"01545","zipSu":"2515","distrSu":"SHB","distr":"Shrewsbury"},
        {"phone":"323-378-3891","address":"2520 Glendale Blvd","city":"Los Angeles, CA","zip":"90039","zipSu":"3220","distrSu":"SLK","distr":"Silver Lake"},
        {"phone":"301-608-9373","address":"833 Wayne Ave","city":"Silver Spring, MD","zip":"20910","zipSu":"4427","distrSu":"SSP","distr":"Silver Spring"},
        {"phone":"617-902-9300","address":"45 Beacon St","city":"Somerville, MA","zip":"02143","zipSu":"4323","distrSu":"SMV","distr":"Somerville"},
        {"phone":"530-578-4555","address":"3600 Lake Tahoe Blvd","city":"South Lake Tahoe, CA","zip":"96150","zipSu":"8942","distrSu":"TAH","distr":"South Lake Tahoe(NC)"},
        {"phone":"215-733-9788","address":"929 South St","city":"Philadelphia, PA","zip":"19147","zipSu":"1928","distrSu":"SOS","distr":"South Street"},
        {"phone":"408-257-7000","address":"20955 Stevens Creek Blvd","city":"Cupertino, CA","zip":"95014","zipSu":"2107","distrSu":"STC","distr":"Stevens"},
        {"phone":"646-497-1222","address":"226 E 57th St","city":"New York, NY","zip":"10022","zipSu":"3261","distrSu":"MTE","distr":"Street"},
        {"phone":"312-379-7900","address":"255 E Grand Ave","city":"Chicago, IL","zip":"60611","zipSu":"3315","distrSu":"STV","distr":"streeterville"},
        {"phone":"781-592-2200","address":"331 Paradise Rd","city":"Swampscott, MA","zip":"01907","zipSu":"2928","distrSu":"SWA","distr":"Swampscott"},
        {"phone":"702-942-1500","address":"7250 W Lake Mead Blvd","city":"Las Vegas, NV","zip":"89128","zipSu":"8301","distrSu":"TYA","distr":"Tenaya"},
        {"phone":"408-207-1126","address":"777 The Alameda","city":"San Jose, CA","zip":"95126","zipSu":"3155","distrSu":"ALM","distr":"The Alameda"},
        {"phone":"310-257-8700","address":"2655 Pacific Coast Hwy","city":"Torrance, CA","zip":"90505","zipSu":"7037","distrSu":"TOR","distr":"Torrance"},
        {"phone":"443-652-6566","address":"300 Towson Row","city":"Towson, MD","zip":"21204","zipSu":"5252","distrSu":"TSN","distr":"Towson"},
        {"phone":"212-349-6555","address":"270 Greenwich St","city":"New York, NY","zip":"10007","zipSu":"1150","distrSu":"TRB","distr":"Tribeca"},
        {"phone":"801-924-9060","address":"544 South 700 East","city":"Salt Lake City, UT","zip":"84102","zipSu":"2814","distrSu":"TSQ","distr":"Trolley Square"},
        {"phone":"212-673-5388","address":"4 Union Square S","city":"New York, NY","zip":"10003","zipSu":"4196","distrSu":"USQ","distr":"Union Square"},
        {"phone":"646-891-3888","address":"1551 3rd Ave","city":"New York, NY","zip":"10128","zipSu":"3104","distrSu":"UES","distr":"Upper East Side"},
        {"phone":"661-260-2377","address":"24130 Valencia Blvd","city":"Valencia, CA","zip":"91355","zipSu":"5392","distrSu":"VAL","distr":"Valencia"},
        {"phone":"224-504-3990","address":"851 N Milwaukee Ave","city":"Vernon Hills, IL","zip":"60061","zipSu":"1627","distrSu":"VRN","distr":"Vernon Hills"},
        {"phone":"713-789-4477","address":"1407 S Voss Rd","city":"Houston, TX","zip":"77057","zipSu":"1088","distrSu":"VOS","distr":"Voss"},
        {"phone":"414-808-3600","address":"11100 W Burleigh St","city":"Wauwatosa, WI","zip":"53222","zipSu":"3212","distrSu":"WAW","distr":"Wauwatosa"},
        {"phone":"973-587-1470","address":"560 Valley Rd","city":"Wayne, NJ","zip":"07470","zipSu":"3526","distrSu":"WNJ","distr":"Wayne"},
        {"phone":"323-848-4200","address":"7871 Santa Monica Blvd","city":"West Hollywood, CA","zip":"90046","zipSu":"5344","distrSu":"HOL","distr":"West Hollywood"},
        {"phone":"312-207-8400","address":"1 N Halsted St","city":"Chicago, IL","zip":"60661","zipSu":"2176","distrSu":"WLP","distr":"West loop"},
        {"phone":"310-996-8840","address":"11666 National Blvd","city":"Los Angeles, CA","zip":"90064","zipSu":"3888","distrSu":"WLA","distr":"West Los Angeles"},
        {"phone":"973-669-3196","address":"235 Prospect Ave","city":"West Orange, NJ","zip":"07052","zipSu":"4228","distrSu":"WOP","distr":"West Orange"},
        {"phone":"978-303-2900","address":"160 Littleton Rd","city":"Westford, MA","zip":"01886","zipSu":"3190","distrSu":"WSF","distr":"Westford"},
        {"phone":"206-621-9700","address":"2210 Westlake Ave","city":"Seattle, WA","zip":"98121","zipSu":"2713","distrSu":"WSL","distr":"Westlake"},
        {"phone":"310-824-0858","address":"1050 S Gayley Ave","city":"Los Angeles, CA","zip":"90024","zipSu":"3402","distrSu":"WWD","distr":"Westwood"},
        {"phone":"718-734-2321","address":"238 Bedford Ave","city":"Brooklyn, NY","zip":"11249","zipSu":"4014","distrSu":"WBG","distr":"Williamsburg"},
        {"phone":"910-777-2499","address":"3804 Oleander Dr","city":"Wilmington, NC","zip":"28403","zipSu":"6759","distrSu":"WOL","distr":"Wilmington"},
        {"phone":"610-896-3737","address":"15 E Wynnewood Rd","city":"Wynnewood, PA","zip":"19096","zipSu":"1917","distrSu":"WYW","distr":"Wynnewood NEW"},
        {"phone":"925-269-9010","address":"2941 Ygnacio Valley Rd","city":"Walnut Creek, CA","zip":"94598","zipSu":"3535","distrSu":"YVR","distr":"Ygnacio Valley Road"}
      ],
      productData:[
        {"name":"BMEAT PLANT BSD MEATBALLS","price":"8.49","productCode":"0007756725423"},
        {"name":"BMEAT ORIGINAL SAUSAGE","price":"9.99","productCode":"0077567254238"},
        {"name":"BMEAT PLT BSD BRGR 4PK","price":"10.49","productCode":"0077567254238"},
        {"name":"BMEAT CAJUN SUN SAUSAGE","price":"9.99","productCode":"0077567254238"},
        {"name":"BMEAT PESTO SUN SAUSAGE","price":"9.99","productCode":"0077567254238"}]
    }
  },
  components: {
  },
  watch: {
    // 监听 newOrderId 变量的变化
    newOrderId(newValue) {
      if (newValue) {
        // console.log('newOrderId 变化，启动定时任务');
        this.startTimer();  // 启动定时任务
      } else {
        this.stopTimer();   // 如果 newOrderId 为空，停止定时任务
      }
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  mounted() {
    this.gensFuncs();
  },
  methods: {
    roundToNextHundred(amount) {
      return Math.ceil(amount / 100) * 100;
    },
    printElement() {
      const printWindow = window.open('', '_blank',);
      const element = document.getElementById("printContent");
      const printContent = element.innerHTML;
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();

    },
    gensFuncs() {
      this.showProduceData = [];
      this.codeFrist = Math.floor(Math.random() * (400 - 201 + 1) + 201);
      this.codeSecend = Math.floor(Math.random() * (95001 - 45001 + 1) + 45001);
      var index = Math.floor(Math.random() * this.storeData.length);
      this.address =  this.storeData[index].address;
      this.tempAddress =  this.address;
      this.phone =  this.storeData[index].phone;
      this.city =  this.storeData[index].city;
      this.zip =  this.storeData[index].zip;
      this.distr =  this.storeData[index].distr;
      this.distrSu =  this.storeData[index].distrSu;
      this.zipSu =  this.storeData[index].zipSu;
      this.iptRows = Math.floor(Math.random() * (12 - 6 + 1)) + 6;
      this.iptRowsFull = this.iptRows < 10 ? '0' + this.iptRows : this.iptRows.toString();
      var tempPrice = 0.00;
      for (let i = 1; i <= this.iptRows; i++) {
        var pIindex = Math.floor(Math.random() * (this.productData.length));
        this.showProduceData.push(this.productData[pIindex])
        tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
      }

      tempPrice = parseFloat(tempPrice).toFixed(2);
      console.log("showProduceData:"+this.showProduceData)

      this.orderTempDate = this.getRandomTempYear();
      this.orderDate = this.getRandomDateTime();
      //条形码
      let barcodeFrist ="630110";
      let barcodeSecond = Math.floor(Math.random() * (900 - 401 + 1) + 401);
      let barcodeThired = this.orderTempDate.replaceAll("/","");
      this.barcodeValue = barcodeFrist+''+barcodeSecond+""+this.codeFrist+""+this.codeSecend+""+barcodeThired+"0";
      //行数随机
      this.issuer = this.generateRandomString(10);
      this.genRows= parseInt(this.iptRows);
      this.totalPrice = tempPrice;
      this.totalPrice = parseFloat(this.totalPrice).toFixed(2);
      this.cashAmount = parseFloat(this.roundToNextHundred(this.totalPrice)).toFixed(2);
      this.changeAmount = parseFloat(this.cashAmount).toFixed(2) - parseFloat(this.totalPrice).toFixed(2);
      this.changeAmount = parseFloat(this.changeAmount).toFixed(2);
      this.totalPrice = parseFloat(this.totalPrice).toFixed(2);
      this.showIptRows = this.iptRows < 10 ? this.iptRows.toString()+'\xa0'+'\xa0' : this.iptRows.toString();
      this.generateBarcode();
    },
    generateRandomString(num) {
      let digitsCount = 0;
      let result = '';

      while (result.length < num) {
        const randomChar = Math.random() >= 0.5 ? this.getRandomChar() : this.getRandomDigit();
        result += randomChar;
        if (randomChar >= '0' && randomChar <= '9') {
          digitsCount++;
        }
      }
      // 确保至少有4位数字
      while (digitsCount < 2) {
        const randomIndex = Math.floor(Math.random() * result.length);
        const charAtIndex = result.charAt(randomIndex);
        if (charAtIndex >= '0' && charAtIndex <= '9') {
          result = result.slice(0, randomIndex) + this.getRandomDigit() + result.slice(randomIndex);
          digitsCount++;
        }
      }
      return result;
    },
    getRandomChar() {
      return String.fromCharCode(65 + Math.floor(Math.random() * 26)); // A-Z
    },
    getRandomDigit() {
      return String.fromCharCode(48 + Math.floor(Math.random() * 10)); // 0-9
    },

    getRandomDateTime() {
      const startYear = 2018;
      const endYear = 2024;
      const startTime = new Date(startYear, 7, 20); // 2018年的6月30日
      const diff = new Date(endYear, 6, 13) - startTime; // 2024年的7月14日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      var year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (12 - 2 + 1)) + 2;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }

      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      var  tempHours =  Math.floor(Math.random() * (19 - 9 + 1)) + 9;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');
      const seconds = String(randomDate.getSeconds()).padStart(2, '0');
      var tempYear = year.toString();

      var str;
      if(tempHours>12) {
        tempHours -= 12;
        str = " PM";
      }else{
        str = " AM";
      }
      tempHours = tempHours < 10 ? "0" + tempHours:tempHours;
      return `${tempHours}:${minutes}&nbsp; ${str}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },

    getRandomTempYear() {
      const startYear = 2018;
      const endYear = 2024;
      const startTime = new Date(startYear, 7, 20); // 2018年的6月30日
      const diff = new Date(endYear, 6, 13) - startTime; // 2024年的7月14日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      var year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }

      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      var  tempHours =  Math.floor(Math.random() * (19 - 12 + 1)) + 12;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');
      const seconds = String(randomDate.getSeconds()).padStart(2, '0');
      var tempYear = year.toString();
      return `${month}/${day}/${tempYear}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },

    generateBarcode() {
      // 使用 JsBarcode 库生成条形码
      JsBarcode(this.$refs.barcode, this.barcodeValue, {
        format: "CODE128", // 条形码格式，可选 CODE128、EAN 等
        lineColor: "#000",
        width: 1.4,
        height: 18,
        displayValue: false // 是否显示条形码值
      });
    },
    // 判断设备是否为移动端
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 使用正则检查 userAgent 中是否包含手机/平板的关键字
      return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile|webos.*mobile/i.test(userAgent);
    },
    isMobileBrowerSize() {
      return window.innerWidth <= 768;  // 视窗宽度小于等于 768px 视为移动端
    },

  },
  beforeDestroy() {

  },
  created() {

  }
}
</script>

<style >
@media print {
  body {
    font-family: "Times New Roman", Times, serif;
  }
}
</style>